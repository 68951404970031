import { ResultData } from "../../../../../service/Shared";
import {
  CursorDataGrid,
  DataGridColumnDefinition,
  DataGridCursorDataLoadEventHandler,
  ExpandableCard,
} from "../../../../../widget";

export interface DashboardTableCardProps {
  data?: ResultData[];
  columns: DataGridColumnDefinition[];
  title: string;
  expandRoute?: string;
  height?: number;
  isLoading: boolean;
  refresh?: boolean;
  pageable?: boolean;
  onChange?: DataGridCursorDataLoadEventHandler;
}

export const DashboardTableCard = ({
  data,
  columns,
  title,
  expandRoute,
  height,
  isLoading,
  refresh,
  pageable = false,
  onChange,
}: DashboardTableCardProps): JSX.Element => {
  return (
    <ExpandableCard height={height} title={title} targetRoute={expandRoute}>
      <CursorDataGrid
        data={data || []}
        onChange={onChange}
        columns={columns}
        clientSide={onChange === undefined}
        pageable={pageable}
        sortable={false}
        refresh={refresh}
        pagination={{ totalCount: data?.length || 0 }}
        dataIsLoading={isLoading}
        noDataMessage="No projects to display"
      />
    </ExpandableCard>
  );
};
