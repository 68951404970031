import "./Password.css";

import { ChangeEvent } from "react";
import { Link } from "react-router-dom";

import { getForgottenPasswordRoute } from "../../../../utils/routes";

interface PasswordProps {
  label: string | undefined;
  name: string;
  value: string;
  placeholder?: string;
  hasForgottenPassword?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Password = ({
  label,
  name,
  value,
  placeholder,
  hasForgottenPassword = false,
  onChange,
}: PasswordProps): JSX.Element => {
  return (
    <div className="form_field Password">
      <div className="PasswordLabelContainer">
        {label && <label htmlFor={name}>{label}</label>}
        {hasForgottenPassword && (
          <span className="body1">
            <Link to={getForgottenPasswordRoute()}>Forgotten password?</Link>
          </span>
        )}
      </div>
      <input
        id={name}
        type="password"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete="current-password"
      />
    </div>
  );
};
