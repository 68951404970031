import "./LineChart.css";

import { ComplexFillTarget, FillTarget, Scale } from "chart.js";
import { Line } from "react-chartjs-2";

import { ChartDatasets } from "../models";
import { maybeSetGlobalChartJsConfiguration } from "../utils";
import { useLineChart } from "./useLineChart";

maybeSetGlobalChartJsConfiguration();

interface LineChartProps {
  labels: string[];
  datasets: ChartDatasets;
  offset?: number;
  customChartColours?: string[];
  defaultFill?: FillTarget | ComplexFillTarget;
  emptyPlaceholder?: string;
  xAxisTitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  xAxisTickCallbackFunction?: (this: Scale, val: string | number, index: number) => any;
  yAxisTitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yAxisTickCallbackFunction?: (this: Scale, val: string | number, index: number) => any;
}

export const LineChart = ({
  labels,
  datasets,
  offset,
  customChartColours,
  defaultFill,
  emptyPlaceholder = "No data to display",
  xAxisTitle,
  xAxisTickCallbackFunction,
  yAxisTitle,
  yAxisTickCallbackFunction,
}: LineChartProps): JSX.Element => {
  const { options, chartData, plugins } = useLineChart({
    labels,
    datasets,
    offset,
    customChartColours,
    defaultFill,
    xAxisTitle,
    xAxisTickCallbackFunction,
    yAxisTitle,
    yAxisTickCallbackFunction,
  });
  if (!chartData && !plugins)
    return (
      <div className="Line Line_empty">
        <p className="body3">{emptyPlaceholder}</p>
      </div>
    );

  return (
    <div className="Line">
      <Line data={chartData} options={options} plugins={plugins} />
    </div>
  );
};
