import {
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  ComplexFillTarget,
  Filler,
  FillTarget,
  Plugin,
  Scale,
  TooltipItem,
} from "chart.js";
import { useEffect, useMemo, useState } from "react";

import { ColorConstants } from "../../../constants";
import { ChartDatasets } from "../models";

interface UseLineChartReturnData {
  chartData: ChartData<"line", number[], string>;
  options: ChartOptions<"line">;
  plugins: Plugin<"line">[] | undefined;
}

interface UseLineChartProps {
  labels: string[];
  datasets: ChartDatasets;
  offset?: number;
  customChartColours?: string[];
  defaultFill?: FillTarget | ComplexFillTarget;
  xAxisTitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  xAxisTickCallbackFunction?: (this: Scale, val: string | number, index: number) => any;
  yAxisTitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yAxisTickCallbackFunction?: (this: Scale, val: string | number, index: number) => any;
}

export const useLineChart = ({
  labels,
  datasets,
  offset,
  customChartColours = ColorConstants.LINE_CHART_COLORS,
  defaultFill,
  xAxisTitle,
  xAxisTickCallbackFunction,
  yAxisTitle,
  yAxisTickCallbackFunction,
}: UseLineChartProps): UseLineChartReturnData => {
  const [chartData, setChartData] = useState<ChartData<"line", number[], string>>({ datasets: [] });

  const plugins = useMemo(() => {
    const tempPlugins = [
      {
        // No default way to change this, so using this - https://stackoverflow.com/a/67723827
        id: "increaseLegendSpacing",
        beforeInit: (chart: Chart) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const originalFit = (chart.legend as any).fit;

          // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
          (chart.legend as any).fit = function fit() {
            // Call original function and bind scope in order to use `this` correctly inside it
            originalFit.bind(chart.legend)();
            // eslint-disable-next-line react/no-this-in-sfc
            this.height += 16;
          };
        },
      },
    ] as Plugin<"line">[] | undefined;

    // Adding offset to each point on the line chart
    if (offset)
      tempPlugins?.push(
        {
          id: "onInitOffset",
          beforeDraw: (chart: Chart) => {
            chart.data.datasets.forEach((dataset: ChartDataset, i: number) => {
              const meta = chart.getDatasetMeta(i);
              for (let j = 0; j < meta.data.length; j++) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const point = meta.data[j] as any;
                if (point.old_x === undefined) {
                  point.old_x = point.x;
                }
                point.x = point.old_x + offset;
              }
            });
          },
        },
        {
          id: "onResizeOffset",
          resize: (chart: Chart) => {
            chart.data.datasets.forEach((dataset: ChartDataset, i: number) => {
              const meta = chart.getDatasetMeta(i);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              meta.data.forEach((point: any) => {
                if (point.old_x !== undefined) {
                  // eslint-disable-next-line no-param-reassign
                  point.old_x = undefined;
                }
              });
            });
          },
        }
      );

    /* Note - register Filler plugin here, rather than in maybeSetGlobalChartJsConfiguration, 
       as when we add it before our custom plugins it causes the chart to glitch. Also we only need when chart needs fill
     */
    if (defaultFill) {
      tempPlugins?.push(Filler);
    }

    return tempPlugins;
  }, [datasets]);

  const options: ChartOptions<"line"> = useMemo(
    () => ({
      maintainAspectRatio: false,
      devicePixelRatio: 4,
      layout: {
        padding: {
          left: -14,
        },
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
        },
        line: {
          tension: 0.5,
          borderWidth: 2,
        },
      },
      scales: {
        x: {
          labeloffset: 20,
          title: xAxisTitle
            ? {
                display: true,
                text: xAxisTitle,
                font: {
                  size: 14,
                  weight: "500",
                  family: "figtree",
                },
                padding: 10,
              }
            : undefined,
          ticks: {
            callback: xAxisTickCallbackFunction,
            font: {
              family: "figtree",
            },
            padding: 8,
            labelOffset: offset,
          },
          grid: {
            display: false,
            drawTicks: false,
          },
        },
        y: {
          title: yAxisTitle
            ? {
                display: true,
                text: yAxisTitle,
                padding: 12,
                font: {
                  size: 14,
                  weight: "500",
                  family: "figtree",
                },
              }
            : undefined,
          grid: {
            display: false,
            drawTicks: false,
          },
          ticks: {
            callback: yAxisTickCallbackFunction,
            font: {
              family: "figtree",
            },
            padding: 8,
          },
        },
      },
      plugins: {
        legend: {
          align: "start",
          position: "top",
          labels: {
            boxHeight: 12,
            boxWidth: 12,
            borderRadius: 2,
            useBorderRadius: true,
            padding: 16,
            color: ColorConstants.GREY_60,
            font: {
              family: "figtree",
              size: 14,
            },
          },
        },
        title: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          label: (tooltipItem: TooltipItem<"line">) => {
            return tooltipItem.formattedValue;
          },
        },
        filler: {
          propagate: true,
        },
      },
    }),
    [datasets]
  );

  useEffect(() => {
    if (!datasets) return;

    setChartData({
      labels,
      datasets: datasets.map((x, i) => ({
        ...x,
        backgroundColor: customChartColours[i],
        borderColor: customChartColours[i],
        fill: defaultFill,
      })),
    });
  }, [datasets]);

  return {
    options,
    chartData,
    plugins,
  };
};
