import { ResultData } from "../../service/Shared";
import { AvatarGridRow, DiscussionsActionsMenuGridRow } from "./dataGridCustomTypes";

export type SupportedDataTypes = string | number | boolean | Date | AvatarGridRow | DiscussionsActionsMenuGridRow;
export type SupportedDataTypeNames =
  | "string"
  | "number"
  | "boolean"
  | "Date"
  | "AvatarGridRow"
  | "DiscussionsActionsMenuGridRow";

export enum DataGridColumnCurrency {
  Gbp = "GBP",
  Usd = "USD",
  Eur = "EUR",
}

export type SortDirection = "asc" | "desc";

export type SortCriteria = {
  key: string;
  direction: SortDirection;
};

export type FilterOperator =
  | "eq"
  | "neq"
  | "gt"
  | "lt"
  | "gte"
  | "lte"
  | "empty"
  | "notEmpty"
  | "startsWith"
  | "endsWith"
  | "contains"
  | "ncontains"
  | "in"
  | "nin";

export type FilterCriteria = {
  key: string;
  operator: FilterOperator;
  value: SupportedDataTypes;
  filterId?: number;
};

export interface CursorChangeProps {
  sorting: SortCriteria[];
  filtering: FilterCriteria[];
  paging: {
    beforeCursor?: string;
    afterCursor?: string;
    pageSize: number;
  };
}

export interface ResultType {
  resultData: ResultData[];
  paging: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageSize: number;
    totalCount: number;
  };
}
