import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import { Origin, TabsList } from "../../../../models";
import { WorkflowActivity } from "../../../../models/shared/workflowActivityType";
import { IStateOrigin } from "../../../../navigators/navigateStateObjects";
import {
  GetActivityDetailsResponse,
  getActivityHistoryDetails,
  getProjectDetails,
  GetProjectDetailsResponse,
  searchActivities,
  searchActivityHistory,
  SearchActivityHistoryResponse,
} from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import {
  getActivityDashboardTabRoute,
  getActivityViewRoute,
  getProjectActivitiesRoute,
  getProjectAuditHistoryRoute,
  getProjectsRoute,
  getSettingsAuditHistoryRoute,
} from "../../../../utils/routes";
import { ChartData, dataGridMapFilterCriteria } from "../../../../widget";
import toast from "../../../../widget/general/Toast/Toast";
import { isActivityDataValid } from "../../../developer/activities";
import { createActivityDraft, resumeActivity, versionConflictModalGotDisplayed } from "../../utils";
import { ActivityDashboardContext } from "../ActivityDashboardContext";

interface UseActivityOverviewPageReturnData {
  handleBackClick?: () => void;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  setShowDeleteActivityModal: Dispatch<SetStateAction<boolean>>;
  shouldRefreshActivities: () => void;
  isExternalActivity: () => boolean;
  tabsList: TabsList;
  activityDetails: GetActivityDetailsResponse | undefined;
  projectDetails: GetProjectDetailsResponse | undefined;
  discussionsChartData: ChartData;
  workflowActivity: WorkflowActivity | undefined;
  showVersionConflictModal: boolean;
  showDeleteActivityModal: boolean;
  draftActivityHistoryUuid?: string;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  hasManageProjectActivityPermission: boolean;
  currentUserType: OrganisationTypeConstants;
  backButtonText: string;
  pageTitle?: string;
}

export const useActivityOverviewPage = (): UseActivityOverviewPageReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const stateOrigin = state?.origin as IStateOrigin;

  const {
    setShowVersionConflictModal,
    setNewVersionActivityHistory,
    setDraftActivityHistoryUuid,
    shouldRefreshActivities,
    isExternalActivity,
    refreshActivities,
    activityUuid,
    activityDetails,
    activityReviewAggregate,
    hasManageProjectActivityPermission,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    hasUnreadDiscussions,
    queryParams,
  } = useContext(ActivityDashboardContext);

  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse>();
  const [workflowActivity, setWorkflowActivity] = useState<WorkflowActivity>();
  const [showDeleteActivityModal, setShowDeleteActivityModal] = useState(false);

  const [draftActivityHistory, setDraftActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [hasFetchedActivityHistory, setHasFetchedActivityHistory] = useState(false);
  const [discussionsChartData, setDiscussionsChartData] = useState<ChartData>({});

  const projectUuid = searchParams.get("projectUuid");
  const [pageTitle, setPageTitle] = useState<string>();

  const getTabsList = (): TabsList => {
    const list = [
      {
        label: "Documents",
        route: getActivityDashboardTabRoute(
          activityUuid ?? "",
          "documents",
          currentUserType,
          // eslint-disable-next-line no-nested-ternary
          queryParams
        ),
      },
      {
        label: "Discussions",
        route: getActivityDashboardTabRoute(
          activityUuid ?? "",
          "discussions",
          currentUserType,
          // eslint-disable-next-line no-nested-ternary
          queryParams
        ),
        notify: hasUnreadDiscussions,
      },
      {
        label: "Version history",
        route: getActivityDashboardTabRoute(
          activityUuid ?? "",
          "history",
          currentUserType,
          // eslint-disable-next-line no-nested-ternary
          queryParams
        ),
      },
    ];

    return list;
  };

  const resumeDraft = async (
    _draftActivityHistoryUuid: string,
    localDraftActivityHistory: SearchActivityHistoryResponse
  ): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const activityHistory = localDraftActivityHistory.results.find((x) => x.uuid === _draftActivityHistoryUuid)!;

    await resumeActivity(
      navigate,
      setDraftActivityHistoryUuid,
      setNewVersionActivityHistory,
      setShowVersionConflictModal,
      activityHistory.activity.uuid,
      activityHistory.uuid,
      activityHistory.versionNumber
    );
  };

  const fetchDraftActivities = useCallback(async (): Promise<void> => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.isDraft = {
      operator: "eq",
      value: true,
    };

    const response = await searchActivityHistory({
      activityUuid: activityUuid ?? "",
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 10,
      },

      filter: { results: filterCriteria },
    });

    if (response.status === Status.Success && response.data && response.data.results.length) {
      setDraftActivityHistory(response.data);
    }
  }, [refreshActivities]);

  const fetchWorkflowData = useCallback(async (): Promise<void> => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.uuid = {
      operator: "eq",
      value: activityUuid,
    };

    const response = await searchActivities({
      paging: {
        limit: 1,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "results.currentVersion.updatedAt",
          direction: "desc",
        },
      ],
      filter: { results: filterCriteria },
    });

    if (response.status === Status.Success && response.data && response.data.results) {
      const mappedActivities = response.data.results.map((activity) => ({
        uuid: activity.uuid,
        projectUuid: activity.project?.uuid,
        groupUuid: activity.group?.uuid || undefined,
        activityHistoryUuid:
          activity?.draftVersion?.uuid || activity?.currentVersion?.uuid || activity?.reviewVersion?.uuid,
        activityHistoryRowVersion: activity?.draftVersion ? activity?.draftVersion.rowVersion : undefined,
        isUnderReview: activity.isUnderReview,
        isDraftVersion: !!activity?.draftVersion,
        isCurrentVersion: activity?.draftVersion ? false : !!activity?.currentVersion,
        rowVersion: activity.rowVersion,
        status: activity.status,
        verifierDisplayName:
          activity.project?.verifier?.displayName || activity.group?.verifier?.displayName || undefined,
        displayName: activity?.draftVersion
          ? `${activity.activityDefinition.displayName} (Draft)`
          : activity.activityDefinition.displayName,
        completionPercentage: Math.round(
          // eslint-disable-next-line no-nested-ternary
          activity.draftVersion?.completionPercentage
            ? activity.draftVersion.completionPercentage * 100
            : // eslint-disable-next-line no-nested-ternary
            activity.currentVersion?.completionPercentage
            ? activity.currentVersion.completionPercentage * 100
            : activity.reviewVersion != null
            ? 100
            : 0
        ),
        // eslint-disable-next-line no-nested-ternary
        isManuallyApproved: activity.currentVersion
          ? activity.currentVersion?.activityDefinitionVersion?.isManuallyApproved
          : activity.draftVersion
          ? activity.draftVersion?.activityDefinitionVersion?.isManuallyApproved
          : activity.reviewVersion == null,
        supportsDigitalVerification:
          activity.project?.verifier?.supportsDigitalVerification ??
          activity.group?.verifier?.supportsDigitalVerification ??
          undefined,
        isActivityDataValid: false,
        isVersionConflict: async () => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const activityHistory = draftActivityHistory!.results.find((x) => x.uuid === activity?.draftVersion?.uuid)!;

          return versionConflictModalGotDisplayed(
            setDraftActivityHistoryUuid,
            setNewVersionActivityHistory,
            setShowVersionConflictModal,
            activityHistory.activity.uuid,
            activityHistory.uuid,
            activityHistory.versionNumber
          );
        },
        onEdit: () =>
          // eslint-disable-next-line no-nested-ternary
          activity?.draftVersion && draftActivityHistory
            ? resumeDraft(activity.draftVersion.uuid, draftActivityHistory)
            : activity?.currentVersion
            ? createActivityDraft(activity.uuid, false, navigate)
            : null,
        onView: () =>
          // eslint-disable-next-line no-nested-ternary
          activity?.currentVersion
            ? navigate(getActivityViewRoute(activity.currentVersion.uuid, currentUserType, queryParams))
            : activity?.reviewVersion
            ? navigate(getActivityViewRoute(activity.reviewVersion.uuid, currentUserType, queryParams))
            : null,
        onResume: () =>
          // eslint-disable-next-line no-nested-ternary
          activity?.draftVersion && draftActivityHistory
            ? resumeDraft(activity.draftVersion.uuid, draftActivityHistory)
            : activity?.currentVersion
            ? createActivityDraft(activity.uuid, false, navigate)
            : toast.error({
                message: `This activity has not yet been published. Once ${activity.createdByUser.fullName} has published the activity, you can begin editing.`,
              }),
        onDownload: () => null,
      }));

      // Refactor opportunity
      // We need to fetch the activity data for each activity in order to compute the isActivityDataValid
      // would be better to return it from the BE
      for (let index = 0; index < response.data.results.length; index++) {
        const activityHistoryUuid =
          response.data.results[index]?.draftVersion?.uuid || response.data.results[index]?.currentVersion?.uuid;

        if (activityHistoryUuid) {
          // eslint-disable-next-line no-await-in-loop
          const historyDetailsRes = await getActivityHistoryDetails({ activityHistoryUuid });

          if (historyDetailsRes.status === Status.Success && historyDetailsRes.data) {
            mappedActivities[index].isActivityDataValid = isActivityDataValid(historyDetailsRes.data.data.steps);
          }
        }
      }

      setWorkflowActivity(mappedActivities[0]);
    }
  }, [draftActivityHistory]);

  useEffect(() => {
    if (!activityUuid || !activityDetails) {
      return;
    }

    getProjectDetails({
      projectUuid: activityDetails?.project?.uuid ?? "",
      projectExternalReference: null,
      projectType: null,
    }).then((res) => {
      if (res.status === Status.Success && res.data) {
        setProjectDetails(res.data);
      }
    });

    // TODO: when we refactor this we could use the activityDetails to populate the workflowActivity
    // const workflowActivityData = {
    //   uuid: activityDetails?.uuid ?? "",
    //   activityHistoryUuid: (activityDetails?.draftVersion?.uuid || activityDetails?.currentVersion?.uuid) ?? "",
    //   activityHistoryRowVersion: activityDetails?.rowVersion ?? 0,
    //   isDraftVersion: activityDetails?.draftVersion !== null,
    //   isCurrentVersion: activityDetails?.currentVersion !== null,
    //   rowVersion: activityDetails?.rowVersion ?? 0,
    //   status: activityDetails?.status ?? "",
    //   displayName: activityDetails?.draftVersion
    //     ? `${activityDetails.activityDefinition.displayName} (Draft)`
    //     : activityDetails.activityDefinition.displayName,
    //   isManuallyApproved: undefined,
    //   completionPercentage: (activityDetails?.cachedCompletionPercentage ?? 0) * 100,
    //   isActivityDataValid: false,
    //   isVersionConflict: async () => {
    //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //     const activityHistory = draftActivityHistory!.results.find(
    //       (x) => x.uuid === activityDetails?.draftVersion?.uuid
    //     )!;

    //     return versionConflictModalGotDisplayed(
    //       setDraftActivityHistoryUuid,
    //       setNewVersionActivityHistory,
    //       setShowVersionConflictModal,
    //       activityHistory.activity.uuid,
    //       activityHistory.uuid,
    //       activityHistory.versionNumber
    //     );
    //   },
    //   onEdit: () =>
    //     // eslint-disable-next-line no-nested-ternary
    //     activityDetails?.draftVersion
    //       ? resumeActivity(
    //           navigate,
    //           setDraftActivityHistoryUuid,
    //           setNewVersionActivityHistory,
    //           setShowVersionConflictModal,
    //           activityUuid ?? "",
    //           activityDetails?.currentVersion?.uuid,
    //           activityDetails?.currentVersion?.versionNumber
    //         )
    //       : activityDetails?.currentVersion
    //       ? createActivityDraft(activityDetails.uuid, navigate)
    //       : null,
    //   onView: () =>
    //     activityDetails?.currentVersion ? navigate(getActivityViewRoute(activityDetails.currentVersion.uuid)) : null,
    //   onResume: () =>
    //     // eslint-disable-next-line no-nested-ternary
    //     activityDetails?.draftVersion
    //       ? resumeActivity(
    //           navigate,
    //           setDraftActivityHistoryUuid,
    //           setNewVersionActivityHistory,
    //           setShowVersionConflictModal,
    //           activityUuid ?? "",
    //           activityDetails?.draftVersion?.uuid,
    //           activityDetails?.draftVersion?.versionNumber
    //         )
    //       : activityDetails?.currentVersion
    //       ? createActivityDraft(activityDetails.uuid, navigate)
    //       : toast.error({
    //           message: `This activity has not yet been published. Once ${activityDetails?.createdByUser.fullName} has published the activity, you can begin editing.`,
    //         }),
    //   onDownload: () => null,
    // };

    // const activityHistoryUuid = activityDetails?.draftVersion?.uuid || activityDetails?.currentVersion?.uuid;

    // if (activityHistoryUuid) {
    //   getActivityHistoryDetails({ activityHistoryUuid }).then((res) => {
    //     if (res.status === Status.Success && res.data) {
    //       workflowActivityData.isActivityDataValid = isActivityDataValid(res.data.data.steps);
    //     }
    //   });
    // }

    // setWorkflowActivity(workflowActivityData);
  }, [activityDetails]);

  useEffect(() => {
    fetchDraftActivities().finally(() => setHasFetchedActivityHistory(true));
  }, [activityDetails, refreshActivities]);

  useEffect(() => {
    if (hasFetchedActivityHistory) {
      fetchWorkflowData().finally(() => setHasFetchedActivityHistory(false));
    }
  }, [fetchWorkflowData, hasFetchedActivityHistory, activityDetails]);

  useEffect(() => {
    if (!activityDetails) return;
    setPageTitle(
      `${activityDetails?.groupUuid == null ? `${activityDetails?.project?.displayName} ` : ""}${activityDetails
        ?.activityDefinition.displayName}`
    );
  }, [activityDetails]);

  useEffect(() => {
    if (activityReviewAggregate) {
      setDiscussionsChartData({
        "Resolved discussions": activityReviewAggregate?.resolvedDiscussions ?? 0,
        "Your unresolved discussions": activityReviewAggregate?.unresolvedDiscussionsAuthor ?? 0,
        "Other unresolved discussions": activityReviewAggregate?.unresolvedDiscussionsOrganisation ?? 0,
      });
    }
  }, [activityReviewAggregate]);

  const backButtonText =
    stateOrigin?.goBackText ??
    (origin === Origin.ProjectAuditPage || origin === Origin.OrganisationAuditPage
      ? "Back to audit"
      : "Back to project");

  const handleBackClick = (): void => {
    if (stateOrigin?.from) navigate(stateOrigin?.from);
    // Navigate to audit page
    else if (origin === Origin.ProjectAuditPage && projectDetails?.uuid)
      navigate(getProjectAuditHistoryRoute(projectDetails?.uuid, currentUserType));
    else if (origin === Origin.OrganisationAuditPage) navigate(getSettingsAuditHistoryRoute(currentUserType));
    else if (activityDetails?.project?.uuid)
      navigate(getProjectActivitiesRoute(projectUuid || activityDetails?.project.uuid, currentUserType));
    else navigate(getProjectsRoute(currentUserType));
  };

  return {
    tabsList: getTabsList(),
    handleBackClick,
    setShowVersionConflictModal,
    setShowDeleteActivityModal,
    shouldRefreshActivities,
    isExternalActivity,
    activityDetails,
    projectDetails,
    discussionsChartData,
    workflowActivity,
    showVersionConflictModal,
    showDeleteActivityModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    hasManageProjectActivityPermission,
    currentUserType,
    backButtonText,
    pageTitle,
  };
};
