import { PillVariant } from "../../widget";

export class ProjectActivitiesConstants {
  // Generated Document MimeType
  public static readonly GENERATED_DOCUMENT_MIME_TYPE: string =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  // Change summaries
  public static readonly CHANGE_SUMMARY_CREATED = "Created";

  public static readonly CHANGE_SUMMARY_UPDATED = "Updated";

  public static readonly CHANGE_SUMMARY_SUBMITTED = "Submitted";

  public static readonly CHANGE_SUMMARY_INVALIDATED = "Invalidated";

  // Definitions
  public static readonly DEFINITION_VALIDATION = "Validation";

  public static readonly DEFINITION_VERIFICATION = "Verification";

  public static readonly DEFINITION_LOSS_EVENT = "Loss Event";

  // Statuses
  public static readonly STATUS_TO_DO = "To do";

  public static readonly STATUS_IN_PROGRESS = "In progress";

  public static readonly STATUS_ASSESSMENT_ASSIGNED = "Assessment assigned";

  public static readonly STATUS_ASSESSMENT_IN_PROGRESS = "Assessment in progress";

  public static readonly STATUS_ASSESSMENT_AWAITING_REVIEW = "Assessment awaiting review";

  public static readonly STATUS_REVIEW_ASSIGNED = "Review assigned";

  public static readonly STATUS_REVIEW_IN_PROGRESS = "Review in progress";

  public static readonly STATUS_REVIEW_COMPLETE = "Review complete";

  public static readonly STATUS_REVIEW_CANCELLED = "Review cancelled";

  public static readonly STATUS_CHANGES_REQUIRED = "Changes required";

  public static readonly STATUS_SUBMITTED_TO_CODE = "Submitted to Code";

  public static readonly STATUS_SUBMITTED_TO_REGISTRY = "Submitted to Registry";

  public static readonly STATUS_COMPLETE = "Complete";

  public static readonly STATUS_NOT_STARTED = "Not started";

  public static readonly STATUS_ASSESSMENT_IN_REVIEW = "Assessment in review";

  public static readonly STATUS_SUBMITTED_TO_VVB = "Submitted to VVB";

  public static readonly STATUS_AWAITING_FEEDBACK = "Awaiting feedback";

  public static readonly STATUS_FEEDBACK_RECEIVED = "Feedback received";

  public static readonly STATUS_SUBMITTED = "Submitted";

  public static readonly STATUS_REJECTED = "Rejected";

  public static readonly STATUS_APPROVED = "Approved";

  public static readonly STATUS_DELETED = "Deleted";

  public static readonly STATUS_SUPERSEDED = "Superseded";

  public static readonly STATUS_WITHDRAWN = "Withdrawn";

  public static readonly PROJECT_ACTIVITIES_STATUS_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
    "To do": "grey",
    "In progress": "coral",
    "Assessment assigned": "grey",
    "Assessment in progress": "coral",
    "Assessment awaiting review": "cyan",
    "Review assigned": "grey",
    "Review in progress": "coral",
    "Review complete": "green",
    "Review cancelled": "red",
    "Changes required": "coral",
    "Submitted to Code": "cyan",
    "Submitted to Registry": "cyan",
    "Submitted to VVB": "cyan",
    "Awaiting feedback": "cyan",
    "Feedback received": "coral",
    Complete: "green",
    Approved: "green",
    Deleted: "red",
    Rejected: "red",
    Submitted: "purple",
    Superseded: "pink",
    Withdrawn: "pink",
  };

  public static readonly INPROGESS_STATUSES = [
    ProjectActivitiesConstants.STATUS_IN_PROGRESS,
    ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED,
    ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED,
  ];

  public static readonly INREVIEW_STATUSES = [
    ProjectActivitiesConstants.STATUS_SUBMITTED_TO_VVB,
    ProjectActivitiesConstants.STATUS_ASSESSMENT_ASSIGNED,
    ProjectActivitiesConstants.STATUS_ASSESSMENT_IN_PROGRESS,
    ProjectActivitiesConstants.STATUS_ASSESSMENT_AWAITING_REVIEW,
    ProjectActivitiesConstants.STATUS_REVIEW_ASSIGNED,
    ProjectActivitiesConstants.STATUS_REVIEW_IN_PROGRESS,
    ProjectActivitiesConstants.STATUS_REVIEW_COMPLETE,
    ProjectActivitiesConstants.STATUS_SUBMITTED_TO_CODE,
    ProjectActivitiesConstants.STATUS_SUBMITTED_TO_REGISTRY,
  ];

  public static readonly COMPLETED_STATUSES = [
    ProjectActivitiesConstants.STATUS_APPROVED,
    ProjectActivitiesConstants.STATUS_REJECTED,
    ProjectActivitiesConstants.STATUS_WITHDRAWN,
    ProjectActivitiesConstants.STATUS_SUPERSEDED,
    ProjectActivitiesConstants.STATUS_SUBMITTED,
  ];
}

export enum ActivityApplicableDefinitionType {
  GENERAL = "General",
  SOLO = "Solo",
  GROUP = "Group",
}

export enum ActivityOwnerType {
  PROJECT = "Project",
  ORGANISATION = "Organisation",
  GROUP = "Group",
}
