import "./ReportPanel.css";

import { ColorConstants } from "../../../../../../constants";
import { GetDefaultResponse } from "../../../../../../service/valuation";
import { capitalize, convertDateToStringDDMMYYYY, formatNumber, getPrefixFromCurrency } from "../../../../../../utils";
import {
  Button,
  Column,
  CursorDataGrid,
  DataGridColumnDefinition,
  Divider,
  FlyoutPanel,
  LineChart,
  ReadonlyText,
  Row,
} from "../../../../../../widget";
import { ReportPanelActivityDetails } from "../../models";
import { ReportPanelChartData } from "../../models/ReportPanelChartData";
import { useReportPanel } from "./useReportPanel";

interface ReportPanelProps {
  activityDetails?: ReportPanelActivityDetails;
  show: boolean;
  onClose: () => void;
}

const content = (
  projectsColumns: DataGridColumnDefinition[],
  futurePricesChartData?: ReportPanelChartData,
  monteCarloChartData?: ReportPanelChartData,
  reportData?: GetDefaultResponse
): JSX.Element => {
  return reportData ? (
    <div className="ReportPanelContent">
      <Row spacingV="l">
        <Column span={6}>
          <ReadonlyText label="Fund name" value={reportData.fund.fundName} />
        </Column>
        <Column span={6}>
          <ReadonlyText
            label="Fund NAV"
            // TODO JWI: when we return NAV replace calc
            value={`${getPrefixFromCurrency(reportData.fund.fundCcy)}${
              reportData.fund.total_value && reportData.fund.sharesIssued
                ? (reportData.fund.total_value / reportData.fund.sharesIssued).toFixed(2)
                : undefined
            }`}
          />
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={6}>
          <ReadonlyText label="Fund CCY" value={reportData.fund.fundCcy?.toUpperCase()} />
        </Column>
        <Column span={6}>
          <ReadonlyText
            label="Fund type"
            value={reportData.fund.fundType ? capitalize(reportData.fund.fundType) : undefined}
          />
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={6}>
          <ReadonlyText
            label="Fund value"
            value={`${getPrefixFromCurrency(reportData.fund.fundCcy)}${formatNumber(
              reportData.fund.total_value ?? undefined
            )}`}
          />
        </Column>
        <Column span={6}>
          <ReadonlyText label="Shared issued" value={reportData.fund.sharesIssued} />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={6}>
          <ReadonlyText
            label="Fund start date"
            value={reportData.fund.Startdate ? convertDateToStringDDMMYYYY(reportData.fund.Startdate) : undefined}
          />
        </Column>
        <Column span={6}>
          <ReadonlyText
            label="Share price"
            value={`${getPrefixFromCurrency(reportData.fund.fundCcy)}${formatNumber(
              reportData.fund.fund_share_price ?? undefined
            )}`}
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={12}>
          <h2>Projects</h2>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <CursorDataGrid
            data={reportData.holdings}
            columns={projectsColumns}
            clientSide
            pageable={false}
            filterable={false}
            sortable={false}
            showTotalCount={false}
          />
        </Column>
      </Row>
      <Row spacingV="xs">
        <Column span={12}>
          <h2>Projected prices</h2>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <div className="LineChartContainer">
            <LineChart
              labels={futurePricesChartData?.labels || []}
              datasets={futurePricesChartData?.datasets || []}
              offset={75}
              xAxisTitle="Date"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              xAxisTickCallbackFunction={(val: any, index: number) => {
                // Show every 5th label
                return index % 5 === 0 ? futurePricesChartData?.labels[val] : "";
              }}
              yAxisTitle={`Price (${getPrefixFromCurrency(reportData.fund.fundCcy)})`}
              yAxisTickCallbackFunction={(value, index: number) => {
                return index % 10 ? `${value}` : "";
              }}
            />
          </div>
        </Column>
      </Row>
      <Row spacingV="xs">
        <Column span={12}>
          <h2>MC simulated growth</h2>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <div className="LineChartContainer">
            <LineChart
              labels={monteCarloChartData?.labels || []}
              datasets={monteCarloChartData?.datasets || []}
              offset={75}
              customChartColours={[ColorConstants.GREEN_60, ColorConstants.GREEN, ColorConstants.DARK_GREEN]}
              defaultFill={{
                target: "-1",
                above: ColorConstants.GREEN_20,
                below: ColorConstants.GREEN_20,
              }}
              xAxisTitle="Years"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              xAxisTickCallbackFunction={(val: any, index: number) => {
                // Show every 5th label
                return index % 10 === 0 ? monteCarloChartData?.labels[val] : "";
              }}
              yAxisTitle={`Price (${getPrefixFromCurrency(reportData.fund.fundCcy)})`}
            />
          </div>
        </Column>
      </Row>
    </div>
  ) : (
    <div className="ReportPanelContent">
      <p className="body2 NoData">No report data</p>
    </div>
  );
};

const button = (editValuation: () => void): JSX.Element => {
  return <Button text="Edit valuation" onClick={editValuation} />;
};

export const ReportPanel = ({ activityDetails, show, onClose }: ReportPanelProps): JSX.Element => {
  const { isLoading, variant, reportData, projectsColumns, futurePricesChartData, monteCarloChartData, editValuation } =
    useReportPanel({
      activityDetails,
      onClose,
    });

  return (
    <FlyoutPanel
      show={show && !isLoading}
      onClose={onClose}
      size="Large"
      header={`${variant} Report`}
      buttonsChildren={button(editValuation)}
      contentChildren={content(projectsColumns, futurePricesChartData, monteCarloChartData, reportData)}
    />
  );
};
