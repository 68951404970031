import Axios, {
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseTransformer,
  InternalAxiosRequestConfig,
} from "axios";

import {
  ERROR_ACCESS_DENIED,
  ERROR_APPLICATION,
  ERROR_NO_RECORD_FOUND,
  ERROR_NO_ROWS_UPDATED,
  jsonReviver,
} from "../utils";
import { recursivelyRemoveEmptyStrings } from "../utils/rest";
import { getLogonRoute } from "../utils/routes";
import { Toast } from "../widget";
import { logError } from "./error";
import { memoizedRefreshAccessToken } from "./identity";
import { ServiceError, Status } from "./Shared";
import { getErrorMessageFromCode } from "./ValidationErrorFormatter";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const responseTransformer: AxiosResponseTransformer = (data: any): any => {
  if (data !== undefined && data !== null && typeof data === "string" && data !== "")
    return JSON.parse(data, jsonReviver);
  return data;
};

export const dateTransformer = (data: unknown): unknown => {
  if (data instanceof Date) {
    return data.toISOString().replace(/.\d\d\dZ$/, "Z");
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, dateTransformer(value)]));
  }
  return data;
};

export const requestTransformer: AxiosRequestTransformer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return recursivelyRemoveEmptyStrings(data);
};

export const axios = Axios.create({
  baseURL: window.API_URL,
  timeout: 10000,
  transformRequest: [requestTransformer].concat(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Axios.defaults.transformRequest as any
  ),
  transformResponse: [responseTransformer].concat(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Axios.defaults.transformResponse as any
  ),
});

export const requestHandler = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // Generic request data modification (eg, dateTime handling)
  if (config.headers && window.ACCESS_TOKEN) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${window.ACCESS_TOKEN}`;
  }
  return config;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorRequestHandler = (error: any): Promise<any> => {
  // Generic error handling code
  Toast.error({ message: "The application has encountered an unknown error." });
  logError({ error });
  return Promise.reject(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const responseHandler = (response: AxiosResponse): any => {
  // Generic response data modification (eg, dateTime handling)
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorResponseHandler = async (error: any): Promise<any> => {
  // Generic error handling code
  const config = error?.config;
  // Note response may be null if the connection timed out...
  const errors = error.response?.data?.errors as ServiceError[];
  errors?.forEach((e) => {
    const params: { [index: string]: string | undefined } = {
      ...e.parameters,
      property: e.property,
      path: e.path,
    };
    e.message = getErrorMessageFromCode(e.code, params);
  });

  // Check if is 401 Unauthorized error, refresh the access token and retry the query once
  if (error?.response?.status === 401 && !config?.sent) {
    config.sent = true;
    const result = await memoizedRefreshAccessToken();

    if (result.status === Status.Error) {
      // Redirect to login if not able to refresh token (e.g refresh token expired)
      window.location.href = getLogonRoute();
    }
    return axios(config);
  }
  if (error?.response?.status === 400) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ errors });
  }
  if (error?.response?.status === 403) {
    Toast.error({
      message: getErrorMessageFromCode(ERROR_ACCESS_DENIED),
    });
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ errors });
  }
  if (error?.response?.status === 404) {
    Toast.error({
      message: getErrorMessageFromCode(ERROR_NO_RECORD_FOUND),
    });
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ errors });
  }
  if (error?.response?.status === 409) {
    Toast.warn({
      message: getErrorMessageFromCode(ERROR_NO_ROWS_UPDATED),
    });
    return Promise.reject(errors);
  }
  // 500, request time-out (error.code == ECONNABORTED with no response object), or anything else
  Toast.error({
    message: getErrorMessageFromCode(ERROR_APPLICATION),
  });
  return Promise.reject(errors);
};

axios.interceptors.request.use(requestHandler, errorRequestHandler);
axios.interceptors.response.use(responseHandler, errorResponseHandler);
