import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ActivityReviewPageParams } from "../../../../../../models";
import { getActivityDetails, getActivityReviewDetails } from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";

interface ActivityReviewWizardProviderProps {
  children?: JSX.Element;
}

interface ActivityReviewWizardContextType {
  activityUuid: string | undefined;
  activityReviewUuid: string | undefined;
  activityHistoryUuid: string | undefined;
  currentOrganisationUuid: string | undefined;
  activityReviewRowVersion: number;
  setActivityReviewRowVersion: Dispatch<SetStateAction<number>>;
  refreshActivityWizard: boolean;
  setRefreshActivityWizard: Dispatch<SetStateAction<boolean>>;
}

export const ActivityReviewWizardContext = createContext<ActivityReviewWizardContextType>(
  {} as ActivityReviewWizardContextType
);

export const ActivityReviewWizardProvider = ({ children }: ActivityReviewWizardProviderProps): JSX.Element => {
  const { activityReviewUuid } = useParams<ActivityReviewPageParams.activityReviewUuid>();

  const { currentVerifierUuid } = useAuth();

  const [activityUuid, setActivityUuid] = useState<string | undefined>();
  const [activityHistoryUuid, setActivityHistoryUuid] = useState<string | undefined>();
  const [currentOrganisationUuid, setCurrentOrganisationUuid] = useState<string | undefined>();
  const [activityReviewRowVersion, setActivityReviewRowVersion] = useState<number>(1);
  const [refreshActivityWizard, setRefreshActivityWizard] = useState(false);

  // Is the user a guest user to the project and the activity (we need this for external discussion checks)
  const fetchIsExternalActivity = useCallback(
    async (uuid: string) => {
      const activityReviewDetailsResponse = await getActivityReviewDetails({ activityReviewUuid: uuid });

      if (activityReviewDetailsResponse.status === Status.Success && activityReviewDetailsResponse.data) {
        setActivityHistoryUuid(activityReviewDetailsResponse.data?.activityHistoryDetailsResponse.uuid);
        setActivityUuid(activityReviewDetailsResponse.data?.activityHistoryDetailsResponse.activity.uuid);
        const activityDetailsResponse = await getActivityDetails({
          activityUuid: activityReviewDetailsResponse.data?.activityHistoryDetailsResponse.activity.uuid,
        });

        if (activityDetailsResponse.status === Status.Success && activityDetailsResponse.data) {
          setCurrentOrganisationUuid(activityDetailsResponse.data.project?.verifier?.organisationUuid || undefined);
        }
      }
    },
    [activityReviewUuid]
  );

  useEffect(() => {
    if (activityReviewUuid && currentVerifierUuid) {
      fetchIsExternalActivity(activityReviewUuid);
    }
  }, [activityReviewUuid, currentVerifierUuid]);

  const memoisedValue = useMemo(
    () => ({
      activityUuid,
      activityReviewUuid,
      activityHistoryUuid,
      currentOrganisationUuid,

      activityReviewRowVersion,
      setActivityReviewRowVersion,
      refreshActivityWizard,
      setRefreshActivityWizard,
    }),
    [activityReviewUuid, currentOrganisationUuid, activityReviewRowVersion, refreshActivityWizard]
  );

  return <ActivityReviewWizardContext.Provider value={memoisedValue}>{children}</ActivityReviewWizardContext.Provider>;
};
