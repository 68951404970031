import { BaseResponseWithSingle, Status } from "../Shared";
import { valuationModelAxios } from "../valuationModelAxios";
import { GetDefaultResponse } from "./Types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDefault = async (req: any): Promise<BaseResponseWithSingle<GetDefaultResponse>> => {
  return valuationModelAxios
    .request<BaseResponseWithSingle<GetDefaultResponse>>({
      method: "post",
      url: "default",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data as unknown as GetDefaultResponse,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error || [],
      };
    });
};
