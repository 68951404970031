export class OrganisationPermissionConstants {
  // read
  public static readonly READ = "read";

  public static readonly READ_ORGANISATION_USERS = "readOrganisationUsers";

  // write
  public static readonly WRITE = "write";

  public static readonly WRITE_ORGANISATION_ACTIVITY = "writeOrganisationActivity";
}
