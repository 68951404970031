import { useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";

import { OrganisationTypeConstants } from "../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../models";
import { searchActivityDocuments, SearchActivityDocumentsResponse } from "../../../../service/query";
import { ResultData } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { flattenObject } from "../../../../utils";
import { getActivityDocumentRoute } from "../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../widget";
import {
  downloadDocumentCellFormatter,
  formatActivityDocumentHistoryDownloadLink,
} from "../../../developer/activities";
import { useActivityDashboardContextSwitcher } from "../../utils/activities/useActivityDashboardContextSwitcher";
import { DocumentDetailsCellFormatter } from "./components";

interface UseDocumentsReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  refresh: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useDocuments = (): UseDocumentsReturnData => {
  const { currentUserType } = useAuth();

  const { activityUuid, activityDetails, queryParams } = useActivityDashboardContextSwitcher();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [projectUuid, setProjectUuid] = useState<string | undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] =
    currentUserType !== OrganisationTypeConstants.VERIFIER
      ? [
          {
            key: "activityDefinitionDocumentType.displayName",
            name: "Type",
            dataType: "string",
            filterable: false,
            minWidth: 180,
          },
          {
            key: "file.filename",
            name: "Filename",
            dataType: "string",
            formatter: "stringWithEllipsis",
            filterable: true,
            sortable: true,
            minWidth: 180,
          },
          {
            key: "activityDocument.variant",
            name: "Variant",
            dataType: "string",
          },
          {
            key: "activityDocument.versionNumber",
            name: "Version",
            dataType: "number",
            formatter: "align",
            alignment: "center",
          },
          {
            key: "activityDocument.createdAt",
            name: "Date uploaded",
            dataType: "Date",
            formatter: "dateOnly",
            alignment: "center",
            filterable: false,
          },
          {
            key: "activityDocument.createdByUser.fullName",
            name: "Uploaded by",
            dataType: "string",
            filterable: true,
            sortable: true,
          },
          {
            key: "historyLink",
            name: "Details",
            dataType: "string",
            formatter: "custom",
            alignment: "center",
            filterable: false,
            sortable: false,
            customCellFormatter: DocumentDetailsCellFormatter,
          },
          {
            key: "downloadIcon",
            name: "Download latest",
            dataType: "string",
            formatter: "custom",
            alignment: "center",
            filterable: false,
            sortable: false,
            customCellFormatter: downloadDocumentCellFormatter,
          },
        ]
      : [
          {
            key: "activityDefinitionDocumentType.displayName",
            name: "Type",
            dataType: "string",
            filterable: false,
            minWidth: 180,
          },
          {
            key: "file.filename",
            name: "Filename",
            dataType: "string",
            formatter: "stringWithEllipsis",
            filterable: true,
            sortable: true,
            minWidth: 180,
          },
          {
            key: "activityDocument.variant",
            name: "Variant",
            dataType: "string",
          },
          {
            key: "activityDocument.createdAt",
            name: "Date uploaded",
            dataType: "Date",
            formatter: "dateOnly",
            alignment: "center",
            filterable: false,
          },
          {
            key: "activityDocument.createdByUser.fullName",
            name: "Uploaded by",
            dataType: "string",
            filterable: true,
            sortable: true,
          },
          {
            key: "downloadIcon",
            name: "Download latest",
            dataType: "string",
            formatter: "custom",
            alignment: "center",
            filterable: false,
            sortable: false,
            customCellFormatter: downloadDocumentCellFormatter,
          },
        ];
  const defaultSortingCriteria: SortColumn[] = [
    { columnKey: "activityDefinitionDocumentType.displayName", direction: "DESC" },
  ];

  useEffect(() => {
    if (activityDetails) {
      setProjectUuid(activityDetails?.project?.uuid ?? "");
      setRefresh(!refresh);
    }
  }, [activityDetails]);

  const formatData = useCallback(
    (responseData: SearchActivityDocumentsResponse | undefined): ResultData[] => {
      return (
        responseData?.results?.map((d) => {
          const result = flattenObject(d);

          result.downloadIcon = formatActivityDocumentHistoryDownloadLink({
            activityDocumentHistoryUuid: d.activityDocument.uuid,
          });

          result.historyLink = getActivityDocumentRoute(
            activityUuid ?? "",
            d.activityDocument.uuid,
            currentUserType,
            queryParams
          );
          return result;
        }) || []
      );
    },
    [projectUuid]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    if (
      !projectUuid ||
      projectUuid.length < 10 ||
      (!activityDetails?.draftVersion && !activityDetails?.currentVersion && !activityDetails?.isUnderReview)
    ) {
      setDataIsLoading(false);
      return data;
    }

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activityDefinitionDocumentType = {
      ...filterCriteria.activityDefinitionDocumentType,
      isGenerated: {
        operator: "eq",
        value: false,
      },
    };

    await searchActivityDocuments({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
      currentOnly: true,
      activityDocumentHistoryUuid: null,
      activityHistoryUuid: null,
      activityUuid: activityUuid ?? null,
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  return {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    refresh,
  };
};
