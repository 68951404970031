import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useIsLoadingWrapper } from "../../../../../../utils";
import { getValuationsRoute } from "../../../../../../utils/routes";
import { Toast } from "../../../../../../widget";
import { isSuccessfulCompleteActivityDraft } from "../../../../../shared/utils/activities/completeActivity";

interface useSubmitValuationModalProps {
  activityUuid: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
}

interface useSubmitValuationModalReturnData {
  isLoading: boolean;
  handleSubmit: () => void;
}

export const useSubmitValuationModal = ({
  activityUuid,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
}: useSubmitValuationModalProps): useSubmitValuationModalReturnData => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const potentiallyCompleteDraft = async (): Promise<boolean> => {
    if (isDraft && activityHistoryRowVersion) {
      const successfullyCompletedDraft = await isSuccessfulCompleteActivityDraft(
        activityHistoryUuid,
        activityHistoryRowVersion
      );

      if (!successfullyCompletedDraft) {
        Toast.error({ message: "Activity failed to save" });

        return false;
      }
    }
    return true;
  };

  const handleSubmit = useIsLoadingWrapper(async (): Promise<void> => {
    const successfullyCompletedDraft = await potentiallyCompleteDraft();
    if (!successfullyCompletedDraft) return;

    // Pass activityHistoryUuid to open report
    navigate(getValuationsRoute(`activityUuid=${activityUuid}`));
  }, setIsLoading);

  return {
    handleSubmit,
    isLoading,
  };
};
