import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate } from "react-router-dom";

import {
  ActivityApplicableDefinitionType,
  ActivityOwnerType,
  ProjectActivitiesConstants,
  ProjectPermissionConstants,
} from "../../../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../../../models";
import { createActivity } from "../../../../../../service/activity";
import { logError } from "../../../../../../service/error";
import {
  getCurrentUserProjectPermissions,
  GetCurrentUserProjectPermissionsResponse,
  searchActivities,
  SearchActivitiesRequest,
  SearchActivitiesResponse,
  searchActivityDefinitions,
  SearchActivityHistoryResponse,
} from "../../../../../../service/query";
import { FilterGroups, ResultData, Status } from "../../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../../../useAuth";
import { flattenObject, hasActivityPermissionForProject } from "../../../../../../utils";
import {
  getActivityDashboardTabRoute,
  getActivityEditRoute,
  getActivityViewRoute,
} from "../../../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
  ProfileMenuItemType,
  Toast,
} from "../../../../../../widget";
import { CreateActivityVariantModalData } from "../../../../components/CreateActivityVariantModal/useCreateActivityVariantModal";
import { resumeActivity } from "../../../../utils";
import { ProjectContext } from "../../ProjectContext";
import { ActivitiesDetailsCellFormatter } from "./components";

interface UseActivitiesReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  activityDropdownOptions: ProfileMenuItemType[];
  showActivityDropdown: boolean;
  setShowActivityDropdown: Dispatch<SetStateAction<boolean>>;
  showCreateActivityVariantModal: boolean;
  setShowCreateActivityVariantModal: Dispatch<SetStateAction<boolean>>;
  createActivityVariantModalData?: CreateActivityVariantModalData;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  showVersionConflictModal: boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  hasProjectPermission: (permission: string) => boolean;
  refreshActivities?: boolean;
}

export const useActivities = (): UseActivitiesReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();
  const { projectDetails, hasProjectPermission, refreshActivities } = useContext(ProjectContext);

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const [showActivityDropdown, setShowActivityDropdown] = useState(false);
  const [showCreateActivityVariantModal, setShowCreateActivityVariantModal] = useState(false);
  const [activityDropdownOptions, setActivityDropdownOptions] = useState<ProfileMenuItemType[]>([]);
  const [createActivityVariantModalData, setCreateActivityVariantModalData] =
    useState<CreateActivityVariantModalData>();
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "activityLink",
      name: "Activity",
      dataType: "string",
      formatter: "buttonLink",
    },
    { key: "variant", name: "Variant", dataType: "string", minWidth: 200, sortable: true },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      alignment: "center",
      minWidth: 240,
      filterable: false,
      sortable: true,
    },
    {
      key: "latestVersion.versionNumber",
      name: "Version no.",
      dataType: "number",
      formatter: "align",
      alignment: "center",
      minWidth: 150,
    },
    {
      key: "createdAt",
      name: "Date created",
      dataType: "Date",
      formatter: "dateOnly",
      minWidth: 150,
      filterable: false,
    },
    {
      key: "latestVersion.updatedAt",
      name: "Last updated",
      dataType: "Date",
      formatter: "dateOnly",
      minWidth: 150,
      filterable: false,
    },
    {
      key: "latestVersion.updatedByUser.fullName",
      name: "Updated by",
      dataType: "string",
      minWidth: 150,
      filterable: false,
    },
    {
      key: "detailsLink",
      name: "More details",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      customCellFormatter: ActivitiesDetailsCellFormatter,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "latestVersion.updatedAt", direction: "DESC" }];

  const formatData = useCallback(
    (
      responseData: SearchActivitiesResponse | undefined,
      permissions: GetCurrentUserProjectPermissionsResponse[]
    ): ResultData[] =>
      responseData?.results?.map((d) => {
        const result = flattenObject(d);

        if (
          (d.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ||
            d.status === ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED) &&
          (d.project || d.group) &&
          hasActivityPermissionForProject(
            permissions,
            d.project?.uuid,
            ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY,
            d.group?.uuid
          )
        ) {
          result.activityLink = {
            text: d.activityDefinition.displayName,
            action: () => {
              if (d.isUnderReview && d.draftVersion) {
                navigate(
                  getActivityViewRoute(
                    d.draftVersion?.uuid,
                    currentUserType,
                    d.project ? `projectUuid=${d.project.uuid}` : ""
                  )
                );
              } else if (d.isUnderReview && d.currentVersion) {
                navigate(
                  getActivityViewRoute(
                    d.currentVersion?.uuid,
                    currentUserType,
                    d.project ? `projectUuid=${d.project.uuid}` : ""
                  )
                );
              } else {
                resumeActivity(
                  navigate,
                  setDraftActivityHistoryUuid,
                  setNewVersionActivityHistory,
                  setShowVersionConflictModal,
                  d.uuid,
                  d.draftVersion?.uuid,
                  d.draftVersion?.versionNumber,
                  undefined,
                  true
                );
              }
            },
          } as DataGridButtonLinkCellFormatterData;
        } else {
          result.activityLink = {
            text: d.activityDefinition.displayName,
            action: () => {
              if (d.currentVersion) {
                navigate(
                  getActivityViewRoute(d.currentVersion.uuid, currentUserType, `projectUuid=${projectDetails?.uuid}`)
                );
              } else if (d.reviewVersion) {
                navigate(
                  getActivityViewRoute(d.reviewVersion.uuid, currentUserType, `projectUuid=${projectDetails?.uuid}`)
                );
              } else {
                Toast.error({
                  message: `This activity has not yet been published. Once ${d.createdByUser.fullName} has published the activity, you can view the activity.`,
                });
              }
            },
          } as DataGridButtonLinkCellFormatterData;
        }
        result.detailsLink = getActivityDashboardTabRoute(
          d.uuid,
          "documents",
          currentUserType,
          `projectUuid=${projectDetails?.uuid}`
        );

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!projectDetails?.uuid) throw new Error("Uuid must not be empty!");

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.activityLink) {
      filterCriteria.activityDefinition = {
        displayName: filterCriteria.activityLink,
      };
      filterCriteria.activityLink = undefined;
    }

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const sortKeyMap: Record<string, string> = {
      activityLink: "activityDefinition.displayName",
    };

    const request: SearchActivitiesRequest & FilterGroups<SearchActivitiesRequest["filter"]> = {
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: sortKeyMap[s.key] || s.key,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    };

    // Always filter by project
    let filterResults: any = {
      project: {
        uuid: {
          operator: "eq",
          value: projectDetails?.uuid || null,
        },
      },
    };

    // If in group, filter by group
    if (projectDetails?.group?.groupUuid) {
      filterResults = {
        ...filterResults,
        group: {
          uuid: {
            operator: "eq",
            value: projectDetails?.group?.groupUuid || null,
          },
        },
      };
    }

    request.filterGroups = [
      {
        operator: "or",
        filter: {
          results: filterResults,
        },
      },
    ];

    await searchActivities(request)
      .then(async (response) => {
        await getCurrentUserProjectPermissions({
          projectUuids: projectDetails?.uuid ? [projectDetails.uuid] : null,
          groupUuids: projectDetails?.group?.groupUuid ? [projectDetails?.group?.groupUuid] : null,
        }).then((permissions) => {
          data = {
            resultData: formatData(response.data, permissions.data || []),
            paging: {
              startCursor: response.data?.paging?.startCursor || "",
              endCursor: response.data?.paging?.endCursor || "",
              pageSize: paging.pageSize || 10,
              totalCount: response.data?.paging?.total || 0,
              hasNextPage: response.data?.paging?.hasNextPage || false,
              hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
            },
          };
        });
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  const onCreateActivity = async (
    ownerType: string,
    ownerUuid: string,
    activityDefinitionVersionUuid: string
  ): Promise<void> => {
    if (!projectDetails?.uuid) throw new Error("Project uuid must not be empty!");
    if (!activityDefinitionVersionUuid) throw new Error("ActivityDefinitionVersion uuid must not be empty!");
    const res = await createActivity({
      ownerType,
      ownerUuid,
      activityDefinitionVersionUuid,
      variant: null,
    });

    if (res.status === Status.Success && res.data) {
      navigate(getActivityEditRoute(res.data.activityHistoryUuid));
    }
    if (res.status === Status.Error && res.errors && res.errors.length) {
      Toast.error({ message: getErrorMessageFromCode(res.errors[0].code, res.errors[0].parameters) });
    }
  };

  const onActivityDropdownOptionAction = (
    ownerType: string | undefined,
    ownerUuid: string | undefined,
    activityDefinitionVersionUuid: string,
    hasVariant: boolean
  ): void => {
    if (ownerType === undefined) throw new Error(`ownerType is required for createActivity`);
    if (ownerUuid === undefined) throw new Error(`ownerUuid is required for createActivity`);

    setShowActivityDropdown(false);
    if (hasVariant) {
      setCreateActivityVariantModalData({
        ownerType,
        ownerUuid,
        activityDefinitionVersionUuid,
      });
      setShowCreateActivityVariantModal(true);
    } else {
      onCreateActivity(ownerType, ownerUuid, activityDefinitionVersionUuid);
    }
  };

  const getOwnerUuidByDefinitionType = useCallback(
    (definitionType: string): string | undefined => {
      switch (definitionType) {
        case ActivityApplicableDefinitionType.SOLO:
        case ActivityApplicableDefinitionType.GENERAL:
          return projectDetails?.uuid;
        case ActivityApplicableDefinitionType.GROUP:
          return projectDetails?.group?.groupUuid;
        default:
          logError({
            error: `The activity definition is missing applicableActivityDefinitionType`,
          });
          return undefined;
      }
    },
    [projectDetails]
  );

  const getOwnerTypeByDefinitionType = useCallback(
    (definitionType: string): string | undefined => {
      switch (definitionType) {
        case ActivityApplicableDefinitionType.SOLO:
        case ActivityApplicableDefinitionType.GENERAL:
          return ActivityOwnerType.PROJECT;
        case ActivityApplicableDefinitionType.GROUP:
          return ActivityOwnerType.GROUP;
        default:
          logError({
            error: `The activity definition is missing applicableActivityDefinitionType`,
          });
          return undefined;
      }
    },
    [projectDetails]
  );

  const getAndSetActivityDefinitions = async (
    standardUuid: string,
    projectStatus: string,
    isInGroup: boolean
  ): Promise<void> => {
    const result = await searchActivityDefinitions({
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 10,
      },
      applicableProjectStatus: [projectStatus],
      standardUuid,
      applicableActivityDefinitionType: isInGroup
        ? ActivityApplicableDefinitionType.GROUP
        : ActivityApplicableDefinitionType.SOLO,
    });

    if (result.status === Status.Success && result.data) {
      const optionList: ProfileMenuItemType[] = [];
      let id = 1;
      result.data.results.forEach((el) => {
        if (el.activeVersions.length > 1) {
          el.activeVersions.forEach((av) => {
            optionList.push({
              id,
              value: `${el.displayName} ${av.versionNumber}`,
              action: () =>
                onActivityDropdownOptionAction(
                  getOwnerTypeByDefinitionType(el.applicableActivityDefinitionType),
                  getOwnerUuidByDefinitionType(el.applicableActivityDefinitionType),
                  av.uuid,
                  el.hasVariant
                ),
            });
            id += 1;
          });
        } else {
          optionList.push({
            id,
            value: `${el.displayName}`,
            action: () =>
              onActivityDropdownOptionAction(
                getOwnerTypeByDefinitionType(el.applicableActivityDefinitionType),
                getOwnerUuidByDefinitionType(el.applicableActivityDefinitionType),
                el.activeVersions[0].uuid,
                el.hasVariant
              ),
          });
          id += 1;
        }
      });
      setActivityDropdownOptions(optionList);
    }
  };

  useEffect(() => {
    if (projectDetails) {
      getAndSetActivityDefinitions(
        projectDetails.standard.uuid,
        projectDetails.status,
        !!projectDetails.group?.groupUuid
      );
    }
  }, [projectDetails]);

  return {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    activityDropdownOptions,
    showActivityDropdown,
    setShowActivityDropdown,
    showCreateActivityVariantModal,
    setShowCreateActivityVariantModal,
    createActivityVariantModalData,
    onChange,
    setShowVersionConflictModal,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    hasProjectPermission,
    refreshActivities,
  };
};
