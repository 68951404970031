/* Note this page is using imports from /developer - this was done rather than
 * moving the file to shared, as we wanted to keep the git history of the file given the
 * complexity of some of bugs that have occured surrounding the wizard in the past.
 */
import "./Edit.css";

import { ArrowRoundLeftDarkIcon, ArrowRoundRightLightIcon, DownwardArrowLightIcon } from "../../../../../../assets";
import { VersionConflictModal } from "../../../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { hasEnoughSpace } from "../../../../../../utils";
import {
  Button,
  Column,
  ProfileMenu,
  ProfileMenuItem,
  Row,
  Stepper,
  UserHeader,
  Wizard,
} from "../../../../../../widget";
import { ActivityWizardContent } from "../../../../../developer/activities";
import { GenericModal } from "../../../../../developer/components";
import { ReviewContent } from "../../components";
import { useEdit } from "./useEdit";

export const Edit = (): JSX.Element => {
  const {
    steps,
    l3Steps,
    clickedL3Step,
    setClickedL3Step,
    stepFields,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    isReview,
    showReviewPage,
    activityDefinitionInfo,
    activityCompletionPercentage,
    errors,
    currentUserType,
    movePrevious,
    moveNext,
    moveTo,
    moveToReview,
    moveToLastStep,
    isMoveNextLoading,
    isMovePreviousLoading,
    isMoveToReviewLoading,
    isMoveToLastStepLoading,
    isSaveOrPublishDraftLoading,
    showVersionConflictModal,
    setShowVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    showSaveAndCloseDropdown,
    setShowSaveAndCloseDropdown,
    setDraftActivityHistoryUuid,
    setNewVersionActivityHistory,
    saveAndCloseOptions,
    showSaveDraftModal,
    onConfirmSaveDraftModal,
    onCloseSaveDraftModal,
    saveAndCloseBtnRef,
  } = useEdit();

  const saveAndCloseBtn = (): JSX.Element => {
    return (
      <div>
        <div ref={saveAndCloseBtnRef}>
          <Button
            text="Save & Close"
            onClick={() => {
              setShowSaveAndCloseDropdown(true);
            }}
            icon={<DownwardArrowLightIcon className="SaveCloseArrow" />}
            variant="secondary"
          />
        </div>
        <ProfileMenu
          show={showSaveAndCloseDropdown && !isSaveOrPublishDraftLoading}
          onClose={() => setShowSaveAndCloseDropdown(false)}
          position={
            saveAndCloseBtnRef.current &&
            hasEnoughSpace(saveAndCloseBtnRef.current.getBoundingClientRect().top, 150, document.body.clientHeight)
              ? { top: "0" }
              : { top: "-80px", left: "160px" }
          }
        >
          {saveAndCloseOptions.map((el) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <ProfileMenuItem key={el.id} profileMenuItem={el} onClick={el.action!} />
          ))}
        </ProfileMenu>
      </div>
    );
  };

  return isLoading ? (
    <div />
  ) : (
    <div className={`${currentUserType} ActivityWizardContainer ActivityWizardContainer_Edit`}>
      <div className="ActivityWizardContainerWizard">
        <Wizard
          title={activityData?.activity?.variant || ""}
          activityName={activityDefinitionInfo?.displayName || ""}
          activityVersion={`v${activityDefinitionInfo?.versionNumber || ""}`}
          currentUserType={currentUserType}
          steps={steps}
          completionPercentage={activityCompletionPercentage}
          handleMoveToStep={moveTo}
        />
      </div>
      <div className="ActivityWizardContainerContent">
        <Row spacingV="m" className="ActivityWizardContainerContentUserHeader">
          <Column span={12}>
            <UserHeader />
          </Column>
        </Row>
        {showReviewPage && isReview ? (
          <ReviewContent
            setShowVersionConflictModal={setShowVersionConflictModal}
            setDraftActivityHistoryUuid={setDraftActivityHistoryUuid}
            setNewVersionActivityHistory={setNewVersionActivityHistory}
          />
        ) : (
          <>
            {currentStepName && (
              <Row spacingV={l3Steps && l3Steps.length > 0 ? "ml" : "ll"} className="ActivityWizardContainerStepName">
                <Column span={12}>
                  <h1>{currentStepName}</h1>
                </Column>
              </Row>
            )}
            {l3Steps && l3Steps.length > 0 && (
              <Row spacingV="ll" className="ActivityWizardContainerStepper">
                <Column span={12}>
                  <Stepper
                    steps={l3Steps}
                    currentSubStepKey={currentStepKeys.length === 3 ? currentStepKeys[2] : undefined}
                    onClick={(stepKey) => {
                      const isCurrentStep = currentStepKeys.length === 3 && currentStepKeys[2] === stepKey;
                      const l3StepKeys = [...currentStepKeys.slice(0, -1), stepKey];

                      if (stepKey === clickedL3Step) {
                        return;
                      }

                      if (!isCurrentStep) {
                        setClickedL3Step(l3StepKeys.at(-1));
                        moveTo(l3StepKeys);
                      }
                    }}
                    currentUserType={currentUserType}
                  />
                </Column>
              </Row>
            )}
            <Row spacingV="ll">
              <Column span={12}>
                <ActivityWizardContent fields={stepFields} errors={errors} />
              </Column>
            </Row>
          </>
        )}
        <Row spacingV="ll">
          <Column span={12}>
            <div className="ActivityWizardContainerContentActions">
              {hasPrevious && (
                <Button
                  text="Previous"
                  onClick={movePrevious}
                  variant="tertiary"
                  icon={<ArrowRoundLeftDarkIcon />}
                  iconPosition="left"
                  isLoading={isMovePreviousLoading}
                />
              )}
              {isReview && (
                <Button
                  text="Previous"
                  onClick={moveToLastStep}
                  variant="tertiary"
                  icon={<ArrowRoundLeftDarkIcon />}
                  iconPosition="left"
                  isLoading={isMoveToLastStepLoading}
                />
              )}
              {hasNext && (
                <Button
                  text="Next"
                  onClick={moveNext}
                  variant="primary"
                  icon={<ArrowRoundRightLightIcon />}
                  isLoading={isMoveNextLoading}
                />
              )}
              {!hasNext && showReviewPage && !isReview && (
                <Button
                  text="Next"
                  onClick={moveToReview}
                  variant="primary"
                  icon={<ArrowRoundRightLightIcon />}
                  isLoading={isMoveToReviewLoading}
                />
              )}
              {saveAndCloseBtn()}
            </div>
          </Column>
        </Row>
      </div>
      <VersionConflictModal
        isForActivitiesEditPage
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
        hideDownloadButton
      />
      <GenericModal
        show={showSaveDraftModal && !isSaveOrPublishDraftLoading}
        onConfirm={() => onConfirmSaveDraftModal(false)}
        onConfirmOptional={() => onConfirmSaveDraftModal(true)}
        closeModal={onCloseSaveDraftModal}
        data={{
          title: "Save draft",
          text: [
            "By not publishing these updates, your work will remain in draft and is at risk of being lost if another user with access to the activity publishes their work ahead of you. Comments will also not be visible to other users at your organisation until the draft is published.",
            "How would you like to proceed?",
          ],
          confirmText: "Save draft",
          confirmOptionalText: "Publish draft",
          cancelText: "Continue editing",
        }}
      />
    </div>
  );
};
