import "./VersionConflictModal.css";

import { DownloadDarkIcon } from "../../../../assets";
import { SearchActivityHistoryResponse } from "../../../../service/query";
import { Button, Column, CursorDataGrid, Modal, Row } from "../../../../widget";
import { useVersionConflictModal } from "./useVersionConflictModal";

interface VersionConflictModalProps {
  isForActivitiesEditPage?: boolean;
  isProjectDashboard?: boolean;
  hideDownloadButton?: boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  showVersionConflictModal: boolean;
  closeModal: () => void;
}

export const VersionConflictModal = ({
  isForActivitiesEditPage = false,
  isProjectDashboard = false,
  hideDownloadButton = false,
  showVersionConflictModal,
  draftActivityHistoryUuid,
  closeModal,
  newVersionActivityHistory,
}: VersionConflictModalProps): JSX.Element => {
  const {
    tableColumnsTopProjects,
    data,
    downloadDraft,
    onResume,
    isDocumentDownloading,
    isResumeLatestActivityLoading,
  } = useVersionConflictModal(
    isForActivitiesEditPage,
    isProjectDashboard,
    draftActivityHistoryUuid,
    newVersionActivityHistory
  );

  return (
    <Modal show={showVersionConflictModal} title="New version available" onClose={closeModal}>
      <Row spacingV="2xl" justify="center">
        <Column span={10}>
          <p className="body2">
            A new version of this activity has been saved and this draft is now out-of-date. Please resume the latest
            version of the activity below. In order to prevent losing data, complete an activity by choosing &apos;Save
            and Close&apos; to create an audited version of the activity.
          </p>
          <p className="body2">If required, you can download the draft using the link below.</p>
        </Column>
      </Row>
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <CursorDataGrid
            data={data ?? []}
            onChange={undefined}
            columns={tableColumnsTopProjects}
            clientSide={false}
            pageable={false}
            sortable={false}
            pagination={undefined}
            dataIsLoading={false}
            showTotalCount={false}
            noDataMessage="No activities to display"
          />
        </Column>
      </Row>
      <div className="VersionConflictModalButtons">
        {newVersionActivityHistory && (
          <Button onClick={onResume} text="Resume latest activity version" isLoading={isResumeLatestActivityLoading} />
        )}

        {!hideDownloadButton && (
          <Button
            text="Download your draft"
            onClick={downloadDraft}
            loadingText="Downloading"
            isLoading={isDocumentDownloading}
            icon={<DownloadDarkIcon />}
            variant="secondary"
            iconPosition="left"
          />
        )}
      </div>
    </Modal>
  );
};
