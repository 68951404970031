import "./ComponentRow.css";

import { createPortal } from "react-dom";

import { AddDiscussionDarkIcon, ExistingDiscussionDarkIcon } from "../../../../../../assets";
import { GetDiscussionThreadsResponse } from "../../../../../../service/query";
import { Column, Discussion, Row } from "../../../../../../widget";
import { TabNotificationIndicator } from "../../../../../../widget/wayfinding/Tabs/components";
import { useComponentRow } from "./useComponentRow";

interface ComponentRowProps {
  reactEl: JSX.Element;
  discussions: GetDiscussionThreadsResponse[] | undefined;
  componentKey: string;
  dataPath: string;
  rowWrapKey?: string;
  id: string | undefined;
  isForRepeater?: boolean;
  isForTableRepeater?: boolean;
  isForViewMode?: boolean;
  repeaterIndex?: number;
  isActivityWizardLite?: boolean;
}

export const ComponentRow = ({
  reactEl,
  discussions,
  componentKey,
  dataPath,
  rowWrapKey,
  id,
  isForTableRepeater = false,
  isForRepeater = false,
  isForViewMode = false,
  repeaterIndex,
  isActivityWizardLite = false,
}: ComponentRowProps): JSX.Element => {
  const {
    addDiscussionRef,
    isAddDiscussionVisible,
    isExistingDiscussionVisible,
    showUnreadIndicator,
    canWriteExternalDiscussion,
    handleOpenExistingDiscussion,
    handleOpenNewDiscussion,
    handleCloseDiscussion,
    newThreadInformation,
    discussionPosition,
    internalThreads,
    externalThreads,
    setInternalThreads,
    setExternalThreads,
  } = useComponentRow(discussions, componentKey, dataPath, isForRepeater, repeaterIndex);

  return (
    <Row
      id={id}
      spacingV="ml"
      className={`CreatedComponentRow ${isActivityWizardLite && "IsActivityWizardLite"} ${
        isForViewMode && "IsForViewMode"
      } ${(isAddDiscussionVisible || isExistingDiscussionVisible) && "IsDiscussionOpen"} ${
        isForTableRepeater && "IsForTableRepeater"
      }`}
      align="center"
    >
      <Column style={isForRepeater ? { paddingLeft: "var(--spacing-ml)" } : {}} span={isForTableRepeater ? 11 : 6}>
        {reactEl}
      </Column>

      {!isActivityWizardLite && (
        <div className="IconsContainer">
          {internalThreads.length !== 0 || (externalThreads.length !== 0 && canWriteExternalDiscussion) ? (
            <div
              ref={addDiscussionRef}
              className="ViewDiscussionIconContainer"
              role="button"
              id={rowWrapKey}
              title="View discussion"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleOpenExistingDiscussion();
                }
              }}
              onClick={() => handleOpenExistingDiscussion()}
            >
              <ExistingDiscussionDarkIcon />
              {showUnreadIndicator && <TabNotificationIndicator />}
            </div>
          ) : (
            !isForViewMode && (
              <div
                ref={addDiscussionRef}
                className="CreateDiscussionIconContainer"
                role="button"
                id={rowWrapKey}
                title="Add discussion"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOpenNewDiscussion();
                  }
                }}
                onClick={() => handleOpenNewDiscussion()}
              >
                <AddDiscussionDarkIcon />
              </div>
            )
          )}
        </div>
      )}

      {isAddDiscussionVisible &&
        createPortal(
          <div style={{ top: discussionPosition.top, left: discussionPosition.left, position: "absolute" }}>
            <Discussion
              internalThreads={internalThreads}
              externalThreads={externalThreads}
              setInternalThreads={setInternalThreads}
              setExternalThreads={setExternalThreads}
              closeDiscussion={() => {
                handleCloseDiscussion();
              }}
              newThreadInformation={newThreadInformation}
              isForViewMode={isForViewMode}
            />
          </div>,
          document.getElementById("root") as HTMLElement
        )}
      {isExistingDiscussionVisible &&
        createPortal(
          <div style={{ top: discussionPosition.top, left: discussionPosition.left, position: "absolute" }}>
            <Discussion
              internalThreads={internalThreads}
              externalThreads={externalThreads}
              setInternalThreads={setInternalThreads}
              setExternalThreads={setExternalThreads}
              closeDiscussion={() => {
                handleCloseDiscussion();
              }}
              newThreadInformation={newThreadInformation}
              isForViewMode={isForViewMode}
            />
          </div>,
          document.getElementById("root") as HTMLElement
        )}
    </Row>
  );
};
