import "./Wizard.css";

import { Link } from "react-router-dom";

import {
  DocumentCircleGreenIcon,
  DocumentCircleLightIcon,
  InformationCircleGreenIcon,
  KanaLightLogo,
} from "../../../assets";
import { OrganisationTypeConstants } from "../../../constants";
import { getDashboardRoute } from "../../../utils/routes";
import { Divider, HorizontalStatusKPI } from "../../general";
import { Step, StepProps } from "./components";
import { useWizard } from "./useWizard";

interface WizardProps {
  title: string;
  activityName: string;
  activityVersion: string;
  completionPercentage: number; // 0..1
  currentUserType: OrganisationTypeConstants;
  steps: StepProps[];
  isReview?: boolean;
  isIntroduction?: boolean;
  showLogo?: boolean;
  handleMoveToReview?: () => void;
  handleMoveToIntroduction?: () => void;
  handleMoveToStep: (stepKeys: string[]) => void;
}

export const Wizard = ({
  title,
  activityName,
  activityVersion,
  completionPercentage,
  currentUserType,
  steps,
  isReview,
  isIntroduction,
  showLogo = true,
  handleMoveToReview,
  handleMoveToIntroduction,
  handleMoveToStep,
}: WizardProps): JSX.Element => {
  const { stepRefs, clickedSubStep, setClickedSubStep, handleMoveToStaticStepAndResetSteps } = useWizard({
    steps,
  });

  return (
    <div className={`${currentUserType} Wizard`}>
      {showLogo && (
        <Link to={getDashboardRoute(currentUserType)} className="WizardLogoContainer">
          <KanaLightLogo className="WizardLogo" />
        </Link>
      )}
      <div className="HorizontalStatusWrapper">
        <HorizontalStatusKPI
          status={Math.round(100 * completionPercentage)}
          projectName={title}
          activityName={activityName}
          activityVersion={activityVersion}
        />
      </div>
      <div className="Steps">
        {handleMoveToIntroduction && isIntroduction !== undefined && (
          <>
            <div className={`Step ${currentUserType}`}>
              <div
                role="button"
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleMoveToStaticStepAndResetSteps(handleMoveToIntroduction) : null
                }
                onClick={() => handleMoveToStaticStepAndResetSteps(handleMoveToIntroduction)}
                tabIndex={0}
                aria-label="Introduction"
                className="Header"
              >
                <div className={`HeaderLeftSection${isIntroduction ? " Introduction" : ""}`}>
                  <InformationCircleGreenIcon />
                  <p className="body2">Introduction</p>
                </div>
              </div>
            </div>
            <Divider type="horizontal" />
          </>
        )}
        {steps.map((step, index) => (
          <Step
            ref={stepRefs.current[index]}
            key={step.key}
            step={step}
            lastStep={index === steps.length - 1}
            isReview={isReview}
            currentUserType={currentUserType}
            handleMoveToStep={handleMoveToStep}
            clickedSubStep={clickedSubStep}
            setClickedSubStep={setClickedSubStep}
          />
        ))}
        <Divider type="horizontal" />
        {isReview !== undefined && (
          <div className={`Step ${currentUserType}`}>
            <div
              role="button"
              onKeyDown={(e) => (e.key === "Enter" ? handleMoveToStaticStepAndResetSteps(handleMoveToReview) : null)}
              onClick={() => handleMoveToStaticStepAndResetSteps(handleMoveToReview)}
              tabIndex={0}
              aria-label="Review"
              className="Header"
            >
              <div className={`HeaderLeftSection${isReview ? " Review" : ""}`}>
                {completionPercentage === 1 ? (
                  <>
                    <DocumentCircleGreenIcon />
                    <p className="body2">Review</p>
                  </>
                ) : (
                  <>
                    <DocumentCircleLightIcon />
                    <p className="body2">Review</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
