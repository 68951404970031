import Axios from "axios";

import {
  errorRequestHandler,
  errorResponseHandler,
  requestHandler,
  requestTransformer,
  responseHandler,
  responseTransformer,
} from "./axios";

export const valuationModelAxios = Axios.create({
  baseURL: window.VALUATION_MODEL_URL,
  timeout: 10000,
  transformRequest: [requestTransformer].concat(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Axios.defaults.transformRequest as any
  ),
  transformResponse: [responseTransformer].concat(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Axios.defaults.transformResponse as any
  ),
});

valuationModelAxios.interceptors.request.use(requestHandler, errorRequestHandler);
valuationModelAxios.interceptors.response.use(responseHandler, errorResponseHandler);
