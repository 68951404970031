import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { EditFileBlueIcon, EyeBlueIcon, TrashDark } from "../../../../assets";
import { ActivityOwnerType, OrganisationPermissionConstants, OrganisationTypeConstants } from "../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../models";
import {
  getActivityDetails,
  searchActivities,
  SearchActivitiesResponse,
  SearchActivityHistoryResponse,
} from "../../../../service/query";
import { ResultData, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { flattenObject } from "../../../../utils";
import { getDashboardRoute } from "../../../../utils/routes";
import { DataGridColumnDefinition, dataGridMapFilterCriteria } from "../../../../widget";
import { IconCellFormatterData } from "../../../../widget/data/DataGrid/models";
import { CreateActivityVariantModalData } from "../../../shared/components/CreateActivityVariantModal/useCreateActivityVariantModal";
import { resumeActivity } from "../../../shared/utils";
import { ReportPanelActivityDetails } from "./models/ReportPanelActivityDetails";

interface UseValuationsReturnData {
  deleteActivityUuid: string | undefined;
  createActivityVariantModalData: CreateActivityVariantModalData | undefined;
  showDeleteActivityModal: boolean;
  setShowDeleteActivityModal: Dispatch<SetStateAction<boolean>>;
  showCreateActivityVariantModal: boolean;
  setShowCreateActivityVariantModal: Dispatch<SetStateAction<boolean>>;
  columns: DataGridColumnDefinition[];
  isLoading: boolean;
  refresh: boolean;
  showReportPanel: boolean;
  setShowReportPanel: Dispatch<SetStateAction<boolean>>;
  showVersionConflictModal: boolean;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  reportPanelActivityDetails?: ReportPanelActivityDetails;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  onDelete: () => void;
  handleBackClick: () => void;
}

const columns: DataGridColumnDefinition[] = [
  {
    name: "Name",
    key: "variant",
    dataType: "string",
    alignment: "center",
    formatter: "align",
    filterable: true,
    sortable: true,
  },
  {
    name: "Created at",
    key: "createdAt",
    dataType: "Date",
    formatter: "dateOnly",
    alignment: "center",
    filterable: false,
    sortable: true,
  },
  {
    name: "Created by",
    key: "createdByUser.fullName",
    dataType: "string",
    alignment: "center",
    formatter: "align",
    filterable: true,
    sortable: true,
  },
  {
    name: "Progress",
    key: "currentVersion.completionPercentage",
    dataType: "number",
    formatter: "progress",
    minWidth: 200,
  },
  {
    name: "Status",
    key: "status",
    dataType: "string",
    formatter: "projectActivitiesStatusPill",
    minWidth: 180,
  },
  {
    key: "viewEditLink",
    name: "View / edit",
    dataType: "string",
    formatter: "icon",
    alignment: "center",
    filterable: false,
    sortable: false,
  },
  {
    key: "reportButton",
    name: "Report",
    dataType: "string",
    formatter: "icon",
    alignment: "center",
    filterable: false,
    sortable: false,
  },
  {
    key: "deleteButton",
    name: "Delete",
    dataType: "string",
    formatter: "icon",
    alignment: "center",
    filterable: false,
    sortable: false,
  },
];

export const useValuations = (): UseValuationsReturnData => {
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();

  const [showDeleteActivityModal, setShowDeleteActivityModal] = useState(false);
  const [deleteActivityUuid, setDeleteActivityUuid] = useState<string>();

  const [showCreateActivityVariantModal, setShowCreateActivityVariantModal] = useState(false);
  const [createActivityVariantModalData, setCreateActivityVariantModalData] =
    useState<CreateActivityVariantModalData>();

  const [showReportPanel, setShowReportPanel] = useState(false);
  const [reportPanelActivityDetails, setReportPanelActivityDetails] = useState<ReportPanelActivityDetails>();

  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState<boolean>(false);

  const navigate = useNavigate();
  const { currentOrganisationUuid, hasPermission } = useAuth();
  const [searchParams] = useSearchParams();
  const activityUuidURL = searchParams.get("activityUuid");

  const onDelete = (): void => {
    setRefresh(!refresh);
    setShowDeleteActivityModal(false);
  };

  const handleBackClick = (): void => {
    navigate(getDashboardRoute(OrganisationTypeConstants.ASSET_MANAGER));
  };

  const formatData = useCallback(
    (responseData: SearchActivitiesResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        if (hasPermission(OrganisationPermissionConstants.WRITE_ORGANISATION_ACTIVITY)) {
          result.viewEditLink = {
            action: () => {
              resumeActivity(
                navigate,
                setDraftActivityHistoryUuid,
                setNewVersionActivityHistory,
                setShowVersionConflictModal,
                el.uuid,
                el.draftVersion?.uuid,
                el.draftVersion?.versionNumber,
                OrganisationTypeConstants.ASSET_MANAGER
              );
            },
            icon: <EditFileBlueIcon />,
          } as IconCellFormatterData;
        }

        if (el.currentVersion?.completionPercentage === 1)
          result.reportButton = {
            action: () => {
              setReportPanelActivityDetails({
                activityUuid: el.uuid,
                currentActivityHistoryUuid: el.currentVersion?.uuid,
                draftActivityHistoryUuid: el.draftVersion?.uuid,
                draftActivityHistoryRowVersion: el.draftVersion?.rowVersion,
                setDraftActivityHistoryUuid,
                setNewVersionActivityHistory,
                setShowVersionConflictModal,
              });

              setShowReportPanel(true);
            },
            icon: <EyeBlueIcon className="ReportIcon" />,
          } as IconCellFormatterData;

        result.deleteButton = {
          action: () => {
            setShowDeleteActivityModal(true);
            setDeleteActivityUuid(el.uuid);
          },
          icon: <TrashDark className="DeleteIcon" />,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    setIsLoading(true);
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.organisation = {
      uuid: {
        operator: "neq",
        value: null,
      },
    };

    await searchActivities({
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: paging.pageSize || 10,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setIsLoading(false);
      });

    return data;
  };

  const getAndSetReportActivityDetailsFromUrl = useCallback(
    async (uuid: string) => {
      const activityDetailsResponse = await getActivityDetails({ activityUuid: uuid });

      if (activityDetailsResponse.data && activityDetailsResponse.status === Status.Success) {
        setReportPanelActivityDetails({
          activityUuid: uuid,
          currentActivityHistoryUuid: activityDetailsResponse.data.currentVersion?.uuid,
          draftActivityHistoryUuid: activityDetailsResponse.data.draftVersion?.uuid,
          draftActivityHistoryRowVersion: activityDetailsResponse.data.draftVersion?.rowVersion,
          setDraftActivityHistoryUuid,
          setNewVersionActivityHistory,
          setShowVersionConflictModal,
        });

        setShowReportPanel(true);
      }
    },
    [activityUuidURL]
  );

  useEffect(() => {
    if (currentOrganisationUuid)
      setCreateActivityVariantModalData({
        ownerType: ActivityOwnerType.ORGANISATION,
        ownerUuid: currentOrganisationUuid,
        activityDefinitionVersionUuid: "44a283ae-5e9e-4ac4-b8ff-5338c56af1a3",
      });
  }, [currentOrganisationUuid]);

  useEffect(() => {
    if (activityUuidURL) {
      getAndSetReportActivityDetailsFromUrl(activityUuidURL);
      setShowReportPanel(true);
    }
  }, [activityUuidURL]);

  return {
    deleteActivityUuid,
    createActivityVariantModalData,
    showDeleteActivityModal,
    setShowDeleteActivityModal,
    showCreateActivityVariantModal,
    setShowCreateActivityVariantModal,
    handleBackClick,
    isLoading,
    columns,
    refresh,
    setShowVersionConflictModal,
    showVersionConflictModal,
    setShowReportPanel,
    showReportPanel,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    reportPanelActivityDetails,
    onDelete,
    onChange,
  };
};
