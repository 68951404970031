import { DeveloperTemplate } from "../../../templates";
import { getActivitiesRoute, getProjectsRoute } from "../../../utils/routes";
import {
  Column,
  KanaTooltip,
  ProfileMenu,
  ProfileMenuItem,
  QuickActionsMenu,
  Row,
  TotalMarketValueTotalReturnsKPI,
  UserHeader,
} from "../../../widget";
import { DashboardPieCard, DashboardTableCard } from "./components";
import { useDashboard } from "./useDashboard";

const Dashboard = (): JSX.Element => {
  const {
    isLoading,
    statusChartData,
    unitsChartData,

    onRecentActivitiesDataLoad,
    tableColumnsRecentActivities,
    isLoadingActivities,

    onTopProjectsDataLoad,
    tableColumnsTopProjects,
    isLoadingProjects,
    currentUserType,

    quickActionsMenuOptionsList,
    showQuickActions,
    setShowQuickActions,
  } = useDashboard();

  return (
    <DeveloperTemplate activeElement="Dashboard">
      <div>
        <Row spacingV="ml">
          <Column span={12}>
            <UserHeader />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={6}>
            <div className="HeaderContainer">
              <h1>Dashboard</h1>
            </div>
          </Column>
          <Column span={6}>
            <Row justify="end">
              <div>
                <QuickActionsMenu onClick={() => setShowQuickActions(true)} />
                <ProfileMenu show={showQuickActions} onClose={() => setShowQuickActions(false)} position={{ top: "0" }}>
                  {quickActionsMenuOptionsList.map((el) => (
                    <ProfileMenuItem
                      key={el.id}
                      profileMenuItem={el}
                      onClick={() => {
                        if (el.action) {
                          el.action();
                        }
                        setShowQuickActions(false);
                      }}
                    />
                  ))}
                </ProfileMenu>
              </div>
            </Row>
          </Column>
        </Row>
        {!isLoading && (
          <Row spacingV="ml">
            <Column span={4}>
              <DashboardPieCard title="Status" data={statusChartData} />
            </Column>
            <Column span={5}>
              <DashboardPieCard
                title="Units"
                data={unitsChartData}
                noDataMessage="No units issued"
                tooltip={
                  <KanaTooltip
                    tooltipHeader="Buffer units"
                    tooltipText="Please note that buffer units are not shown in this chart"
                  />
                }
              />
            </Column>
            <Column span={3}>
              <TotalMarketValueTotalReturnsKPI
                totalMarketValue={{ value: 171472984, percentageChange: 12.03, lastUpdated: new Date() }}
                totalReturns={{ value: 20576758, percentageChange: 20.4, lastUpdated: new Date() }}
              />
            </Column>
          </Row>
        )}
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Top projects"
              expandRoute={getProjectsRoute(currentUserType)}
              onChange={onTopProjectsDataLoad}
              columns={tableColumnsTopProjects}
              isLoading={isLoadingProjects}
            />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              title="Recent activities"
              expandRoute={getActivitiesRoute(currentUserType)}
              onChange={onRecentActivitiesDataLoad}
              columns={tableColumnsRecentActivities}
              isLoading={isLoadingActivities}
            />
          </Column>
        </Row>
      </div>
    </DeveloperTemplate>
  );
};

export default Dashboard;
