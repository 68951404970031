import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../../../constants";
import { DataGridColumnCurrency } from "../../../../../../models";
import { getActivityHistoryDetails, GetActivityHistoryDetailsResponse } from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { getDefault, GetDefaultResponse } from "../../../../../../service/valuation";
import { useIsLoadingWrapper } from "../../../../../../utils";
import { DataGridColumnDefinition, Toast } from "../../../../../../widget";
import { resumeActivity } from "../../../../../shared/utils";
import { ReportPanelActivityDetails, ReportPanelChartData } from "../../models";

interface UseReportPanelProps {
  activityDetails?: ReportPanelActivityDetails;
  onClose: () => void;
}

interface UseReportPanelReturnData {
  isLoading: boolean;
  variant?: string;
  reportData?: GetDefaultResponse;
  projectsColumns: DataGridColumnDefinition[];
  futurePricesChartData?: ReportPanelChartData;
  monteCarloChartData?: ReportPanelChartData;
  editValuation: () => void;
}

export const useReportPanel = ({ activityDetails, onClose }: UseReportPanelProps): UseReportPanelReturnData => {
  const [isLoading, setIsLoading] = useState(false);

  const [activityHistoryDetailsData, setActivityHistoryDetailsData] = useState<GetActivityHistoryDetailsResponse>();
  const [reportData, setReportData] = useState<GetDefaultResponse>();
  const [futurePricesChartData, setFuturePricesChartData] = useState<ReportPanelChartData>();
  const [monteCarloChartData, setMonteCarloChartData] = useState<ReportPanelChartData>();

  const [currency, setCurrency] = useState<DataGridColumnCurrency>();

  const navigate = useNavigate();

  const projectsColumns: DataGridColumnDefinition[] = [
    { key: "name", name: "Name", dataType: "string" },
    { key: "original_price", name: "Original price", dataType: "number", formatter: "currency", currency },
    { key: "expected_price", name: "Expected price", dataType: "number", formatter: "currency", currency },
    { key: "current_price", name: "Current price", dataType: "number", formatter: "currency", currency },
    { key: "current_value", name: "Current value", dataType: "number", formatter: "currency", currency },
  ];

  const editValuation = (): void => {
    if (activityDetails && activityHistoryDetailsData) {
      resumeActivity(
        navigate,
        activityDetails.setDraftActivityHistoryUuid,
        activityDetails.setNewVersionActivityHistory,
        activityDetails.setShowVersionConflictModal,
        activityHistoryDetailsData.activity.uuid,
        activityDetails.draftActivityHistoryUuid,
        activityHistoryDetailsData.rowVersion,
        OrganisationTypeConstants.ASSET_MANAGER
      );
      onClose();
    }
  };

  const getAndSetReportPanelData = useIsLoadingWrapper(
    useCallback(
      async (uuid: string) => {
        const activityHistoryDetailsResponse = await getActivityHistoryDetails({ activityHistoryUuid: uuid });

        if (activityHistoryDetailsResponse.data && activityHistoryDetailsResponse.status === Status.Success) {
          setActivityHistoryDetailsData(activityHistoryDetailsResponse.data);

          const getDefaultRequestJson = activityHistoryDetailsResponse.data.data;

          await getDefault(getDefaultRequestJson).then((response) => {
            if (response.status === Status.Success && response.data) {
              // Set general details
              setReportData(response.data);
              // Set currency for projects table
              switch (response.data.fund.fundCcy?.toUpperCase()) {
                case DataGridColumnCurrency.Eur:
                  setCurrency(DataGridColumnCurrency.Eur);
                  break;
                case DataGridColumnCurrency.Usd:
                  setCurrency(DataGridColumnCurrency.Usd);
                  break;
                case DataGridColumnCurrency.Gbp:
                default:
                  setCurrency(DataGridColumnCurrency.Gbp);
              }

              // Set future prices chart data
              const futurePricesProjectKeys = Object.keys(response.data.future_prices);
              const futurePricesLabels = Object.keys(response.data.future_prices[futurePricesProjectKeys[0]]);
              const futurePricesDatasets = futurePricesProjectKeys.map((k) => ({
                label: k,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                data: Object.values(response.data!.future_prices[k]).filter((x) => x != null) as number[],
              }));
              setFuturePricesChartData({ labels: futurePricesLabels, datasets: futurePricesDatasets });

              // Set monte carlo chart data
              const monteCarloPercentileKeys = ["25th", "50th", "75th"];
              const monteCarloLabels = response.data.monte_carlo.map((x) => (x.year ? x.year.toString() : "0"));
              const monteCarloDatasets = monteCarloPercentileKeys.map((k) => ({
                label: `${k.replace("th", "\u1D57\u02B0")} percentile`,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                data: response.data!.monte_carlo.map((x) => {
                  switch (k) {
                    case "25th":
                      return x["25th"];
                    case "50th":
                      return x["50th"];
                    case "75th":
                      return x["75th"];
                    default:
                      return 0;
                  }
                }) as number[],
              }));

              setMonteCarloChartData({ labels: monteCarloLabels, datasets: monteCarloDatasets });
            } else {
              Toast.error({
                message: "Report failed to generate",
              });
              setReportData(undefined);
            }
          });
        }
      },
      [activityDetails?.currentActivityHistoryUuid]
    ),
    setIsLoading
  );

  useEffect(() => {
    if (activityDetails?.currentActivityHistoryUuid) {
      getAndSetReportPanelData(activityDetails?.currentActivityHistoryUuid);
    }
  }, [activityDetails?.currentActivityHistoryUuid]);

  return {
    isLoading,
    variant: activityHistoryDetailsData?.activity.variant ?? undefined,
    reportData,
    projectsColumns,
    futurePricesChartData,
    monteCarloChartData,
    editValuation,
  };
};
