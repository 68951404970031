import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import { createActivity } from "../../../../service/activity";
import { ServiceError, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { getActivityEditRoute, getActivityLiteEditRoute } from "../../../../utils/routes";

interface useCreateActivityVariantModalReturnData {
  variant: string;
  setVariant: Dispatch<SetStateAction<string>>;
  errors: ServiceError[] | undefined;
  handleClose: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  isHandleSubmitLoading: boolean;
}

export interface CreateActivityVariantModalData {
  ownerType: string;
  ownerUuid: string;
  activityDefinitionVersionUuid: string;
}

interface useCreateActivityVariantModalProps {
  closeModal: () => void;
  modalData: CreateActivityVariantModalData;
}

export const useCreateActivityVariantModal = ({
  modalData,
  closeModal,
}: useCreateActivityVariantModalProps): useCreateActivityVariantModalReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  const [variant, setVariant] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const handleClose = (): void => {
    setErrors([]);
    setVariant("");
    closeModal();
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (!modalData.activityDefinitionVersionUuid) throw new Error("ActivityDefinitionVersion uuid must not be empty!");

    const res = await createActivity({
      ownerType: modalData.ownerType,
      ownerUuid: modalData.ownerUuid,
      activityDefinitionVersionUuid: modalData.activityDefinitionVersionUuid,
      variant,
    });

    if (res.status === Status.Success && res.data) {
      handleClose();
      if (currentUserType === OrganisationTypeConstants.DEVELOPER)
        navigate(getActivityEditRoute(res.data.activityHistoryUuid));
      else if (currentUserType === OrganisationTypeConstants.ASSET_MANAGER)
        navigate(getActivityLiteEditRoute(res.data.activityHistoryUuid));
    } else {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    variant,
    setVariant,
    errors,
    handleSubmit,
    handleClose,
    isHandleSubmitLoading,
  };
};
