import "./DeleteActivityModal.css";

import { Button } from "../../../../forms";
import { Modal } from "../../../../general";
import { Column, Row } from "../../../../layout";
import { useDeleteActivityModal } from "./useDeleteActivityModal";

interface DeleteActivityModalProps {
  show: boolean;
  deleteActivityUuid?: string; // optional for valuations list
  onDelete?: () => void; // optional for valuations list
  onClose: () => void;
}

export const DeleteActivityModal = ({
  show,
  deleteActivityUuid,
  onDelete,
  onClose,
}: DeleteActivityModalProps): JSX.Element => {
  const { onDeleteClick, isOnDeleteClickLoading } = useDeleteActivityModal({ deleteActivityUuid, onDelete });

  return (
    <Modal show={show} title="Delete Activity" onClose={onClose}>
      <Row spacingV="ml" justify="center">
        <Column span={8}>
          <span className="body1">
            Are you sure you want to delete this activity? All versions and associated data and documents will be lost
          </span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="DeleteActivityModalButtons">
            <Button text="Delete" onClick={onDeleteClick} isLoading={isOnDeleteClickLoading} />
            <Button variant="secondary" text="Cancel" onClick={onClose} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
