import { Dispatch, SetStateAction, useContext, useState } from "react";

import { OrganisationTypeConstants } from "../../../../../constants";
import { CursorChangeProps } from "../../../../../models";
import { ResultData } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { DataGridColumnDefinition, ProfileMenuItemType } from "../../../../../widget";
import { useActivityDashboardContextSwitcher } from "../../../utils/activities/useActivityDashboardContextSwitcher";
import { ActivityDiscussionContext } from "../ActivityDiscussionContext";

type DiscussionTabData = {
  columns: DataGridColumnDefinition[];
  showNewDiscussionModal: boolean;
  setShowNewDiscussionModal: Dispatch<SetStateAction<boolean>>;
  internalDiscussion?: boolean;
  setInternalDiscussion: Dispatch<SetStateAction<boolean | undefined>>;
  showMessageDropdown: boolean;
  messageDropdownOptions: ProfileMenuItemType[];
  setShowMessageDropdown: Dispatch<SetStateAction<boolean>>;
  discussionTableVisible: boolean;
  handleShowTableHideDetails: () => void;
  confirmDelete?: () => void;
  isConfirmDeleteLoading: boolean;
  cancelDelete?: () => void;
  selectTableRowAndHideDetails: (data: ResultData) => void;
  resolveViewOptions: { key: string; value: string }[];
  selectedResolveViewOption: string;
  changeSelectedResolveViewOption: (value: string) => void;
  showDeleteDiscussionModal: boolean;
  onRequestFetchDiscussions: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  isExternalActivity: () => boolean;
  canWriteExternal: boolean;
  canWriteDiscussion: boolean;
};

const resolveViewOptions = [
  { key: "Unresolved", value: "Unresolved" },
  { key: "Resolved", value: "Resolved" },
  { key: "All", value: "All" },
];

export const useDiscussionTab = (): DiscussionTabData => {
  const { currentUserType } = useAuth();

  const {
    columns,
    showTableHideDetails,
    setSelectedThreadDetails,
    discussionTableVisible,
    setSelectedResolveViewOption,
    selectTableRowAndHideDetails,
    refreshDiscussionTables,
    onRequestFetchDiscussions,
    selectedResolveViewOption,
    showDeleteDiscussionModal,
    confirmDeleteDiscussionCB,
    isConfirmDeleteDiscussionLoading,
    hideDeleteDiscussionModal,
  } = useContext(ActivityDiscussionContext);

  const {
    activityDetails,
    hasManageProjectActivityPermission,
    hasManageProjectActivityReviewPermission,
    hasWriteProjectActivityReviewPermission,
    isExternalActivity,
  } = useActivityDashboardContextSwitcher();

  const [showNewDiscussionModal, setShowNewDiscussionModal] = useState<boolean>(false);
  const [showMessageDropdown, setShowMessageDropdown] = useState<boolean>(false);
  const [internalDiscussion, setInternalDiscussion] = useState<boolean>();

  const externalUserType =
    currentUserType === OrganisationTypeConstants.DEVELOPER
      ? OrganisationTypeConstants.VERIFIER
      : OrganisationTypeConstants.DEVELOPER;

  const messageDropdownOptions: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "Internal Team",
      action: () => {
        setInternalDiscussion(true);
        setShowMessageDropdown(false);
        setShowNewDiscussionModal(true);
      },
    },
    {
      id: 2,
      value: externalUserType,
      action: () => {
        setInternalDiscussion(false);
        setShowMessageDropdown(false);
        setShowNewDiscussionModal(true);
      },
    },
  ];

  const changeSelectedResolveViewOption = (value: string): void => {
    setSelectedResolveViewOption(value);
    refreshDiscussionTables();
    showTableHideDetails();
  };

  const handleShowTableHideDetails = (): void => {
    setSelectedThreadDetails(undefined); // Needed for the useEffects in useDiscussionThreadWithReply and useDiscussionMessage, so that reply and edit text areas are closed
    showTableHideDetails();
  };

  return {
    columns,
    showNewDiscussionModal,
    setShowNewDiscussionModal,
    internalDiscussion,
    setInternalDiscussion,
    showMessageDropdown,
    setShowMessageDropdown,
    messageDropdownOptions,
    discussionTableVisible,
    handleShowTableHideDetails,
    selectTableRowAndHideDetails,
    resolveViewOptions,
    selectedResolveViewOption,
    changeSelectedResolveViewOption,
    onRequestFetchDiscussions,
    showDeleteDiscussionModal,
    confirmDelete: confirmDeleteDiscussionCB,
    isConfirmDeleteLoading: isConfirmDeleteDiscussionLoading,
    cancelDelete: hideDeleteDiscussionModal,
    isExternalActivity,
    canWriteDiscussion:
      hasManageProjectActivityPermission ||
      hasManageProjectActivityReviewPermission ||
      hasWriteProjectActivityReviewPermission ||
      false,
    canWriteExternal:
      (!isExternalActivity() &&
        (activityDetails === undefined ||
          (activityDetails?.project?.verifier?.supportsDigitalVerification &&
            !activityDetails?.activityDefinitionVersion.isManuallyApproved))) ||
      false,
  };
};
