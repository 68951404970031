/* Note this page is also imported from outside /developer - this was done rather than
 * moving the file to shared, as we wanted to keep the git history of the file given the
 * complexity of some of bugs that have occured surrounding the wizard in the past.
 */
import "../../css/ActivityWizard.css";
import "./View.css";

import { ArrowRoundLeftDarkIcon, ArrowRoundRightLightIcon } from "../../../../../../assets";
import { VersionConflictModal } from "../../../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { checkCanManageActivity } from "../../../../../../utils";
import { Button, Column, Row, Stepper, VersionBanner, Wizard } from "../../../../../../widget";
import { ActivityWizardContent, ReviewContent } from "../../components";
import { useView } from "./useView";

export const View = (): JSX.Element => {
  const {
    steps,
    l3Steps,
    stepFields,
    stepReviews,
    currentStepName,
    currentStepKeys,
    hasNext,
    hasPrevious,
    activityData,
    isLoading,
    showReviewPage,
    isReview,
    activityDefinition,
    activityDefinitionInfo,
    activityCompletionPercentage,
    activityStatus,
    activityHistoryUuidData,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    hasManageProjectActivityPermission,
    currentUserType,
    // handlers
    movePrevious,
    moveNext,
    moveTo,
    moveToLastStep,
    moveToReview,
    onActivityHistoryUuidChange,
    onClose,
    onEdit,
    setShowVersionConflictModal,
  } = useView();
  return isLoading ? (
    <div />
  ) : (
    <div className={`${currentUserType} ActivityWizardContainer ActivityWizardContainer_View`}>
      <VersionBanner
        projectName={activityData?.activity?.project?.displayName || ""}
        activityDefinitionName={activityData?.activity.activityDefinition.displayName || ""}
        updatedByUserFullName={activityData?.createdByUser.fullName || ""}
        updatedAt={activityData?.createdAt || undefined}
        activityHistoryVersionNumber={activityData?.versionNumber.toString() || ""}
        onActivityHistoryUuidChange={onActivityHistoryUuidChange}
        activityHistoryUuidData={activityHistoryUuidData}
        isCurrentVersion={activityData?.isCurrent || false}
        isDraftVersion={activityData?.isDraft || false}
        isUnderReview={activityData?.isUnderReview ?? true}
        status={activityStatus}
        canManageActivity={checkCanManageActivity(hasManageProjectActivityPermission, activityData?.activity.status)}
        currentUserType={currentUserType}
        onClose={onClose}
        onEdit={onEdit}
      />
      <div className="ActivityWizardContainerMain">
        <div className="ActivityWizardContainerWizard">
          <Wizard
            title={activityData?.activity?.project?.displayName || ""}
            activityName={activityDefinitionInfo?.displayName || ""}
            activityVersion={activityDefinitionInfo?.versionNumber || ""}
            currentUserType={currentUserType}
            steps={steps}
            isReview={isReview}
            showLogo={false}
            completionPercentage={activityCompletionPercentage}
            handleMoveToStep={moveTo}
            handleMoveToReview={showReviewPage ? moveToReview : undefined}
          />
        </div>
        <div className="ActivityWizardContainerContent">
          {showReviewPage && isReview ? (
            <ReviewContent activityDefinition={activityDefinition} handleMoveToStep={moveTo} />
          ) : (
            <>
              {currentStepName && (
                <Row
                  spacingV={l3Steps && l3Steps.length > 0 ? "ml" : "ll"}
                  className="ActivityWizardContainerContentStepName"
                >
                  <Column span={12}>
                    <h1>{currentStepName}</h1>
                  </Column>
                </Row>
              )}
              {l3Steps && l3Steps.length > 0 && (
                <Row spacingV="ll" className="ActivityWizardContainerStepper">
                  <Column span={12}>
                    <Stepper
                      steps={l3Steps}
                      currentSubStepKey={currentStepKeys.length === 3 ? currentStepKeys[2] : undefined}
                      onClick={(stepKey) => {
                        const isCurrentStep = currentStepKeys.length === 3 && currentStepKeys[2] === stepKey;
                        const l3StepKeys = [...currentStepKeys.slice(0, -1), stepKey];
                        return !isCurrentStep ? moveTo(l3StepKeys) : undefined;
                      }}
                      currentUserType={currentUserType}
                    />
                  </Column>
                </Row>
              )}
              <Row spacingV="ll">
                <Column span={12}>
                  <ActivityWizardContent fields={stepFields} errors={undefined} />
                  {stepReviews !== undefined && stepReviews.length > 1 && (
                    <div className="ActivityWizardReviewStepContainer">{stepReviews}</div>
                  )}
                </Column>
              </Row>
            </>
          )}
          <Row spacingV="ll">
            <Column span={12}>
              <div className="ActivityWizardContainerContentActions">
                {hasPrevious && (
                  <Button
                    text="Previous"
                    onClick={movePrevious}
                    variant="tertiary"
                    icon={<ArrowRoundLeftDarkIcon />}
                    iconPosition="left"
                  />
                )}
                {isReview && (
                  <Button
                    text="Previous"
                    onClick={moveToLastStep}
                    variant="tertiary"
                    icon={<ArrowRoundLeftDarkIcon />}
                    iconPosition="left"
                  />
                )}
                {hasNext && (
                  <Button text="Next" onClick={moveNext} variant="primary" icon={<ArrowRoundRightLightIcon />} />
                )}
                {!hasNext && showReviewPage && !isReview && (
                  <Button text="Next" onClick={moveToReview} variant="primary" icon={<ArrowRoundRightLightIcon />} />
                )}
              </div>
            </Column>
          </Row>
        </div>
        <VersionConflictModal
          showVersionConflictModal={showVersionConflictModal}
          draftActivityHistoryUuid={draftActivityHistoryUuid}
          closeModal={() => setShowVersionConflictModal(false)}
          newVersionActivityHistory={newVersionActivityHistory}
        />
      </div>
    </div>
  );
};
