// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint @typescript-eslint/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// QueryService
import { DocumentNode, gql } from "@apollo/client";

import { DiscussionVisibilityConstants } from "../../constants";
import { OrganisationType } from "../../models";
import { generateGraphQLHelpers, jsonReviver, SearchResultsGraphQL } from "../../utils";
import { apolloPrivate } from "../apolloPrivate";
import { BaseResponseWithMultiple, BaseResponseWithSingle, FilterGroups, ServiceError, Status } from "../Shared";
import {
  GetActivityAggregateRequest,
  GetActivityAggregateResponse,
  GetActivityDefinitionVersionDetailsRequest,
  GetActivityDefinitionVersionDetailsResponse,
  GetActivityDetailsRequest,
  GetActivityDetailsResponse,
  GetActivityHistoryDetailsRequest,
  GetActivityHistoryDetailsResponse,
  GetActivityReviewAggregateRequest,
  GetActivityReviewAggregateResponse,
  GetActivityReviewDetailsRequest,
  GetActivityReviewDetailsResponse,
  GetAssetManagerDetailsRequest,
  GetAssetManagerDetailsResponse,
  GetAuditDataDetailsRequest,
  GetAuditDataDetailsResponse,
  GetCurrentUserProjectPermissionsRequest,
  GetCurrentUserProjectPermissionsResponse,
  GetCurrentUserResponse,
  GetDeveloperDetailsRequest,
  GetDeveloperDetailsResponse,
  GetDevelopmentManagerDetailsRequest,
  GetDevelopmentManagerDetailsResponse,
  GetDiscussionThreadsRequest,
  GetDiscussionThreadsResponse,
  GetGroupDetailsRequest,
  GetGroupDetailsResponse,
  GetOrganisationDetailsRequest,
  GetOrganisationDetailsResponse,
  GetOrganisationEmbeddingPreferencesRequest,
  GetOrganisationEmbeddingPreferencesResponse,
  GetOrganisationUserDetailsRequest,
  GetOrganisationUserDetailsResponse,
  GetProjectAggregateRequest,
  GetProjectAggregateResponse,
  GetProjectDetailsRequest,
  GetProjectDetailsResponse,
  GetProjectDocumentVersionRequest,
  GetProjectDocumentVersionResponse,
  GetProjectIssuancesRequest,
  GetProjectIssuancesResponse,
  GetProjectOrganisationInvitationDetailsRequest,
  GetProjectOrganisationInvitationDetailsResponse,
  GetStandardDetailsRequest,
  GetStandardDetailsResponse,
  GetStandardSpecificSchemaDetailsRequest,
  GetStandardSpecificSchemaDetailsResponse,
  GetStatsAggregateRequest,
  GetStatsAggregateResponse,
  GetVerifierDetailsRequest,
  GetVerifierDetailsResponse,
  LookUpRequest,
  LookUpResponse,
  SearchActivitiesRequest,
  SearchActivitiesResponse,
  SearchActivityDefinitionDocumentTypesRequest,
  SearchActivityDefinitionDocumentTypesResponse,
  SearchActivityDefinitionsRequest,
  SearchActivityDefinitionsResponse,
  SearchActivityDocumentsRequest,
  SearchActivityDocumentsResponse,
  SearchActivityHistoryRequest,
  SearchActivityHistoryResponse,
  SearchActivityReviewsRequest,
  SearchActivityReviewsResponse,
  SearchAuditDataRequest,
  SearchAuditDataResponse,
  SearchDevelopersRequest,
  SearchDevelopersResponse,
  SearchDiscussionThreadsRequest,
  SearchDiscussionThreadsResponse,
  SearchOrganisationUsersRequest,
  SearchOrganisationUsersResponse,
  SearchProjectDocumentHistoryRequest,
  SearchProjectDocumentHistoryResponse,
  SearchProjectDocumentsRequest,
  SearchProjectDocumentsResponse,
  SearchProjectDocumentTypesRequest,
  SearchProjectDocumentTypesResponse,
  SearchProjectDocumentTypeVariantsRequest,
  SearchProjectDocumentTypeVariantsResponse,
  SearchProjectOrganisationInvitationsRequest,
  SearchProjectOrganisationInvitationsResponse,
  SearchProjectOrganisationsRequest,
  SearchProjectOrganisationsResponse,
  SearchProjectsRequest,
  SearchProjectsResponse,
  SearchStandardsRequest,
  SearchStandardsResponse,
  SearchVerifiersRequest,
  SearchVerifiersResponse,
} from "./Types";

export const getAuditDataDetails = async (
  req: GetAuditDataDetailsRequest
): Promise<BaseResponseWithSingle<GetAuditDataDetailsResponse>> => {
  const variables = { auditDataUuid: req.auditDataUuid };

  const QUERY = `
    query ($auditDataUuid: UUID!) {
      auditDataDetails(
        auditDataUuid: $auditDataUuid
      ) {
        eventDateTime
        metaData
        userFullName
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.auditDataDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchAuditData = async (
  req: SearchAuditDataRequest & FilterGroups<SearchAuditDataRequest["filter"]>
): Promise<BaseResponseWithSingle<SearchAuditDataResponse>> => {
  const graphqlTypes = {
    id: "Int!",
    type: "String!",
    objectType: "String!",
    objectUuid: "[UUID]",
    detail: "String",
    action: "String!",
    userFullName: "String",
    "project.projectUuid": "UUID",
    "project.projectName": "String",
    "projectGroup.projectGroupUuid": "UUID",
    "organisation.organisationUuid": "UUID",
    "appUser.userUuid": "UUID",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  const QUERY = `
    query (${variablesBlock}) {
      searchAuditData(
        ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          node {
            id
            uuid
            action
            detail
            eventDateTime
            objectType
            objectUuid
            type
            userFullName
            project {
                projectName
                projectUuid
            }
            projectGroup {
                projectGroupName
                projectGroupUuid
            }
            organisation {
                organisationName
                organisationUuid
            }
            appUser {
                userFullName
                userUuid
            }
          }
        }
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchAuditData as SearchResultsGraphQL<SearchAuditDataResponse["results"][number]>;
      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchProjectOrganisations = async (
  req: SearchProjectOrganisationsRequest
): Promise<BaseResponseWithSingle<SearchProjectOrganisationsResponse>> => {
  const graphqlTypes = {
    role: "[String]",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.projectUuid = req.projectUuid;
  variables.role = req.filter?.results?.role?.value.split(", ");
  const QUERY = `
    query ($projectUuid: UUID!, ${variablesBlock}) {
      searchProjectOrganisations(
        request: {projectUuid: $projectUuid}
        ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          node {
            organisationUuid
            displayName
            role
          }
        }
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchProjectOrganisations as SearchResultsGraphQL<
        SearchProjectOrganisationsResponse["results"][number]
      >;
      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchProjectDocumentTypes = async (
  req: SearchProjectDocumentTypesRequest
): Promise<BaseResponseWithSingle<SearchProjectDocumentTypesResponse>> => {
  const graphqlTypes = {
    "standard.standardUuid": "[UUID]",
    "standard.displayName": "String",
    name: "String!",
    uuid: "UUID!",
    isGenerated: "Boolean",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  // split string into array and swap "null" for null
  variables.standardStandardUuid = req.filter?.results?.standard?.standardUuid?.value
    ?.split(", ")
    .map((value) => (value === "null" ? null : value));

  const QUERY = `
    query (${variablesBlock}) {
      searchProjectDocumentTypes(
        ${queryBlock}
      ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          edges {
            cursor
            node {
              uuid
              name
              supportsVariants
              supportsMultipleFiles
              supportedMimeTypes
              maxFileSize
              isGenerated
              description
              standard {
                  standardUuid
                  displayName
              }
            }
          }
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchProjectDocumentTypes as SearchResultsGraphQL<
        SearchProjectDocumentTypesResponse["results"][number]
      >;

      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchProjectDocumentTypeVariants = async (
  req: SearchProjectDocumentTypeVariantsRequest
): Promise<BaseResponseWithSingle<SearchProjectDocumentTypeVariantsResponse>> => {
  const graphqlTypes = {
    typeUuid: "UUID",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  const QUERY = `
    query (${variablesBlock}) {
      searchProjectDocumentTypeVariants(
        ${queryBlock}
      ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          edges {
            cursor
            node {
              uuid
              name
              description
              typeUuid
            }
          }
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchProjectDocumentTypeVariants as SearchResultsGraphQL<
        SearchProjectDocumentTypeVariantsResponse["results"][number]
      >;

      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchProjectDocuments = async (
  req: SearchProjectDocumentsRequest
): Promise<BaseResponseWithSingle<SearchProjectDocumentsResponse>> => {
  const graphqlTypes = {
    uuid: "UUID!",
    "updatedByUser.fullName": "String!",
    "updatedByUser.firstName": "String!",
    "updatedByUser.lastName": "String!",
    updatedAt: "DateTime!",
    type: "String!",
    visibility: "String!",
    variant: "String",
    customVariant: "String",
    version: "Int!",
    activityName: "String!",
    "file.filename": "String!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.projectUuid = req.projectUuid;
  variables.groupUuid = req.groupUuid;
  const QUERY = `
    query ($projectUuid: UUID!, $groupUuid: UUID, ${variablesBlock}) {
      searchProjectDocuments(
        projectUuid: $projectUuid
        groupUuid: $groupUuid
        ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
            file {
              filename
              url
              mimeType
              sizeBytes
              uuid
            }
            activityName
            visibility
            version
            versionCount
            versionUuid
            groupId
            updatedAt
            updatedByUser {
                firstName
                lastName
                fullName
                avatar {
                    url
                }
            }
            type
            variant
            customVariant
            uuid
          }
        }
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchProjectDocuments as SearchResultsGraphQL<
        SearchProjectDocumentsResponse["results"][number]
      >;

      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const getProjectDocumentVersion = async (
  req: GetProjectDocumentVersionRequest
): Promise<BaseResponseWithSingle<GetProjectDocumentVersionResponse>> => {
  const variables = { projectDocumentHistoryUuid: req.projectDocumentHistoryUuid };

  const QUERY = `
    query ($projectDocumentHistoryUuid: UUID!) {
      projectDocumentVersion(
        projectDocumentHistoryUuid: $projectDocumentHistoryUuid
      ) {
        uuid
        customVariant
        version
        visibility
        groupId
        documentRowVersion
        rowVersion
        standardApproved
        documentType {
            uuid
            name
            maxFileSize
            supportedMimeTypes
            supportsVariants
        }
        documentVariant {
            uuid
            name
        }
        relatedOrganisations {
            displayName
            uuid
        }
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.projectDocumentVersion[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchProjects = async (
  req: SearchProjectsRequest & FilterGroups<SearchProjectsRequest["filter"]>
): Promise<BaseResponseWithSingle<SearchProjectsResponse>> => {
  const graphqlTypes = {
    "assetManager.displayName": "String!",
    "developer.displayName": "String!",
    "developer.uuid": "UUID!",
    "developmentManager.displayName": "String!",
    "standard.displayName": "String!",
    "standard.uuid": "UUID!",
    "projectType.displayName": "String!",
    "projectType.uuid": "UUID!",
    "validator.displayName": "String!",
    "validator.uuid": "UUID",
    addressCountryCode: "String!",
    displayName: "String!",
    name: "String!",
    status: "String!",
    cachedPiuQuantity: "Decimal!",
    cachedVcuQuantity: "Decimal!",
    "group.groupUuid": "UUID!",
    areaNetHa: "Decimal!",
    duration: "Decimal!",
    locationGridReference: "String!",
    "projectReference.externalReference": "String!",
  };

  // convenient though expensive deep clone.
  // const reqWithoutOrganisationUuids = cloneDeep(req);
  // if (reqWithoutOrganisationUuids.filter?.results?.standard?.uuid)
  //   reqWithoutOrganisationUuids.filter.results.standard = undefined;
  // // if (reqWithoutOrganisationUuids.filter?.results?.developer?.uuid) {
  // //   reqWithoutOrganisationUuids.filter.results.developer = undefined;
  // // }
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  const QUERY = `
    query ( ${variablesBlock}) {
      searchProjects(
        ${queryBlock}
      ) {
        edges {
          node {
            displayName
            name
            status
            uuid
            addressCountryCode
            cachedPiuQuantity
            cachedVcuQuantity
            areaNetHa
            duration
            locationGridReference
            startDate
            projectType {
              displayName
              uuid
            }
            developer {
              displayName
              uuid
            }
            standard {
              displayName
              uuid
            }
            validator {
              displayName
              uuid
            }
            assetManager {
              displayName
            }
            developmentManager {
              displayName
            }
            group {
              groupUuid
            }
            projectReference {
              externalReference
            }
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchProjects as SearchResultsGraphQL<SearchProjectsResponse["results"][number]>;

      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchStandards = async (
  req: SearchStandardsRequest
): Promise<BaseResponseWithSingle<SearchStandardsResponse>> => {
  const graphqlTypes = {
    displayName: "String!",
    "parentOrganisation.displayName": "String!",
    "projectTypes.displayName": "String!",
    canCreateNewProjects: "Boolean!",
  };

  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  const QUERY = gql`
    query (${variablesBlock}) {
      searchStandards(
        ${queryBlock}
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          cursor
          node {
            uuid
            displayName
            canCreateNewProjects
            parentOrganisation {
              displayName
              uuid
            }
            projectTypes{
              uuid
              displayName
            }
          }
        }
      }
    }
  `;
  return apolloPrivate
    .query<any>({
      query: QUERY,
      variables,
    })
    .then((response) => {
      const data = response.data.searchStandards as SearchResultsGraphQL<SearchStandardsResponse["results"][number]>;

      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};
interface GetProjectDetailsResponseGraphQL {
  projectDetails: GetProjectDetailsResponse[];
}

export const getProjectDetails = async (
  req: GetProjectDetailsRequest
): Promise<BaseResponseWithSingle<GetProjectDetailsResponse>> => {
  const QUERY = gql`
    query ($projectUuid: UUID, $projectExternalReference: String, $projectType: String) {
      projectDetails(
        request: {
          projectUuid: $projectUuid
          projectExternalReference: $projectExternalReference
          projectType: $projectType
        }
      ) {
        uuid
        name
        displayName
        addressCity
        addressCountryCode
        addressRegion
        addressLine1
        dataScore
        areaNetHa
        status
        cachedVcuQuantity
        cachedPiuQuantity
        locationCoordinates
        locationGridReference
        rowVersion
        currentUserPermissions
        developer {
          displayName
          uuid
          files {
            file {
              url
            }
            type
          }
          listing {
            content
          }
        }
        standard {
          displayName
          uuid
          files {
            file {
              url
            }
          }
        }
        standardSpecificData
        validator {
          displayName
          uuid
          files {
            file {
              url
            }
          }
        }
        group {
          groupName
          groupUuid
        }
        listing {
          availableForSale
          contactAlternativeEmail
          contactOptOut
          content
          hidden
          rowVersion
          showPriceInDirectory
          seekingFunding
          pilotProject
        }
        listingFiles {
          uuid
          file {
            filename
            url
            mimeType
          }
        }
        badges {
          uuid
          displayName
          files {
            file {
              url
            }
            type
          }
          type
        }
        projectType {
          displayName
          uuid
        }
        projectReference {
          externalReference
          externalParty
        }
        standardSpecificSchemaUuid
      }
    }
  `;
  return apolloPrivate
    .query<GetProjectDetailsResponseGraphQL>({
      query: QUERY,
      variables: {
        projectUuid: req.projectUuid,
        projectExternalReference: req.projectExternalReference,
        projectType: req.projectType,
      },
    })
    .then((response) => {
      if (
        response.data.projectDetails[0].listing?.content &&
        typeof response.data.projectDetails[0].listing?.content === "string"
      )
        response.data.projectDetails[0].listing.content = JSON.parse(
          response.data.projectDetails[0].listing.content,
          jsonReviver
        );
      if (
        response.data.projectDetails[0].standardSpecificData &&
        typeof response.data.projectDetails[0].standardSpecificData === "string"
      )
        response.data.projectDetails[0].standardSpecificData = JSON.parse(
          response.data.projectDetails[0].standardSpecificData,
          jsonReviver
        );
      if (
        response.data.projectDetails[0].developer?.listing?.content &&
        typeof response.data.projectDetails[0].developer?.listing?.content === "string"
      ) {
        response.data.projectDetails[0].developer.listing.content = JSON.parse(
          response.data.projectDetails[0].developer?.listing?.content,
          jsonReviver
        );
      }

      // TODO KAN-4532 remove this after BE FIX
      if (response.data.projectDetails[0].addressRegion) {
        response.data.projectDetails[0].addressRegion = response.data.projectDetails[0].addressRegion.replaceAll(
          "�",
          "ô"
        );
      }
      return {
        status: Status.Success,
        data: response.data.projectDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetProjectIssuancesResponseGraphQL {
  projectIssuances: GetProjectIssuancesResponse[];
}

export const getProjectIssuances = async (
  req: GetProjectIssuancesRequest
): Promise<BaseResponseWithMultiple<GetProjectIssuancesResponse>> => {
  const QUERY = gql`
    query ($projectUuid: UUID!) {
      projectIssuances(projectUuid: $projectUuid) {
        uuid
        price
        quantity
        quantityType
        vintageStartDate
        vintageEndDate
        rowVersion
        verifier {
          displayName
        }
      }
    }
  `;
  return apolloPrivate
    .query<GetProjectIssuancesResponseGraphQL>({
      query: QUERY,
      variables: { projectUuid: req.projectUuid },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.projectIssuances,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchOrganisationUsers = async (
  req: SearchOrganisationUsersRequest
): Promise<BaseResponseWithSingle<SearchOrganisationUsersResponse>> => {
  const graphqlTypes = {
    role: "String!",
    active: "Boolean!",
    "user.email": "String!",
    "user.firstName": "String!",
    "user.lastName": "String!",
    "user.fullName": "String!",
    "user.avatar.url": "String!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.organisationUuid = req.organisationUuid;
  const QUERY = `
    query ($organisationUuid: UUID!, ${variablesBlock}) {
      searchOrganisationUsers(
        organisationUuid: $organisationUuid
        ${queryBlock}
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          cursor
          node {
            role
            active
            user {
              uuid
              firstName
              lastName
              fullName
              email
              avatar {
                url
              }
            }
          }
        }
      }
    }
  `;
  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchOrganisationUsers as SearchResultsGraphQL<
        SearchOrganisationUsersResponse["results"][number]
      >;

      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetOrganisationDetailsResponseGraphQL {
  organisationDetails: GetOrganisationDetailsResponse[];
}

export const getOrganisationDetails = async (
  req: GetOrganisationDetailsRequest
): Promise<BaseResponseWithSingle<GetOrganisationDetailsResponse>> => {
  const QUERY = gql`
    query ($organisationUuid: UUID!) {
      organisationDetails(organisationUuid: $organisationUuid) {
        displayName
        rowVersion
        addresses {
          city
          countryCode
          line1
          line2
          region
          postcode
          type
          rowVersion
        }
      }
    }
  `;
  return apolloPrivate
    .query<GetOrganisationDetailsResponseGraphQL>({
      query: QUERY,
      variables: { organisationUuid: req.organisationUuid },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.organisationDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

const getOrganisationDetailsHelper = (type: OrganisationType): DocumentNode => {
  let queryType = "";
  switch (type) {
    case OrganisationType.Developer:
      queryType = `query ($developerUuid: UUID!) {
        developerDetails(developerUuid: $developerUuid) {`;
      break;
    case OrganisationType.Standard:
      queryType = `query ($standardUuid: UUID!) {
        standardDetails(standardUuid: $standardUuid) {`;
      break;
    case OrganisationType.Verifier:
      queryType = `query ($verifierUuid: UUID!) {
        verifierDetails(verifierUuid: $verifierUuid) {`;
      break;
    case OrganisationType.Badge:
      queryType = `query ($badgeUuid: UUID!) {
        badgeDetails(badgeUuid: $badgeUuid) {`;
      break;
    case OrganisationType.DevelopmentManager:
      queryType = `query ($developmentManagerUuid: UUID!) {
        developmentManagerDetails(developmentManagerUuid: $developmentManagerUuid) {`;
      break;
    case OrganisationType.AssetManager:
      queryType = `query ($assetManagerUuid: UUID!) {
        assetManagerDetails(assetManagerUuid: $assetManagerUuid) {`;
      break;
    default:
      break;
  }
  return gql`
    ${queryType}
        uuid
        websiteUrl
        rowVersion
        contactEmail
        contactPhone
        displayName
        socialMediaFacebookUrl
        socialMediaInstagramUsername
        socialMediaLinkedInUrl
        socialMediaTwitterUsername
        socialMediaYoutubeChannel
        files {
          type
          file {
            url
          }
        }
        listing {
          content
          rowVersion
        }
         ${
           type !== OrganisationType.DevelopmentManager && type !== OrganisationType.AssetManager
             ? `
         listingFiles {
          uuid
          file {
            url
          }
        }`
             : ""
         }
        ${
          type === OrganisationType.Standard
            ? `
        canCreateNewProjects
        standardSpecificDataSchema {
          name
          latestVersion
        }`
            : ""
        }
      }
    }
  `;
};

interface GetDeveloperDetailsResponseGraphQL {
  developerDetails: GetDeveloperDetailsResponse[];
}

export const getDeveloperDetails = async (
  req: GetDeveloperDetailsRequest
): Promise<BaseResponseWithSingle<GetDeveloperDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.Developer);

  return apolloPrivate
    .query<GetDeveloperDetailsResponseGraphQL>({
      query: QUERY,
      variables: { developerUuid: req.developerUuid },
    })
    .then((response) => {
      if (response.data.developerDetails[0].listing?.content)
        response.data.developerDetails[0].listing.content = JSON.parse(
          response.data.developerDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.developerDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetStandardDetailsResponseGraphQL {
  standardDetails: GetStandardDetailsResponse[];
}

export const getStandardDetails = async (
  req: GetStandardDetailsRequest
): Promise<BaseResponseWithSingle<GetStandardDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.Standard);

  return apolloPrivate
    .query<GetStandardDetailsResponseGraphQL>({
      query: QUERY,
      variables: { standardUuid: req.standardUuid },
    })
    .then((response) => {
      if (response.data.standardDetails[0].listing?.content)
        response.data.standardDetails[0].listing.content = JSON.parse(
          response.data.standardDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.standardDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetVerifierDetailsResponseGraphQL {
  verifierDetails: GetVerifierDetailsResponse[];
}

export const getVerifierDetails = async (
  req: GetVerifierDetailsRequest
): Promise<BaseResponseWithSingle<GetVerifierDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.Verifier);

  return apolloPrivate
    .query<GetVerifierDetailsResponseGraphQL>({
      query: QUERY,
      variables: { verifierUuid: req.verifierUuid },
    })
    .then((response) => {
      if (response.data.verifierDetails[0].listing?.content)
        response.data.verifierDetails[0].listing.content = JSON.parse(
          response.data.verifierDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.verifierDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const getOrganisationEmbeddingPreferences = async (
  req: GetOrganisationEmbeddingPreferencesRequest
): Promise<BaseResponseWithMultiple<GetOrganisationEmbeddingPreferencesResponse>> => {
  throw new Error(`Not implemented yet server-side ${req.organisationUuid}`);
};

interface GetOrganisationUserDetailsResponseGraphQL {
  organisationUserDetails: GetOrganisationUserDetailsResponse[];
}
export const getOrganisationUserDetails = async (
  req: GetOrganisationUserDetailsRequest
): Promise<BaseResponseWithSingle<GetOrganisationUserDetailsResponse>> => {
  const QUERY = gql`
    query ($userUuid: UUID!) {
      organisationUserDetails(userUuid: $userUuid) {
        role
        active
        rowVersion
        user {
          firstName
          lastName
          fullName
          email
          avatar {
            url
          }
        }
      }
    }
  `;
  return apolloPrivate
    .query<GetOrganisationUserDetailsResponseGraphQL>({
      query: QUERY,
      variables: { userUuid: req.userUuid },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.organisationUserDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetProjectAggregateResponseGraphQL {
  projectAggregate: GetProjectAggregateResponse[];
}

export const getProjectAggregate = async (
  req: GetProjectAggregateRequest
): Promise<BaseResponseWithMultiple<GetProjectAggregateResponse>> => {
  const QUERY = gql`
    query (
      $filterBy: String
      $filterOperator: String
      $filterValue: String
      $groupBy: String
      $aggregate: String
      $aggregation: String
    ) {
      projectAggregate(
        request: {
          filterBy: $filterBy
          filterOperator: $filterOperator
          filterValue: $filterValue
          groupBy: $groupBy
          aggregate: $aggregate
          aggregation: $aggregation
        }
      ) {
        key
        value
      }
    }
  `;
  return apolloPrivate
    .query<GetProjectAggregateResponseGraphQL>({
      query: QUERY,
      variables: {
        filterBy: req.filterBy,
        filterOperator: req.filterOperator,
        filterValue: req.filterValue,
        groupBy: req.groupBy,
        aggregate: req.aggregate,
        aggregation: req.aggregation,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.projectAggregate,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetCurrentUserResponseGraphQL {
  currentUser: GetCurrentUserResponse[];
}

export const getCurrentUser = async (): Promise<BaseResponseWithSingle<GetCurrentUserResponse>> => {
  const QUERY = gql`
    query {
      currentUser {
        userUuid
        firstName
        lastName
        fullName
        email
        rowVersion
        avatar {
          url
          uuid
        }
        organisations {
          role
          assetManagerUuid
          organisationUuid
          developerUuid
          developmentManagerUuid
          standardUuid
          verifierUuid
          permissions
        }
      }
    }
  `;
  return apolloPrivate
    .query<GetCurrentUserResponseGraphQL>({
      query: QUERY,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.currentUser[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface LookUpResponseGraphQL {
  lookUp: LookUpResponse[];
}

export const lookUp = async (req: LookUpRequest): Promise<BaseResponseWithMultiple<LookUpResponse>> => {
  const graphqlTypes = {
    type: "String!",
  };
  const [queryBlock] = generateGraphQLHelpers(req, graphqlTypes);
  const QUERY = gql`
    query ($type: String!) {
      lookUp(
        request: { type: $type }
        ${queryBlock}) {
        key
        valueString
      }
    }
  `;
  return apolloPrivate
    .query<LookUpResponseGraphQL>({
      query: QUERY,
      variables: {
        type: req.type,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.lookUp,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export interface GetStatsAggregateResponseGraphQL {
  statsAggregate: GetStatsAggregateResponse[];
}

export const getStatsAggregate = async (
  req: GetStatsAggregateRequest
): Promise<BaseResponseWithMultiple<GetStatsAggregateResponse>> => {
  const QUERY = gql`
    query ($metric: String, $objectType: String, $objectUuid: UUID, $periodicity: String) {
      statsAggregate(
        request: { metric: $metric, objectType: $objectType, objectUuid: $objectUuid, periodicity: $periodicity }
      ) {
        objectName
        objectUuid
        value
      }
    }
  `;

  return apolloPrivate
    .query<GetStatsAggregateResponseGraphQL>({
      query: QUERY,
      variables: {
        metric: req.metric,
        objectType: req.objectType,
        objectUuid: req.objectUuid,
        periodicity: req.periodicity,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.statsAggregate,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchVerifiers = async (
  req: SearchVerifiersRequest
): Promise<BaseResponseWithSingle<SearchVerifiersResponse>> => {
  const graphqlTypes = {
    displayName: "[String!]",
    uuid: "[UUID!]",
    supportsDigitalVerification: "Boolean!",
    "projectTypes.some.uuid": "UUID!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  const QUERY = gql`
    query (${variablesBlock}) {
      searchVerifiers(${queryBlock}) {
        edges {
          cursor
          node {
            displayName
            uuid
            parentOrganisation {
              uuid
              displayName
            }
            supportsDigitalVerification
                files {
                    file {
                        url
                    }
                    type
                }
            projectTypes {
                uuid
                displayName
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: QUERY,
      variables,
    })
    .then((response) => {
      const data = response.data.searchVerifiers as SearchResultsGraphQL<SearchVerifiersResponse["results"][number]>;

      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

export const SearchProjectOrganisationInvitations = async (
  req: SearchProjectOrganisationInvitationsRequest
): Promise<BaseResponseWithSingle<SearchProjectOrganisationInvitationsResponse>> => {
  const graphqlTypes = {
    sentDate: "Date!",
    targetRole: "String!",
    calculatedStatus: "String!",
    "project.displayName": "String!",
    "project.uuid": "UUID!",
    "sentByUser.fullName": "String!",
    "sentByUser.firstName": "String!",
    "sentByUser.lastName": "String!",
    "sourceOrganisation.displayName": "String!",
    "sourceOrganisation.uuid": "UUID!",
    "targetOrganisation.displayName": "String!",
    "targetOrganisation.uuid": "UUID!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  // TODO add inviationUuid here after BE PR is complete
  // https://github.com/kana-earth/api/pull/274#pullrequestreview-1206224730
  const QUERY = `
    query(${variablesBlock}) {
      searchProjectOrganisationInvitations(
        ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
            targetOrganisation {
              uuid
              displayName
            }
            sourceOrganisation {
              uuid
              displayName
            }
            project {
              uuid
              displayName
            }
            sentByUser {
              firstName
              lastName
              fullName
              uuid
            }
            sentDate
            targetRole
            uuid
            calculatedStatus
          }
        }
      }
    }`;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchProjectOrganisationInvitations as SearchResultsGraphQL<
        SearchProjectOrganisationInvitationsResponse["results"][number]
      >;

      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

interface GetProjectOrganisationInvitationDetailsResponseGraphQL {
  projectOrganisationInvitationDetails: GetProjectOrganisationInvitationDetailsResponse[];
}

export const getProjectOrganisationInvitationDetails = async (
  req: GetProjectOrganisationInvitationDetailsRequest
): Promise<BaseResponseWithSingle<GetProjectOrganisationInvitationDetailsResponse>> => {
  const QUERY = gql`
    query ($projectOrganisationInvitationUuid: UUID!) {
      projectOrganisationInvitationDetails(invitationUuid: $projectOrganisationInvitationUuid) {
        targetOrganisation {
          uuid
          displayName
          contactEmail
          contactPhone
        }
        sourceOrganisation {
          uuid
          displayName
          contactEmail
          contactPhone
        }
        sentDate
        calculatedStatus
        project {
          uuid
          displayName
          status
          standard {
            uuid
            displayName
          }
        }
        targetRole
        acceptedDate
        rejectedDate
        expiresDate
        revoked
        sentByUser {
          firstName
          lastName
          fullName
          uuid
        }
        acceptedByUser {
          firstName
          lastName
          fullName
          uuid
        }
        rejectedByUser {
          firstName
          lastName
          fullName
          uuid
        }
        revokedByUser {
          firstName
          lastName
          fullName
          uuid
        }
        rowVersion
      }
    }
  `;

  return apolloPrivate
    .query<GetProjectOrganisationInvitationDetailsResponseGraphQL>({
      query: QUERY,
      variables: { projectOrganisationInvitationUuid: req.projectOrganisationInvitationUuid },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.projectOrganisationInvitationDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchDevelopers = async (
  req: SearchDevelopersRequest
): Promise<BaseResponseWithSingle<SearchDevelopersResponse>> => {
  const graphqlTypes = {
    excludedProjectUuid: "UUID",
    displayName: "String!",
    uuid: "UUID!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  const QUERY = `
    query($excludedProjectUuid: UUID, ${variablesBlock}) {
      searchDevelopers(
        request: {excludedProjectUuid: $excludedProjectUuid}, ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
            displayName
            uuid
            parentOrganisation {
              uuid
              displayName
            }
          }
        }
      }
    }`;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables: {
        ...variables,
        excludedProjectUuid: req.excludedProjectUuid,
      },
    })
    .then((response) => {
      const data = response.data.searchDevelopers as SearchResultsGraphQL<SearchDevelopersResponse["results"][number]>;

      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

export const searchActivities = async (
  req: SearchActivitiesRequest & FilterGroups<SearchActivitiesRequest["filter"]>
): Promise<BaseResponseWithSingle<SearchActivitiesResponse>> => {
  const graphqlTypes = {
    "project.uuid": "UUID!",
    "group.uuid": "UUID!",
    "organisation.uuid": "UUID",
    "project.displayName": "String!",
    createdAt: "Date!",
    status: "[String!]",
    "_0_.status": "String!",
    "_1_.status": "String!",
    "_2_.status": "String!",
    "_3_.status": "String!",
    "_4_.status": "String!",
    "_5_.status": "String!",
    "_6_.status": "String!",
    "_7_.status": "String!",
    "_8_.status": "String!",
    // eslint-disable-next-line prettier/prettier
    "isUnderReview": "Boolean!",
    variant: "String!",
    uuid: "UUID!",
    "activityDefinition.displayName": "String!",
    "currentVersion.createdByUser.fullName": "String!",
    "currentVersion.createdAt": "Date!",
    "currentVersion.updatedAt": "Date!",
    "currentVersion.updatedByUser.fullName": "String!",
    "currentVersion.versionNumber": "Decimal!",
    "latestVersion.updatedAt": "Date!",
    "latestVersion.updatedByUser.fullName": "String!",
    "latestVersion.versionNumber": "Decimal!",
    "latestVersion.completionPercentage": "Decimal!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  const QUERY = `
    query(${variablesBlock}) {
      searchActivities(
        ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
            firstSubmissionDate
            activityDefinition {
              displayName
              uuid
            }
            completionDate
            createdAt
            createdByUser {
              fullName
              firstName
              lastName
            }
            firstSubmissionDate
            isUnderReview
            latestVersion {
                updatedAt
                updatedByUser {
                  fullName
                  firstName
                  lastName
                }
                versionNumber
                completionPercentage
            }
            reviewVersion {
                rowVersion
                submittedAt
                uuid
                createdAt
                createdByUser {
                    firstName
                    fullName
                    lastName
                }
                updatedAt
                updatedByUser {
                  fullName
                  firstName
                  lastName
                }
                versionNumber
            }
            currentVersion {
              activityDefinitionVersion {
                isManuallyApproved
              }
              completionPercentage
              createdAt
              updatedAt
              createdByUser {
                fullName
                firstName
                lastName
              }
              updatedByUser {
                fullName
                firstName
                lastName
              }
              uuid
              versionNumber
            }
            draftVersion {
              activityDefinitionVersion {
                isManuallyApproved
              }
              completionPercentage
              createdAt
              createdByUser {
                fullName
                firstName
                lastName
              }
              updatedAt
              updatedByUser {
                fullName
                firstName
                lastName
              }
              previousActivityHistoryUuid
              uuid
              versionNumber
              rowVersion
            }
            project {
              displayName
              standard {
                displayName
                uuid
              }
              developer {
                displayName
              }
              uuid
              verifier {
                displayName
                uuid
                supportsDigitalVerification
              }
            }
            group {
              displayName
              externalReference
              uuid
              standard {
                displayName
                uuid
              }
              developer {
                displayName
                uuid
              }
              verifier {
                displayName
                uuid
                supportsDigitalVerification
              }
            }
            organisation {
              displayName
              uuid
            }
            rowVersion
            status
            uuid
            variant
          }
        }
      }
    }`;
  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchActivities as SearchResultsGraphQL<SearchActivitiesResponse["results"][number]>;

      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

export const searchActivityReviews = async (
  req: SearchActivityReviewsRequest
): Promise<BaseResponseWithSingle<SearchActivityReviewsResponse>> => {
  const graphqlTypes = {
    "project.uuid": "UUID",
    "project.displayName": "String",
    "group.uuid": "UUID",
    "developer.displayName": "String!",
    "standard.displayName": "String!",
    "assignedToUser.uuid": "UUID",
    "assignedToUser.fullName": "String",
    "activity.uuid": "UUID!",
    "activity.status": "String!",
    "activity.variant": "String!",
    "activity.activityDefinition.displayName": "String!",
    completionPercentage: "Decimal!",
    status: "String!",
    type: "String",
    calculatedDaysInQueue: "Decimal!",
    calculatedDaysToDeadline: "Decimal!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  const QUERY = `
      query(${variablesBlock}) {
        searchActivityReviews(${queryBlock})
        {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
            edges {
                node {
                    activity {
                        activityDefinition {
                            displayName
                            uuid
                        }
                        currentVersion {
                            activityDefinitionVersion {
                                isManuallyApproved
                            }
                            completionPercentage
                            createdAt
                            createdByUser {
                                firstName
                                fullName
                                lastName
                            }
                            uuid
                            versionNumber
                            isUnderReview
                        }
                        rowVersion
                        status
                        uuid
                        variant
                    }
                    activityReviewUuid
                    assignedToUser {
                        fullName
                        uuid
                    }
                    primaryAssignedToUser {
                        fullName
                        uuid
                    }
                    calculatedDaysInQueue
                    calculatedDaysToDeadline
                    completionPercentage
                    developer {
                        displayName
                        uuid
                    }
                    group {
                        displayName
                        externalReference
                        uuid
                    }
                    project {
                        displayName
                        uuid
                    }
                    rowVersion
                    standard {
                        displayName
                        uuid
                    }
                    status
                    type
                }
            }
        }
    }`;
  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchActivityReviews as SearchResultsGraphQL<
        SearchActivityReviewsResponse["results"][number]
      >;
      const results = data.edges.map((e) => e.node);
      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

interface GetActivityReviewDetailsResponseGraphQL {
  activityReviewDetails: GetActivityReviewDetailsResponse[];
}

export const getActivityReviewDetails = async (
  req: GetActivityReviewDetailsRequest
): Promise<BaseResponseWithSingle<GetActivityReviewDetailsResponse>> => {
  const graphqlTypes = {
    "activityHistoryDetailsResponse.project.uuid": "UUID!",
    "activityHistoryDetailsResponse.project.displayName": "String!",
    "activityHistoryDetailsResponse.createdAt": "Date!",
    "activityHistoryDetailsResponse.status": "String!",
    "activityHistoryDetailsResponse.variant": "String!",
    "activityHistoryDetailsResponse.activityDefinition.displayName": "String!",
    "activityHistoryDetailsResponse.currentVersion.createdByUser.fullName": "String!",
    "activityHistoryDetailsResponse.currentVersion.createdAt": "Date!",
    "activityHistoryDetailsResponse.currentVersion.versionNumber": "String!",
  };

  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.activityReviewUuid = req.activityReviewUuid;
  const QUERY = `
    query($activityReviewUuid: UUID!, ${variablesBlock}) {
      activityReviewDetails(request: { activityReviewUuid: $activityReviewUuid ${queryBlock}})
        {
            activityHistoryDetailsResponse {
                uuid
                versionNumber
                changeSummary
                completionPercentage
                createdAt
                data
                isCurrent
                isDraft
                rowVersion
                previousActivityHistoryUuid
                createdByUser {
                    fullName
                    firstName
                    lastName
                    avatar {
                        url
                    }
                }
                documents {
                    activityDefinitionDocumentType {
                        displayName
                        uuid
                    }
                    activityDocumentHistory {
                            file {
                            filename
                            mimeType
                            }
                        uuid
                        variant
                        versionNumber
                    }
                    uuid
                }
                activity {
                    uuid
                    status
                    rowVersion
                    variant
                    group {
                        displayName
                        externalReference
                        uuid
                    }
                    project {
                        displayName
                        uuid
                    }
                    activityDefinition {
                        displayName
                        uuid
                    }
                    activityDefinitionVersion {
                        uuid
                        versionNumber
                    }
                }
            }
            activityReviewUuid
            activityReviewType
            activityReviewStatus
            completionPercentage
            isUnderReview
            rowVersion
            deadline
            assignedToUserUuids
        }
    }`;

  return apolloPrivate
    .query<GetActivityReviewDetailsResponseGraphQL>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: {
          ...response.data.activityReviewDetails[0],
          activityHistoryDetailsResponse: {
            ...response.data.activityReviewDetails[0].activityHistoryDetailsResponse,
            data: JSON.parse(response.data.activityReviewDetails[0].activityHistoryDetailsResponse.data, jsonReviver),
          },
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const getActivityReviewAggregate = async (
  req: GetActivityReviewAggregateRequest
): Promise<BaseResponseWithSingle<GetActivityReviewAggregateResponse>> => {
  const QUERY = `
      query($activityUuid: UUID, $activityReviewUuid:UUID, $assignedToUserUuid: UUID ){
        activityReviewAggregate(request: {activityUuid: $activityUuid, activityReviewUuid: $activityReviewUuid, assignedToUserUuid: $assignedToUserUuid }) {
            activityReviewUuid
            approvedDocuments
            resolvedDiscussions
            unapprovedDocuments
            unresolvedDiscussionsOrganisation
            unresolvedDiscussionsAuthor
            assessment
            {
                nearDeadLines
                pastDeadLines
                queued
            }
            review
            {
                nearDeadLines
                pastDeadLines
                queued
            }
        }
      }`;
  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables: {
        activityUuid: req.activityUuid ?? null,
        activityReviewUuid: req.activityReviewUuid ?? null,
        assignedToUserUuid: req.assignedToUserUuid ?? null,
      },
    })
    .then((response) => {
      const data = response.data.activityReviewAggregate[0];
      return {
        status: Status.Success,
        data,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchActivityDefinitions = async (
  req: SearchActivityDefinitionsRequest
): Promise<BaseResponseWithSingle<SearchActivityDefinitionsResponse>> => {
  const QUERY = `
    query ($standardUuid: UUID, $applicableProjectStatus: String!, $applicableActivityDefinitionType: String!) {
      searchActivityDefinitions( request: {standardUuid: $standardUuid, applicableProjectStatus: $applicableProjectStatus, applicableActivityDefinitionType: $applicableActivityDefinitionType}) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
            activeVersions {
              uuid
              versionNumber
            }
            applicableProjectStatuses
            applicableActivityDefinitionType
            displayName
            hasVariant
            standard {
              displayName
              uuid
            }
            uuid
          }
        }
      }
    }`;
  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables: {
        standardUuid: req.standardUuid,
        applicableProjectStatus: req.applicableProjectStatus[0],
        applicableActivityDefinitionType: req.applicableActivityDefinitionType,
      },
    })
    .then((response) => {
      const data = response.data.searchActivityDefinitions as SearchResultsGraphQL<
        SearchActivityDefinitionsResponse["results"][number]
      >;

      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

export const searchActivityHistory = async (
  req: SearchActivityHistoryRequest
): Promise<BaseResponseWithSingle<SearchActivityHistoryResponse>> => {
  const graphqlTypes = {
    isCurrent: "Boolean!",
    isDraft: "Boolean!",
    uuid: "UUID!",
    "activity.uuid": "UUID!",
    versionNumber: "Decimal!",
    completionPercentage: "Decimal!",
    "createdByUser.fullName": "String!",
    "activity.project.uuid": "UUID!",
    changeSummary: "String!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  const activityUuidVar = req.activityUuid ? `request: { activityUuid: "${req.activityUuid}"}, ` : "";

  const QUERY = `
    query(${variablesBlock}) {
      searchActivityHistory(
        ${activityUuidVar},
        ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
            uuid
            versionNumber
            changeSummary
            completionPercentage
            createdAt
            updatedAt
            isCurrent
            isDraft
            isUnderReview
            createdByUser {
              fullName
            }
            activity {
              uuid
              project {
                displayName
                uuid
              }
              group {
                uuid
              }
              organisation {
                uuid
              }
              activityDefinition {
                displayName
                uuid
              }
            }
          }
        }
      }
    }`;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchActivityHistory as SearchResultsGraphQL<
        SearchActivityHistoryResponse["results"][number]
      >;
      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

export const searchProjectDocumentHistory = async (
  req: SearchProjectDocumentHistoryRequest
): Promise<BaseResponseWithSingle<SearchProjectDocumentHistoryResponse>> => {
  const graphqlTypes = {
    "activity.displayName": "String!",
    "activity.versionNumber": "Decimal!",
    "activity.isKey": "Boolean!",
    "projectDocument.externalParty": "String",
    "projectDocument.standardApproved": "Boolean!",
    "projectDocument.visibility": "String!",
    "projectDocument.createdByUser.fullName": "String!",
    "projectDocument.file.filename": "String!",
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.projectDocumentUuid = req.projectDocumentUuid;

  const QUERY = `
    query($projectDocumentUuid: UUID!, ${variablesBlock}) {
      searchProjectDocumentHistory(
        projectDocumentUuid: $projectDocumentUuid
        ${queryBlock}
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
           activity {
             displayName
             variant
             isKey
             versionNumber
           }
           projectDocument {
            uuid
            groupId
            standardApproved
            visibility
            version
            type
            variant
            customVariant
            externalParty
            externalReference
            rowVersion
            file {
              url,
              filename
            }
            createdAt
            createdByUser {
              firstName
              lastName
              fullName
            }
           }
          }
        }
      }
    }`;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchProjectDocumentHistory as SearchResultsGraphQL<
        SearchProjectDocumentHistoryResponse["results"][number]
      >;
      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

interface GetActivityAggregateResponseGraphQL {
  activityAggregate: GetActivityAggregateResponse[];
}

export const getActivityAggregate = async (
  req: GetActivityAggregateRequest
): Promise<BaseResponseWithMultiple<GetActivityAggregateResponse>> => {
  const QUERY = gql`
    query (
      $filterBy: String
      $filterOperator: String
      $filterValue: String
      $groupBy: String
      $aggregate: String
      $aggregation: String
    ) {
      activityAggregate(
        request: {
          filterBy: $filterBy
          filterOperator: $filterOperator
          filterValue: $filterValue
          groupBy: $groupBy
          aggregate: $aggregate
          aggregation: $aggregation
        }
      ) {
        key
        value
      }
    }
  `;
  return apolloPrivate
    .query<GetActivityAggregateResponseGraphQL>({
      query: QUERY,
      variables: {
        filterBy: req.filterBy,
        filterOperator: req.filterOperator,
        filterValue: req.filterValue,
        groupBy: req.groupBy,
        aggregate: req.aggregate,
        aggregation: req.aggregation,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.activityAggregate,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetActivityDetailsResponseGraphQL {
  activityDetails: GetActivityDetailsResponse[];
}

export const getActivityDetails = async (
  req: GetActivityDetailsRequest
): Promise<BaseResponseWithSingle<GetActivityDetailsResponse>> => {
  const graphqlTypes = {
    "project.uuid": "UUID!",
    "project.displayName": "String!",
    createdAt: "Date!",
    status: "String!",
    variant: "String!",
    "activityDefinition.displayName": "String!",
    "currentVersion.createdByUser.fullName": "String!",
    "currentVersion.createdAt": "Date!",
    "currentVersion.versionNumber": "String!",
  };

  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.activityUuid = req.activityUuid;
  const QUERY = `
    query($activityUuid: UUID!, ${variablesBlock}) {
      activityDetails(request: { activityUuid: $activityUuid ${queryBlock}})
        {
        uuid
        groupUuid
        organisationUuid
        variant
        status
        createdAt
        isUnderReview
        cachedCompletionPercentage
        rowVersion
        activityDefinition {
          displayName
          uuid
        }
        project {
          displayName
          uuid
          developer {
            uuid
            organisationUuid
          }
          verifier {
            uuid
            organisationUuid
            supportsDigitalVerification
          }
        }
        activityDefinitionVersion {
          uuid
          versionNumber
          isManuallyApproved
        }
        createdByUser {
          firstName
          fullName
          lastName
        }
        currentVersion {
          createdAt
          uuid
          versionNumber
          createdByUser {
              firstName
              fullName
              lastName
            }
        }
        draftVersion {
          createdAt
          uuid
          previousActivityHistoryUuid
          versionNumber
          createdByUser {
              firstName
              fullName
              lastName
          }
          rowVersion
        }
      }
    }`;

  return apolloPrivate
    .query<GetActivityDetailsResponseGraphQL>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.activityDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetActivityDefinitionVersionDetailsResponseGraphQL {
  activityDefinitionVersionDetails: GetActivityDefinitionVersionDetailsResponse[];
}

export const getActivityDefinitionVersionDetails = async (
  req: GetActivityDefinitionVersionDetailsRequest
): Promise<BaseResponseWithSingle<GetActivityDefinitionVersionDetailsResponse>> => {
  const graphqlTypes = {
    activityDefinitionVersionUuid: "UUID!",
    activityHistoryUuid: "UUID!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.activityDefinitionVersionUuid = req.activityDefinitionVersionUuid;
  variables.activityHistoryUuid = req.activityHistoryUuid;
  const QUERY = gql`
    query ($activityDefinitionVersionUuid: UUID!, $activityHistoryUuid: UUID!, ${variablesBlock}) {
      activityDefinitionVersionDetails(
       request: { activityDefinitionVersionUuid: $activityDefinitionVersionUuid, activityHistoryUuid: $activityHistoryUuid ${queryBlock}}
       ) {
        activityDefinition {
          displayName
          hasVariant
          uuid
        }
        definition
        isManuallyApproved
        uuid
        validFrom
        validTo
        versionNumber
      }
    }
  `;
  return apolloPrivate
    .query<GetActivityDefinitionVersionDetailsResponseGraphQL>({
      query: QUERY,
      variables,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: {
          ...response.data.activityDefinitionVersionDetails[0],
          definition: JSON.parse(response.data.activityDefinitionVersionDetails[0].definition, jsonReviver),
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetActivityHistoryResponseGraphQL {
  activityHistoryDetails: GetActivityHistoryDetailsResponse[];
}

export const getActivityHistoryDetails = async (
  req: GetActivityHistoryDetailsRequest
): Promise<BaseResponseWithSingle<GetActivityHistoryDetailsResponse>> => {
  const graphqlTypes = {
    isCurrent: "Boolean!",
    isDraft: "Boolean!",
    uuid: "UUID!",
  };

  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.activityHistoryUuid = req.activityHistoryUuid;
  const QUERY = `
    query($activityHistoryUuid: UUID!, ${variablesBlock}) {
      activityHistoryDetails( activityHistoryUuid: $activityHistoryUuid ${queryBlock}) {
        activity {
          activityDefinition {
            displayName
            uuid
          }
          activityDefinitionVersion {
            uuid
            versionNumber
          }
          project {
            displayName
            uuid
          }
          group {
            displayName
            externalReference
            uuid
          }
          organisation {
            displayName
            uuid
          }
          rowVersion
          status
          uuid
          variant
        }
        changeSummary
        completionPercentage
        createdAt
        createdByUser {
          avatar {
            url
          }
          firstName
          fullName
          lastName
        }
        documents {
          activityDefinitionDocumentType {
            displayName
            uuid
          }
          activityDocumentHistory {
            file {
              filename
              mimeType
            }
            uuid
            variant
            versionNumber
          }
          uuid
        }
        data
        isCurrent
        isDraft
        isUnderReview
        previousActivityHistoryUuid
        rowVersion
        uuid
        versionNumber
      }
    }`;

  return apolloPrivate
    .query<GetActivityHistoryResponseGraphQL>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: {
          ...response.data.activityHistoryDetails[0],
          data: JSON.parse(response.data.activityHistoryDetails[0].data, jsonReviver),
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchActivityDocuments = async (
  req: SearchActivityDocumentsRequest
): Promise<BaseResponseWithSingle<SearchActivityDocumentsResponse>> => {
  const graphqlTypes = {
    "activityDefinitionDocumentType.displayName": "String!",
    "activityDefinitionDocumentType.isGenerated": "Boolean!",
    "activityDefinitionDocumentType.isMaster": "Boolean!",
    "activityDocument.variant": "String!",
    "activityDocument.status": "String!",
    "activityDocument.versionNumber": "Decimal!",
    "activityDocument.createdAt": "Date!",
    "activityDocument.createdByUser.fullName": "String!",
    "activityDefinitionVersionDocumentType.isKey": "Boolean!",
    "activity.project.uuid": "UUID!",
    "file.filename": "String!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.currentOnly = req.currentOnly;
  variables.activityUuid = req.activityUuid;
  variables.activityHistoryUuid = req.activityHistoryUuid;
  variables.activityDocumentHistoryUuid = req.activityDocumentHistoryUuid;

  const QUERY = `
    query($currentOnly: Boolean!, $activityUuid: UUID,
    $activityHistoryUuid: UUID,
    $activityDocumentHistoryUuid: UUID, ${variablesBlock}) {
      searchActivityDocuments(request: { currentOnly: $currentOnly, activityDocumentHistoryUuid: $activityDocumentHistoryUuid, activityHistoryUuid: $activityHistoryUuid, activityUuid: $activityUuid }
         ${queryBlock})
      {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edges {
          cursor
          node {
            activity {
              uuid
            }
            activityDefinitionDocumentType {
              displayName
              uuid
            }
            activityDefinitionVersionDocumentType {
              isKey
              uuid
            }
            activityDocument {
              createdAt
              createdByUser {
                avatar {
                  url
                }
                firstName
                fullName
                lastName
              }
              status
              uuid
              variant
              versionNumber
            }
            activityHistory {
              uuid
            }
            file {
              filename
              mimeType
            }
            uuid
          }
        }
      }
    }`;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchActivityDocuments as SearchResultsGraphQL<
        SearchActivityDocumentsResponse["results"][number]
      >;
      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

export const searchActivityDefinitionDocumentTypes = async (
  req: SearchActivityDefinitionDocumentTypesRequest
): Promise<BaseResponseWithSingle<SearchActivityDefinitionDocumentTypesResponse>> => {
  const graphqlTypes = {
    activityDefinitionUuid: "UUID",
    isGenerated: "Boolean!",
    "activityDefinitionVersionDocumentTypes.some.isKey": "Boolean!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);

  const QUERY = `query(${variablesBlock}) {
  searchActivityDefinitionDocumentTypes(${queryBlock}) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges{
      cursor
      node {
        uuid
        activityDefinitionUuid
        displayName
        isGenerated
        isMaster
        isPerProject
        rowVersion
        activityDefinitionVersionDocumentTypes {
         uuid
         activityDefinitionVersionUuid
         templateFileUuid
         isKey
         hasVariant
        }
      }
    }
  }
 }`;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchActivityDefinitionDocumentTypes as SearchResultsGraphQL<
        SearchActivityDefinitionDocumentTypesResponse["results"][number]
      >;
      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

interface GetCurrentUserProjectPermissionsResponseGraphQL {
  currentUserProjectPermissions: GetCurrentUserProjectPermissionsResponse[];
}
export const getCurrentUserProjectPermissions = async (
  req: GetCurrentUserProjectPermissionsRequest
): Promise<BaseResponseWithMultiple<GetCurrentUserProjectPermissionsResponse>> => {
  const QUERY = gql`
    query ($projectUuids: [UUID!], $groupUuids: [UUID!]) {
      currentUserProjectPermissions(projectUuids: $projectUuids, groupUuids: $groupUuids) {
        projectUuid
        groupUuid
        currentUserPermissions
      }
    }
  `;

  return apolloPrivate
    .query<GetCurrentUserProjectPermissionsResponseGraphQL>({
      query: QUERY,
      variables: {
        projectUuids: req.projectUuids,
        groupUuids: req.groupUuids,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.currentUserProjectPermissions,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetGroupDetailsResponseGraphQL {
  groupDetails: GetGroupDetailsResponse[];
}

export const getGroupDetails = async (
  req: GetGroupDetailsRequest
): Promise<BaseResponseWithSingle<GetGroupDetailsResponse>> => {
  const variables = { projectGroupUuid: req.projectGroupUuid };

  const QUERY = gql`
    query ($projectGroupUuid: UUID!) {
      groupDetails(projectGroupUuid: $projectGroupUuid) {
        groupName
        groupGuid
        groupDescription
        externalReference
        manuallyUpdated
        registryMatch
        projects {
          addressCity
          addressCountryCode
          addressLine1
          addressLine2
          addressLine3
          addressRegion
          addressPostcode
          areaNetHa
          cachedPiuQuantity
          cachedVcuQuantity
          calculatedContactable
          developer {
            displayName
            listing {
              content
            }
            uuid
          }
          displayName
          duration
          listing {
            availableForSale
            content
            seekingFunding
            pilotProject
            showPriceInDirectory
          }
          locationCoordinates
          locationGridReference
          name
          projectType {
            displayName
            uuid
          }
          standard {
            displayName
            uuid
          }
          status
          standardSpecificData
          startDate
          uuid
          validator {
            displayName
            uuid
          }
        }
        rowVersion
      }
    }
  `;

  return apolloPrivate
    .query<GetGroupDetailsResponseGraphQL>({
      query: QUERY,
      variables,
    })
    .then((response) => {
      if (response.data.groupDetails[0].projects && Array.isArray(response.data.groupDetails[0].projects)) {
        const parsedProjectArr = response.data.groupDetails[0].projects.map((el) => {
          if (el.listing?.content && typeof el.listing?.content === "string") {
            // eslint-disable-next-line no-param-reassign
            el.listing.content = JSON.parse(el.listing.content, jsonReviver);
          }

          if (el.standardSpecificData && typeof el.standardSpecificData === "string") {
            // eslint-disable-next-line no-param-reassign
            el.standardSpecificData = JSON.parse(el.standardSpecificData, jsonReviver);
          }

          return el;
        });

        response.data.groupDetails[0].projects = parsedProjectArr;
      }

      return {
        status: Status.Success,
        data: response.data.groupDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetStandardSpecificSchemaDetailsResponseGraphQL {
  standardSpecificSchemaDetails: GetStandardSpecificSchemaDetailsResponse[];
}

export const getStandardSpecificSchemaDetails = async (
  req: GetStandardSpecificSchemaDetailsRequest
): Promise<BaseResponseWithSingle<GetStandardSpecificSchemaDetailsResponse>> => {
  const QUERY = gql`
    query ($standardSpecificSchemaUuid: UUID!) {
      standardSpecificSchemaDetails(standardSpecificSchemaUuid: $standardSpecificSchemaUuid) {
        definition
        schema
        standardUuid
        uuid
        validFrom
        validTo
        versionNumber
      }
    }
  `;
  return apolloPrivate
    .query<GetStandardSpecificSchemaDetailsResponseGraphQL>({
      query: QUERY,
      variables: {
        standardSpecificSchemaUuid: req.standardSpecificSchemaUuid,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: {
          ...response.data.standardSpecificSchemaDetails[0],
          definition: JSON.parse(response.data.standardSpecificSchemaDetails[0].definition, jsonReviver),
        },
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetDiscussionThreadsResponseGraphQL {
  discussionThreads: GetDiscussionThreadsResponse[];
}

export const getDiscussionThreads = async (
  req: GetDiscussionThreadsRequest
): Promise<BaseResponseWithMultiple<GetDiscussionThreadsResponse>> => {
  const QUERY = `
    query( $objectUuid: UUID!,
    $objectType: String!,
    $messageLookBack: Boolean!,
    $threadUuids: [UUID!]) {
      discussionThreads(request: {objectUuid: $objectUuid, objectType: $objectType, messageLookBack: $messageLookBack, threadUuids: $threadUuids})
        {
          isResolved
          threadType
          uuid
          visibility
          location
          hasNonDeletedMessagesCreatedByOtherUsers
          messages {
              content
              createdAt
              isRead
              messageType
              rejectedReason
              status
              uuid
              sourceOrganisationUuid
              acceptedByUser {
                  fullName
              }
              createdByUser {
                  uuid
                  fullName
                  avatar {
                    url
                  }
              }
              rejectedByUser {
                  fullName
              }
              rowVersion
          }
      }
    }`;

  return apolloPrivate
    .query<GetDiscussionThreadsResponseGraphQL>({
      query: gql(QUERY),
      variables: {
        objectUuid: req.objectUuid,
        objectType: req.objectType,
        messageLookBack: req.messageLookBack,
        threadUuids: req.threadUuids,
      },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.discussionThreads,
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

export const searchDiscussionThreads = async (
  req: SearchDiscussionThreadsRequest
): Promise<BaseResponseWithSingle<SearchDiscussionThreadsResponse>> => {
  const graphqlTypes = {
    subject: "String!",
    location: "String!",
    type: "String!",
    visibility: "String!",
    isResolved: "Boolean!",
    "createdByUser.fullName": "String!",
  };
  const [queryBlock, variablesBlock, variables] = generateGraphQLHelpers(req, graphqlTypes);
  variables.objectUuid = req.objectUuid;
  variables.objectType = req.objectType;
  variables.isDraft = req.isDraft;

  const QUERY = `
    query($objectUuid: UUID!, $objectType: String!, $isDraft: Boolean!, ${variablesBlock}) {
      searchDiscussionsThreads(
          request: {objectUuid: $objectUuid, objectType: $objectType, isDraft: $isDraft}
          ${queryBlock}
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            uuid
            createdAt
            isResolved
            lastUpdated
            location
            threadType
            visibility
            currentActivityHistoryUuid
            createdByUser {
                fullName
            }
            messages {
              content
              createdAt
              isRead
              messageType
              rejectedReason
              status
              uuid
              objectType
              objectUuid
              objectVersionNumber
              sourceOrganisationUuid
              acceptedByUser {
                  fullName
              }
              createdByUser {
                  fullName
                  uuid
              }
              rejectedByUser {
                  fullName
              }
              rowVersion
            }
          }
        }
      }
    }
  `;

  return apolloPrivate
    .query<any>({
      query: gql(QUERY),
      variables,
    })
    .then((response) => {
      const data = response.data.searchDiscussionsThreads as SearchResultsGraphQL<
        SearchDiscussionThreadsResponse["results"][number]
      >;
      const results = data.edges.map((e) => e.node);

      return {
        status: Status.Success,
        data: {
          paging: {
            startCursor: data.pageInfo.startCursor,
            endCursor: data.pageInfo.endCursor,
            hasNextPage: data.pageInfo.hasNextPage,
            hasPreviousPage: data.pageInfo.hasPreviousPage,
            total: data.totalCount,
          },
          results,
        },
      };
    });
};

interface fetchHasUnreadMessagesResponseGraphQL {
  searchDiscussionsThreads: {
    nodes: {
      visibility: string;
      messages: {
        isRead: boolean;
        sourceOrganisationUuid: string;
        createdByUser: {
          uuid: string;
        };
      }[];
    }[];
  };
}

type fetchHasUnreadMessagesResponse =
  fetchHasUnreadMessagesResponseGraphQL["searchDiscussionsThreads"]["nodes"][number];

type fetchHasUnreadMessagesResponseMessage = fetchHasUnreadMessagesResponse["messages"][number];

export const fetchHasUnreadMessages = async (
  activityUuid: string,
  userUuid: string,
  currentOrganisationUuid: string
): Promise<boolean> => {
  const variables = {
    objectUuid: activityUuid,
    objectType: "Activity",
  };

  const QUERY = `
    query($objectUuid: UUID!, $objectType: String!) {
      searchDiscussionsThreads(
          request: {
              objectUuid: $objectUuid
              objectType: $objectType
              isDraft: false
          }
          where: { messages: { some: { isRead: { eq: false } } } }
      ) {
        totalCount
        nodes {
            visibility
            messages {
                isRead
                sourceOrganisationUuid
                createdByUser {
                    uuid
                }
            }
        }
      }
    }
  `;

  const res = await apolloPrivate.query<fetchHasUnreadMessagesResponseGraphQL>({
    query: gql(QUERY),
    variables,
  });

  return res.data.searchDiscussionsThreads.nodes.some((t: fetchHasUnreadMessagesResponse): boolean =>
    t.messages.some(
      (m: fetchHasUnreadMessagesResponseMessage) =>
        (t.visibility === DiscussionVisibilityConstants.INTERNAL
          ? m.createdByUser.uuid !== userUuid
          : m.sourceOrganisationUuid !== currentOrganisationUuid) && !m.isRead
    )
  );
};

interface GetDevelopmentManagerDetailsResponseGraphQL {
  developmentManagerDetails: GetDevelopmentManagerDetailsResponse[];
}

export const getDevelopmentManagerDetails = async (
  req: GetDevelopmentManagerDetailsRequest
): Promise<BaseResponseWithSingle<GetDevelopmentManagerDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.DevelopmentManager);

  return apolloPrivate
    .query<GetDevelopmentManagerDetailsResponseGraphQL>({
      query: QUERY,
      variables: { developmentManagerUuid: req.developmentManagerUuid },
    })
    .then((response) => {
      if (response.data.developmentManagerDetails[0].listing?.content)
        response.data.developmentManagerDetails[0].listing.content = JSON.parse(
          response.data.developmentManagerDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.developmentManagerDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};

interface GetAssetManagerDetailsResponseGraphQL {
  assetManagerDetails: GetAssetManagerDetailsResponse[];
}

export const getAssetManagerDetails = async (
  req: GetAssetManagerDetailsRequest
): Promise<BaseResponseWithSingle<GetAssetManagerDetailsResponse>> => {
  const QUERY = getOrganisationDetailsHelper(OrganisationType.AssetManager);

  return apolloPrivate
    .query<GetAssetManagerDetailsResponseGraphQL>({
      query: QUERY,
      variables: { assetManagerUuid: req.assetManagerUuid },
    })
    .then((response) => {
      if (response.data.assetManagerDetails[0].listing?.content)
        response.data.assetManagerDetails[0].listing.content = JSON.parse(
          response.data.assetManagerDetails[0].listing.content,
          jsonReviver
        );
      return {
        status: Status.Success,
        data: response.data.assetManagerDetails[0],
      };
    })
    .catch((error) => {
      const errors = error?.networkError?.result?.errors;
      const ret = errors
        ? errors.map(
            (e: any) =>
              ({
                property: e.property,
                path: e.path,
                parameters: e.extensions,
                message: e.message,
                code: e.code,
              }) as ServiceError
          )
        : [];
      return {
        status: Status.Error,
        errors: ret,
      };
    });
};
