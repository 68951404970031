import "./Valuations.css";

import { DeveloperTemplate } from "../../../../templates";
import { VersionConflictModal } from "../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { Button, Column, DeleteActivityModal, Row, UserHeader } from "../../../../widget";
import { CreateActivityVariantModal } from "../../../shared/components";
import { DashboardTableCard } from "../components";
import { ReportPanel } from "./components";
import { useValuations } from "./useValuations";

const Valuations = (): JSX.Element => {
  const {
    deleteActivityUuid,
    createActivityVariantModalData,
    showDeleteActivityModal,
    setShowDeleteActivityModal,
    showCreateActivityVariantModal,
    setShowCreateActivityVariantModal,
    handleBackClick,
    isLoading,
    columns,
    refresh,
    setShowVersionConflictModal,
    showVersionConflictModal,
    setShowReportPanel,
    showReportPanel,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    reportPanelActivityDetails,
    onChange,
    onDelete,
  } = useValuations();

  return (
    <DeveloperTemplate activeElement="Dashboard">
      <div className="ValuationsListContainer">
        <Row spacingV="ml">
          <Column span={12}>
            <UserHeader backButtonText="Back to dashboard" handleBackClick={handleBackClick} />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={6}>
            <div className="HeaderContainer">
              <h1>Valuations</h1>
            </div>
          </Column>
          <Column span={6}>
            <Row justify="end">
              <Button text="New valuation" onClick={() => setShowCreateActivityVariantModal(true)} />
            </Row>
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <DashboardTableCard
              onChange={onChange}
              columns={columns}
              pageable
              isLoading={isLoading}
              refresh={refresh}
              title=""
            />
          </Column>
        </Row>
        <DeleteActivityModal
          deleteActivityUuid={deleteActivityUuid}
          show={showDeleteActivityModal}
          onDelete={onDelete}
          onClose={() => setShowDeleteActivityModal(false)}
        />
        {createActivityVariantModalData && (
          <CreateActivityVariantModal
            show={showCreateActivityVariantModal}
            closeModal={() => setShowCreateActivityVariantModal(false)}
            modalData={createActivityVariantModalData}
          />
        )}
      </div>
      <VersionConflictModal
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
        hideDownloadButton
      />
      <ReportPanel
        activityDetails={reportPanelActivityDetails}
        show={showReportPanel}
        onClose={() => setShowReportPanel(false)}
      />
    </DeveloperTemplate>
  );
};

export default Valuations;
