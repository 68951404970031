import "./ReviewContent.css";

import { Dispatch, SetStateAction } from "react";

import { SearchActivityHistoryResponse } from "../../../../../../service/query";
import { Button, Column, Divider, Row } from "../../../../../../widget";
import { isActivityDataValid } from "../../../../../developer/activities";
import { SubmitValuationModal } from "../SubmitValuationModal";
import { useReviewContent } from "./useReviewContent";

interface ReviewContentProps {
  setShowVersionConflictModal?: Dispatch<SetStateAction<boolean>>;
  setDraftActivityHistoryUuid?: Dispatch<SetStateAction<string | undefined>>;
  setNewVersionActivityHistory?: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>;
}

export const ReviewContent = ({
  setShowVersionConflictModal,
  setDraftActivityHistoryUuid,
  setNewVersionActivityHistory,
}: ReviewContentProps): JSX.Element => {
  const { activityData, showSubmitValuationModal, setShowSubmitValuationModal, submitActivity } = useReviewContent(
    setShowVersionConflictModal,
    setDraftActivityHistoryUuid,
    setNewVersionActivityHistory
  );

  return (
    <>
      <Row spacingV="xl" className="ActivityReviewHeader">
        <Column span={12}>
          <h1>Review</h1>
        </Column>
      </Row>
      <Row spacingV="xl" className="ActivityReviewHeader">
        <Column span={12}>
          <p className="body1">An activity can only be submitted once all sections are validated and complete</p>
        </Column>
      </Row>
      {activityData && (
        <div className="ActivityReviewContent">
          <div className="ActivityReviewContentHeader">
            <Row spacingV="l">
              <Column className="ActivityReviewContentTitle" justify="center" align="start" span={6}>
                <h2>{`Version ${activityData.versionNumber} ${activityData.isCurrent ? "(Latest)" : ""}`}</h2>
              </Column>
              <Column span={6} justify="end">
                {activityData.completionPercentage === 1 && isActivityDataValid(activityData?.data.steps) && (
                  <Button text="Submit" onClick={submitActivity} />
                )}
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={2}>
                <h3>Author</h3>
                <p className="body2">{activityData.createdByUser.fullName}</p>
              </Column>
              <Column span={2}>
                <h3>Variant</h3>
                <p className="body2">{activityData.activity.variant ?? "N/A"}</p>
              </Column>
            </Row>
          </div>
          <Row spacingV="ll">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
        </div>
      )}
      {activityData?.activity.uuid && activityData.uuid && activityData.rowVersion && activityData.isDraft && (
        <SubmitValuationModal
          show={showSubmitValuationModal}
          onClose={() => setShowSubmitValuationModal(false)}
          activityUuid={activityData.activity.uuid}
          activityHistoryUuid={activityData?.uuid}
          activityHistoryRowVersion={activityData?.rowVersion}
          isDraft={activityData?.isDraft}
        />
      )}
    </>
  );
};
