import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ActivityDashboardContext } from "../../../../../route/shared/activities-dashboard";
import { deleteActivity } from "../../../../../service/activity";
import { BaseResponse, Status } from "../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../utils";
import { getProjectActivitiesRoute } from "../../../../../utils/routes";
import { Toast } from "../../../../general";

interface UseDeleteActivityModalProps {
  deleteActivityUuid: string | undefined;
  onDelete?: () => void; // optional for valuations list
}

interface UseDeleteActivityModalReturnData {
  onDeleteClick: () => void;
  isOnDeleteClickLoading: boolean;
}

export const useDeleteActivityModal = ({
  deleteActivityUuid,
  onDelete,
}: UseDeleteActivityModalProps): UseDeleteActivityModalReturnData => {
  const { activityUuid, activityDetails } = useContext(ActivityDashboardContext);
  const { currentUserType } = useAuth();
  const navigate = useNavigate();

  const [isOnDeleteClickLoading, setOnDeleteClickLoading] = useState(false);

  const onDeleteClick = useIsLoadingWrapper(async (): Promise<void> => {
    let res: BaseResponse | undefined;
    if (activityUuid) {
      res = await deleteActivity({ uuid: activityUuid });
    } else if (deleteActivityUuid) {
      res = await deleteActivity({ uuid: deleteActivityUuid });
    }

    if (res?.status === Status.Success) {
      if (activityDetails?.project?.uuid) {
        navigate(getProjectActivitiesRoute(activityDetails?.project.uuid, currentUserType));
      } else if (onDelete) {
        onDelete();
      }
      Toast.success({ message: "Activity deleted successfully" });
    } else {
      Toast.error({ message: getErrorMessageFromCode(res?.errors ? res.errors[0].message : "ERROR_APPLICATION") });
    }
  }, setOnDeleteClickLoading);

  return {
    onDeleteClick,
    isOnDeleteClickLoading,
  };
};
