import { Button, Column, Modal, Row } from "../../../../../../widget";
import { useSubmitValuationModal } from "./useSubmitValuationModal";

interface SubmitActivityModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
  isDraft: boolean;
}

export const SubmitValuationModal = ({
  show,
  onClose,
  activityUuid,
  activityHistoryUuid,
  activityHistoryRowVersion,
  isDraft,
}: SubmitActivityModalProps): JSX.Element => {
  const { isLoading, handleSubmit } = useSubmitValuationModal({
    activityUuid,
    activityHistoryUuid,
    activityHistoryRowVersion,
    isDraft,
  });

  return (
    <Modal show={show} title="Submit valuation" onClose={onClose}>
      <Row spacingV="ml" justify="center">
        <Column span={10}>
          <span className="body1">Submit the valuation and return to the valuations page.</span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <div className="SubmitActivityModalButtons">
            <Button text="Submit" onClick={handleSubmit} isLoading={isLoading} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
