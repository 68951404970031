import React, { ReactNode } from "react";

import kanaExpressionInterpreter from "../../../../../lib/KanaExpressionInterpreter";
import { RepeaterData } from "../../../../../models";
import { logError } from "../../../../../service/error";
import { GetDiscussionThreadsResponse } from "../../../../../service/query";
import {
  Column,
  Divider,
  findDiscussionsInChild,
  Repeater,
  RepeaterProps,
  Row,
  TableRepeater,
  TableRepeaterProps,
} from "../../../../../widget";
import { ComponentRow } from "../components/ComponentRow";
import { Component, DataType, UploadedDocumentEnriched } from "../types";
import {
  computeComponentKey,
  convertToCorrectType,
  createCheckBox,
  createDateInput,
  createInformationBox,
  createMultiFileUpload,
  createParagraph,
  createRadioButtonGroup,
  createReadonlyText,
  createReviewComponent,
  createSelect,
  createSingleFileUpload,
  createSlider,
  createTextArea,
  createTextInput,
  createToggle,
  createViewComponent,
} from "./CreateComponentUtils";

export const convertDataForRepeater = (
  data: string[][],
  template: {
    key: string;
    dataType: DataType;
  }[]
): RepeaterData[] => {
  return data.map((arr) =>
    arr.reduce((acc: object, val: string, index: number) => {
      const { key, dataType } = template[index];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let value: any = val;

      if (dataType === "Date") {
        value = new Date(val);
      }

      value = convertToCorrectType(value, dataType);

      return { ...acc, [key]: value };
    }, {})
  );
};

const parseRows = (data: string): string[][] => {
  return data
    .trim()
    .split(/\r?\n+/)
    .map((row) => row.trim().split("\t"));
};

export const formatClipboardValue = (value: string, template: ReactNode[]): RepeaterData[] | null => {
  if (!value || !value.length) return null;

  const rowsArr = parseRows(value);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const templateData = Object.values(template).map((el: any) => ({
    key: el.key,
    dataType: el.props.dataType,
  }));

  if (rowsArr && rowsArr[0].length === templateData.length) {
    return convertDataForRepeater(rowsArr, templateData);
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertRepeaterValuesToCorrectType = (rd: RepeaterData[], childrenArray: any[]): RepeaterData[] => {
  const formattedData = rd.map((el) => {
    const formattedEntries = Object.entries(el).map((e) => {
      const currChild = childrenArray.find((c) => c.key === e[0]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let convertedData: any;

      switch (currChild.component) {
        case "TableRepeater":
          convertedData = convertRepeaterValuesToCorrectType(e[1] as unknown as RepeaterData[], currChild.children);
          break;
        default:
          convertedData = convertToCorrectType(e[1], childrenArray.find((c) => c.key === e[0]).dataType);
      }
      return [e[0], convertedData];
    });
    return Object.fromEntries(formattedEntries);
  });

  return formattedData;
};

export const createRepeater = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: Component,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setter: (value: any, key: string) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, unused-imports/no-unused-vars
  expressionContext: any,
  singleDocumentSetter: (
    document?: UploadedDocumentEnriched,
    deletedActivityDefinitionVersionDocumentTypeUuidAndVariant?: string
  ) => void,
  multiDocumentSetter: (key: string, documents: UploadedDocumentEnriched[]) => void,
  activityDocuments: UploadedDocumentEnriched[],
  stepDocuments: UploadedDocumentEnriched[],
  activityUuid: string,
  dataPath: string,
  isActivityWizardLite?: boolean
): JSX.Element | null => {
  const props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(component.componentProperties as any),
    initialValue: values[component.key],
    children: [],
    expressionContext,
  } as RepeaterProps;

  props.discussions = discussions;

  const childrenArray = [...(component.children || [])];
  // KAN-2918: remove uploaded files - keep track of files in deleted rows in the repeater
  const singleFileUploadComponentKeys: string[] = [];
  const multiFileUploadComponentKeys: string[] = [];

  // if the repeater is conditionally displayed and his value is undefined and isFixedSize
  // then it might a repeater which iterates over a list of projects
  // so we should render a row for each project
  if (component.conditionExpression && props.initialValue === undefined && props.isFixedSize) {
    const projectsExp = "@group.projects[$index].name";
    const groupProjects = kanaExpressionInterpreter("@group.projects", expressionContext) || [];
    const childWithProjectsExp = childrenArray.length
      ? childrenArray.find((el) => el.defaultValueExpression === projectsExp)
      : null;

    if (Array.isArray(groupProjects) && groupProjects.length && childWithProjectsExp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const computedData = groupProjects.map((el: any) => {
        return { [childWithProjectsExp.key]: el.displayName };
      });

      setter(computedData, component.key);
      props.initialValue = computedData;
    }
  }

  childrenArray.forEach((childComponent: Component) => {
    // eslint-disable-next-line no-param-reassign
    childComponent.componentProperties.key = childComponent.key;

    const repeaterRenderProps = {
      dataType: childComponent.dataType,
      componentType: childComponent.component,
      conditionExpression: childComponent.conditionExpression,
      defaultValueExpression: undefined,
      componentKey: childComponent.key,
      dataPath,
      activityUuid,
      isActivityWizardLite,
    };

    // eslint-disable-next-line no-param-reassign
    childComponent.componentProperties.repeaterRenderProps = repeaterRenderProps;

    let comp;
    switch (childComponent.component) {
      // All the components bellow, even though they have `discussions` as an argument, they do not render the discussions. Discussion rendering happens in useRepeater, where `createComponentUtils` is called
      // Try to pass  [] to the components bellow
      case "ReadonlyText":
        // eslint-disable-next-line no-param-reassign
        childComponent.componentProperties.repeaterRenderProps.defaultValueExpression =
          childComponent.defaultValueExpression;
        comp = createReadonlyText(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "Paragraph":
        comp = createParagraph(childComponent);
        break;
      case "InformationBox":
        comp = createInformationBox(childComponent);
        break;
      case "TextInput":
        comp = createTextInput(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "TextArea":
        comp = createTextArea(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "Slider":
        comp = createSlider(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "Toggle":
        comp = createToggle(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "RadioButtonGroup":
        comp = createRadioButtonGroup(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "CheckBox":
        comp = createCheckBox(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "Select":
        comp = createSelect(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "DateInput":
        comp = createDateInput(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "SingleFileUpload":
        comp = createSingleFileUpload(
          childComponent,
          discussions,
          dataPath,
          activityUuid,
          expressionContext,
          singleDocumentSetter,
          activityDocuments,
          stepDocuments,
          values[component.key],
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        singleFileUploadComponentKeys.push(childComponent.key);
        break;
      case "MultiFileUpload":
        comp = createMultiFileUpload(
          childComponent,
          discussions,
          dataPath,
          activityUuid,
          multiDocumentSetter,
          stepDocuments,
          activityUuid,
          component.key,
          undefined,
          undefined,
          isActivityWizardLite
        );
        multiFileUploadComponentKeys.push(childComponent.key);
        break;
      case "TableRepeater":
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        comp = createTableRepeater(
          childComponent,
          discussions,
          `${component.key}.${childComponent.key}`,
          values,
          setter,
          expressionContext,
          component.key,
          true,
          isActivityWizardLite
        );
        break;
      default:
        logError({ error: `There's no implementation for ${childComponent.component} in the Repeater` });
        comp = <div> Not implemented {childComponent.component}</div>;
        break;
    }
    props.children = [...props.children, comp];
  });

  props.onChange = (rd: RepeaterData[], repeaterIdx?: number) => {
    const formattedData = convertRepeaterValuesToCorrectType(rd, childrenArray);
    // KAN-2918: remove uploaded files - keep track of files in deleted rows in the repeater
    const previousValues = values[component.key] as RepeaterData[];
    if (previousValues?.length && rd.length < previousValues.length && singleFileUploadComponentKeys.length > 0) {
      // a row has been removed, and that row contains a file; handle the file removal
      const previousFiles: string[] = [];
      const currentFiles: string[] = [];
      singleFileUploadComponentKeys.forEach((k) => previousValues.forEach((pv) => previousFiles.push(pv[k] as string)));
      singleFileUploadComponentKeys.forEach((k) => rd.forEach((pv) => currentFiles.push(pv[k] as string)));
      const deletedDocuments = previousFiles.filter((pf) => !currentFiles.includes(pf));
      deletedDocuments.forEach((dd) => singleDocumentSetter(undefined, dd));
    }

    // A row has been removed, and that row contains multifileUpload elements
    // We need to delete the files by passing an empty array to the removed keys
    if (
      previousValues?.length &&
      rd.length < previousValues.length &&
      multiFileUploadComponentKeys.length > 0 &&
      repeaterIdx !== undefined
    ) {
      multiFileUploadComponentKeys.forEach((mfuKey) => {
        multiDocumentSetter(`${component.key}|${repeaterIdx}|${mfuKey}`, []);
      });
    }

    setter(formattedData, component.key);
  };

  // Checking if the repeater contains any SingleFileUpload components
  // If it does, then the user will be prompted to confirm deletion of the files
  // conditionalConfirmDeletionFields will be set to the keys of the SingleFileUpload components and will be used to check if the files need confirmation
  if (childrenArray.some((el) => el.component === "SingleFileUpload")) {
    props.shouldConfirmDeletion = true;

    props.deleteModalData = {
      title: "Delete document?",
      text: [
        "If you delete this document it will be removed from everywhere within the activity wizard. If you wish to replace the document from the current field only, select 'Upload a new version'.",
        "Are you sure you want to delete this document?",
      ],
      confirmText: "Delete",
      cancelText: "Cancel",
    };

    props.conditionalConfirmDeletionFields = childrenArray
      .filter((el) => el.component === "SingleFileUpload")
      .map((el) => el.key);
  }

  const componentKey = computeComponentKey(dataPath, component.key);

  return (
    <Row key={componentKey} id={componentKey} spacingV="ll">
      <Column hasNoPadding span={12}>
        {React.createElement(Repeater, props)}
      </Column>
    </Row>
  );
};

export const createTableRepeater = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  dataPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setter: (value: any, key: string) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any,
  repeaterKey?: string,
  isInRepeater?: boolean,
  isActivityWizardLite?: boolean
): JSX.Element | null => {
  const props = {
    ...component.componentProperties,
    initialValue: repeaterKey ? values[repeaterKey] : values[component.key],
    children: [],
    expressionContext,
    keyProp: component.key,
  } as TableRepeaterProps;

  const childrenArray = [...component.children];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props.tableHeaders = childrenArray.map((el: any) => el.componentProperties.label);
  childrenArray.forEach((element: Component) => {
    const childComponent = { ...element };

    const repeaterRenderProps = {
      dataType: childComponent.dataType,
      componentType: childComponent.component,
      conditionExpression: childComponent.conditionExpression,
      defaultValueExpression: undefined,
      parentIsTableRepeater: true,
    };

    childComponent.componentProperties = {
      ...childComponent.componentProperties,
      key: childComponent.key,
      label: "",
      repeaterRenderProps,
    };

    let comp;

    switch (childComponent.component) {
      case "ReadonlyText":
        childComponent.componentProperties.repeaterRenderProps.defaultValueExpression =
          childComponent.defaultValueExpression;
        comp = createReadonlyText(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "TextInput":
        comp = createTextInput(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "TextArea":
        comp = createTextArea(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "Slider":
        comp = createSlider(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "Toggle":
        comp = createToggle(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "RadioButtonGroup":
        comp = createRadioButtonGroup(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "CheckBox":
        comp = createCheckBox(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "Select":
        comp = createSelect(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      case "DateInput":
        comp = createDateInput(
          childComponent,
          discussions,
          dataPath,
          undefined,
          undefined,
          undefined,
          isActivityWizardLite
        );
        break;
      default:
        logError({ error: `There's no implementation for ${childComponent.component} in the Table Repeater` });
        comp = <div> Not implemented {childComponent.component}</div>;
        break;
    }

    props.children = [...props.children, comp];
  });

  props.onChange = (rd: RepeaterData[]) => {
    const formattedData = convertRepeaterValuesToCorrectType(rd, childrenArray);
    setter(formattedData, component.key);
  };

  const key = computeComponentKey(dataPath, component.key);

  if (isInRepeater) {
    return (
      <Row key={key} id={key} spacingV="ll">
        <Column style={{ paddingRight: 0 }} span={12}>
          {React.createElement(TableRepeater, props)}
        </Column>
      </Row>
    );
  }

  return (
    <ComponentRow
      isForTableRepeater
      key={key}
      id={key}
      rowWrapKey={key}
      reactEl={React.createElement(TableRepeater, props)}
      discussions={discussions}
      componentKey={component.key}
      dataPath={dataPath}
      isActivityWizardLite={isActivityWizardLite}
    />
  );
};

export const createReadonlyRepeater = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any,
  activityDocuments: UploadedDocumentEnriched[],
  dataPath: string,
  isViewMode: boolean
): JSX.Element => {
  const childrenArray = [...component.children];
  const valuesArray = values[component.key];
  const componentsToRender: JSX.Element[] = [];

  if (!valuesArray) {
    // Need to always display child components, even if there are no values or discussions
    // findDiscussionsInChild has index passed as `0` because if there are no values, the discussion will always be found at index 0

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    childrenArray.forEach((child: any, idxChild: number) => {
      const viewComponent = isViewMode
        ? createViewComponent(
            child,
            findDiscussionsInChild(discussions, child.key, 0),
            {},
            expressionContext,
            activityDocuments,
            dataPath,
            []
          )
        : createReviewComponent(
            child,
            findDiscussionsInChild(discussions, child.key, 0),
            {},
            expressionContext,
            activityDocuments,
            dataPath,
            true,
            0
          );

      // giving each component an unique key
      if (viewComponent !== null) {
        const updatedComponent = { ...viewComponent, key: `${viewComponent.key}${0}${idxChild}` };
        componentsToRender.push(updatedComponent);
      }
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valuesArray?.forEach((value: any, idxValue: number) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      childrenArray.forEach((child: any, idxChild: number) => {
        const viewComponent = isViewMode
          ? createViewComponent(
              child,
              findDiscussionsInChild(discussions, child.key, idxValue),
              value,
              expressionContext,
              activityDocuments,
              dataPath,
              [idxValue]
            )
          : createReviewComponent(
              child,
              findDiscussionsInChild(discussions, child.key, idxValue),
              value,
              expressionContext,
              activityDocuments,
              dataPath,
              true,
              idxValue
            );

        // giving each component an unique key
        if (viewComponent !== null) {
          const updatedComponent = { ...viewComponent, key: `${viewComponent.key}${idxValue}${idxChild}` };
          componentsToRender.push(updatedComponent);
        }
      });

      if (component?.componentProperties?.isFixedSize && idxValue < valuesArray.length - 1) {
        componentsToRender.push(
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`${component.key}Divider${idxValue}`} spacingV="ll">
            <Column span={8}>
              <Divider type="horizontal" thickness={3} />
            </Column>
          </Row>
        );
      }
    });
  }

  const key = computeComponentKey(dataPath, component.key);
  return (
    <Row key={key} id={key} spacingV="ll">
      <Column span={12}>{componentsToRender}</Column>
    </Row>
  );
};

export const createReadonlyTableRepeater = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any,
  discussions: GetDiscussionThreadsResponse[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expressionContext: any,
  activityDocuments: UploadedDocumentEnriched[],
  dataPath: string,
  isViewMode: boolean,
  isForRepeater?: boolean,
  repeaterIdx?: number
): JSX.Element => {
  const childrenArray = [...component.children];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableHeaders = childrenArray.map((el: any) => el.componentProperties.label);
  const valuesArray = values[component.key];
  const componentsToRender: JSX.Element[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valuesArray?.forEach((value: any, idxValue: number) => {
    const rowComponentsToRender: JSX.Element[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    childrenArray.forEach((child: any, idxChild: number) => {
      // only rendering components that have values
      const childValues = value[child.key];

      if (childValues) {
        const viewComponent = isViewMode
          ? createViewComponent(
              { ...child, componentProperties: { ...child.componentProperties, label: "" } }, // creating a view component for the child with the label removed
              findDiscussionsInChild(discussions, child.key, idxValue),
              value,
              expressionContext,
              activityDocuments,
              dataPath,
              [idxValue]
            )
          : createReviewComponent(
              { ...child, componentProperties: { ...child.componentProperties, label: "" } }, // creating a view component for the child with the label removed
              findDiscussionsInChild(discussions, child.key, idxValue),
              value,
              expressionContext,
              activityDocuments,
              dataPath,
              true,
              idxValue
            );

        if (viewComponent != null) {
          // digging to the last child in the viewComponent
          const leafComponent = {
            ...viewComponent?.props.reactEl,
          };

          // eslint-disable-next-line react/no-array-index-key
          rowComponentsToRender.push(<td key={`${viewComponent.key}${idxValue}${idxChild}`}>{leafComponent}</td>);
        }
      }
    });

    const tableRow = (
      // eslint-disable-next-line react/no-array-index-key
      <tr key={`TableRepeaterRow${idxValue}`} className="TableRepeaterRow">
        {rowComponentsToRender}
      </tr>
    );

    componentsToRender.push(tableRow);
  });

  const key = computeComponentKey(dataPath, component.key);

  return (
    <ComponentRow
      isForTableRepeater
      isForRepeater={isForRepeater}
      repeaterIndex={repeaterIdx}
      isForViewMode={isViewMode}
      key={key}
      id={key}
      rowWrapKey={key}
      reactEl={
        <div className="TableRepeaterContainer">
          <table className="TableRepeater">
            <thead>
              <tr className="TableRepeaterRow">
                {tableHeaders.map((el) => (
                  <th key={el}>{el}</th>
                ))}
              </tr>
            </thead>
            <tbody>{componentsToRender}</tbody>
          </table>
        </div>
      }
      discussions={discussions}
      componentKey={component.key}
      dataPath={dataPath}
    />
  );
};
