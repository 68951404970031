import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ProjectAccessConstants, UserType } from "../../../constants";
import { CursorChangeProps, ResultType } from "../../../models";
import { IStateOrigin } from "../../../navigators/navigateStateObjects";
import {
  getProjectAggregate,
  searchActivities,
  SearchActivitiesResponse,
  searchProjects,
  SearchProjectsResponse,
} from "../../../service/query";
import { ResultData, Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { flattenObject } from "../../../utils";
import { getActivityViewRoute, getProjectDetailsByUuid, getValuationsRoute } from "../../../utils/routes";
import {
  ChartData,
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridCursorDataLoadEventHandler,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
  ProfileMenuItemType,
  Toast,
} from "../../../widget";

interface UseDashboardReturnData {
  statusChartData: ChartData;
  unitsChartData: ChartData;
  metricsChartData: ChartData;
  tableColumnsTopProjects: DataGridColumnDefinition[];
  tableColumnsRecentActivities: DataGridColumnDefinition[];
  isLoading: boolean;
  isLoadingProjects: boolean;
  isLoadingActivities: boolean;
  onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler;
  onRecentActivitiesDataLoad: DataGridCursorDataLoadEventHandler;
  quickActionsMenuOptionsList: ProfileMenuItemType[];
  showQuickActions: boolean;
  showCreateProjectModal: boolean;
  setShowQuickActions: Dispatch<SetStateAction<boolean>>;
  setShowCreateProjectModal: Dispatch<SetStateAction<boolean>>;
  hasPermission: (permission: string) => boolean;
  currentUserType: UserType;
}

export const useDashboard = (): UseDashboardReturnData => {
  const {
    currentDeveloperUuid,
    currentVerifierUuid,
    currentStandardUuid,
    currentOrganisationUuid,
    currentAssetManagerUuid,
    currentUserType,
    hasPermission,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [statusChartData, setStatusChartData] = useState<ChartData>({});
  const [unitsChartData, setUnitsChartData] = useState<ChartData>({});
  const [metricsChartData, setMetricsChartData] = useState<ChartData>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [isLoadingActivities, setIsLoadingActivities] = useState(true);
  const [showQuickActions, setShowQuickActions] = useState(false);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  const tableColumnsTopProjects: DataGridColumnDefinition[] = [
    {
      key: "link",
      name: "Project",
      dataType: "string",
      formatter: "link",
    },
    { key: "standard.displayName", name: "Code", dataType: "string" },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectStatusPill",
      minWidth: 200,
    },
    {
      key: "cachedPiuQuantity",
      name: "PIUs",
      dataType: "string",
    },
    {
      key: "cachedVcuQuantity",
      name: "VCUs",
      dataType: "string",
    },
  ];

  const tableColumnsRecentActivities: DataGridColumnDefinition[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
    },
    { key: "standardDisplayName", name: "Code", dataType: "string" },
    {
      key: "activityLink",
      name: "Activity",
      dataType: "string",
      formatter: "buttonLink",
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 240,
    },
    {
      name: "Progress",
      key: "currentVersion.completionPercentage",
      dataType: "number",
      formatter: "progress",
    },
    {
      name: "Last updated",
      key: "currentVersion.updatedAt",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
  ];

  const quickActionsMenuOptionsList: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "View valuations",
      action: () => {
        navigate(getValuationsRoute());
      },
    },
  ];

  const { filterBy, filterValue } = useMemo(() => {
    if (currentDeveloperUuid) {
      return {
        filterBy: "developer",
        filterValue: currentDeveloperUuid,
      };
    }
    if (currentVerifierUuid) {
      return {
        filterBy: "verifier",
        filterValue: currentVerifierUuid,
      };
    }
    if (currentStandardUuid) {
      return {
        filterBy: "standard",
        filterValue: currentStandardUuid,
      };
    }
    // if (currentDevelopmentMangerUuid) {
    //   return {
    //     filterBy: "developmentManager",
    //     filterValue: currentDevelopmentMangerUuid,
    //   };
    // }
    if (currentAssetManagerUuid) {
      return {
        filterBy: "assetManager",
        filterValue: currentAssetManagerUuid,
      };
    }
    throw new Error(
      "Organisation not currently linked to a developer, verifier, standard, development manager or asset manager"
    );
  }, [currentDeveloperUuid, currentVerifierUuid, currentStandardUuid, currentAssetManagerUuid]);

  const fetchData = useCallback(async () => {
    const [statusAggregateResponse, piuUnitsAggregateResponse, vcuUnitsAggregateResponse, metricsAggregateResponse] =
      await Promise.all([
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue,
          groupBy: "status",
          aggregation: "count",
          aggregate: "id",
        }),
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue,
          aggregation: "sum",
          aggregate: "cachedPiuQuantity",
          groupBy: null,
        }),
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue,
          aggregation: "sum",
          aggregate: "cachedVcuQuantity",
          groupBy: null,
        }),
        // TODO ENG-557 hidden until post-launch
        Promise.resolve({
          status: Status.Error,
          data: [{ key: "remove", objectName: "remove", value: 1 }],
        }),
        // getAggregate({
        //   periodicity: "AllTime",
        //   metric: "PageViews",
        //   objectType: "project",
        //   objectUuid: null,
        //   timestamp: new Date(),
        // }),
      ]);

    if (statusAggregateResponse.status === Status.Success && statusAggregateResponse.data) {
      setStatusChartData(
        statusAggregateResponse.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.key] = current.value;

          return previous;
        }, {})
      );
    }

    if (
      piuUnitsAggregateResponse.status === Status.Success &&
      piuUnitsAggregateResponse.data &&
      vcuUnitsAggregateResponse.status === Status.Success &&
      vcuUnitsAggregateResponse.data
    ) {
      setUnitsChartData({
        "Verified carbon units (VCUs)": vcuUnitsAggregateResponse.data[0].value,
        "Pending issuance units (PIUs)": piuUnitsAggregateResponse.data[0].value,
      });
    }

    if (metricsAggregateResponse.status === Status.Success && metricsAggregateResponse.data) {
      setMetricsChartData(
        metricsAggregateResponse.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.key] = current.value;

          return previous;
        }, {})
      );
    }
  }, []);

  const fetchProjectInvitations = useCallback(async () => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.targetOrganisation = {
      uuid: {
        operator: "eq",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: currentOrganisationUuid!,
      },
    };

    filterCriteria.calculatedStatus = {
      operator: "eq",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: ProjectAccessConstants.STATUS_SENT,
    };
  }, []);

  useEffect(() => {
    fetchData().finally(() => {
      setIsLoading(false);
    });
    fetchProjectInvitations();
  }, [fetchData, fetchProjectInvitations]);

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = {
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid, currentUserType),
        } as DataGridLinkCellFormatterData;

        return result;
      }) || [],
    []
  );

  const formatActivitiesData = useCallback(
    (responseData: SearchActivitiesResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.project ? el.project.displayName : el.group?.displayName,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, prettier/prettier
          to: el.project ? getProjectDetailsByUuid(el.project.uuid, currentUserType) : "",
          state: { origin: { goBackText: "Back to Dashboard", from: location.pathname } as IStateOrigin },
        } as DataGridLinkCellFormatterData;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result.standardDisplayName = el.project ? el.project.standard.displayName : el.group!.standard!.displayName!;

        result.activityLink = {
          text: el.activityDefinition.displayName,
          action: () => {
            if (el.currentVersion) {
              navigate(
                getActivityViewRoute(
                  el.currentVersion.uuid,
                  currentUserType,
                  el.project ? `projectUuid=${el.project.uuid}` : ""
                )
              );
            } else {
              Toast.error({
                message: `This activity has not yet been published. Once ${el.createdByUser.fullName} has published the activity, you can view the activity.`,
              });
            }
          },
        } as DataGridButtonLinkCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler = async ({ paging }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    setIsLoadingProjects(true);

    await searchProjects({
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "cachedPiuQuantity" as "results.cachedPiuQuantity",
          direction: "desc",
        },
      ],
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingProjects(false);
      });
    return data;
  };

  const onRecentActivitiesDataLoad: DataGridCursorDataLoadEventHandler = async ({
    paging,
    filtering,
  }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    setIsLoadingActivities(true);

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.organisation = {
      uuid: {
        operator: "eq",
        value: null,
      },
    };

    await searchActivities({
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "currentVersion.updatedAt" as "results.currentVersion.updatedAt",
          direction: "desc",
        },
      ],
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        data = {
          resultData: formatActivitiesData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingActivities(false);
      });
    return data;
  };

  return {
    statusChartData,
    unitsChartData,
    metricsChartData,
    tableColumnsTopProjects,
    tableColumnsRecentActivities,
    isLoading,
    isLoadingProjects,
    isLoadingActivities,
    onTopProjectsDataLoad,
    onRecentActivitiesDataLoad,
    quickActionsMenuOptionsList,
    showQuickActions,
    showCreateProjectModal,
    setShowQuickActions,
    setShowCreateProjectModal,
    hasPermission,
    currentUserType,
  };
};
