import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ActivityPageParams } from "../../../../../../models";
import {
  getActivityHistoryDetails,
  GetActivityHistoryDetailsResponse,
  SearchActivityHistoryResponse,
} from "../../../../../../service/query";
import { Status } from "../../../../../../service/Shared";
import { getNewVersion } from "../../../../../shared/utils";

interface useReviewContentReturnData {
  activityData?: GetActivityHistoryDetailsResponse;
  showSubmitValuationModal: boolean;
  setShowSubmitValuationModal: Dispatch<SetStateAction<boolean>>;
  submitActivity: () => void;
}

export const useReviewContent = (
  setShowVersionConflictModal?: Dispatch<SetStateAction<boolean>>,
  setDraftActivityHistoryUuid?: Dispatch<SetStateAction<string | undefined>>,
  setNewVersionActivityHistory?: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>
): useReviewContentReturnData => {
  const { activityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();

  const [showSubmitValuationModal, setShowSubmitValuationModal] = useState(false);
  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse>();

  const getAndSetData = async (uuid: string): Promise<void> => {
    const historyDetailsRes = await getActivityHistoryDetails({ activityHistoryUuid: uuid });

    if (historyDetailsRes.status === Status.Success && historyDetailsRes.data) {
      setActivityData(historyDetailsRes.data);
    }
  };

  useEffect(() => {
    if (activityHistoryUuid) {
      getAndSetData(activityHistoryUuid);
    }
    return () => {
      setActivityData(undefined);
    };
  }, [activityHistoryUuid]);

  const isVersionConflict = async (): Promise<boolean> => {
    if (activityData && setShowVersionConflictModal && setDraftActivityHistoryUuid && setNewVersionActivityHistory) {
      const newVersion = await getNewVersion(activityData.activity.uuid, activityData.versionNumber);

      if (newVersion) {
        setShowVersionConflictModal(true);
        setDraftActivityHistoryUuid(activityHistoryUuid);
        setNewVersionActivityHistory(newVersion);

        return true;
      }
    }
    return false;
  };

  const submitActivity = async (): Promise<void> => {
    const isConflictModalOpen = await isVersionConflict();

    if (isConflictModalOpen) {
      return;
    }

    setShowSubmitValuationModal(true);
  };

  return {
    activityData,
    showSubmitValuationModal,
    setShowSubmitValuationModal,
    submitActivity,
  };
};
