import { NavigateFunction } from "react-router-dom";

import { OrganisationTypeConstants, UserType } from "../../../../constants";
import { createDraft } from "../../../../service/activity";
import { Status } from "../../../../service/Shared";
import { getActivityEditRoute, getActivityLiteEditRoute } from "../../../../utils/routes";
import toast from "../../../../widget/general/Toast/Toast";

/**
 * This method is directly called on VersionConflictModal `Resume The Latest Activity` and in Developer/Project Workflow `Resume` button
 */
export const createActivityDraft = async (
  activityUuid: string,
  isProjectDashboard: boolean,
  navigate: NavigateFunction,
  userOrganisationType?: OrganisationTypeConstants
): Promise<void> => {
  await createDraft({ activityUuid }).then((response) => {
    if (response.status === Status.Success && response.data) {
      if (userOrganisationType === OrganisationTypeConstants.ASSET_MANAGER)
        navigate(getActivityLiteEditRoute(response.data.uuid));
      else navigate(getActivityEditRoute(response.data.uuid, isProjectDashboard ? "location=Project" : undefined));
    } else {
      const validationNotPublishedError = response.errors?.find(
        (error) => error.code === "VALIDATION_NO_PUBLISHED_VERSION_AVAILABLE"
      );

      if (validationNotPublishedError) {
        toast.error({
          message: `This activity has not yet been published. Once ${validationNotPublishedError?.parameters?.createdByUserFullName} has published the activity, you can begin editing.`,
        });
      }
    }
  });
};

export const createDraftOrNavigateToExistingDraft = async (
  activityUuid: string,
  draftVersionUuid: string | undefined,
  isProjectDashboard: boolean,
  navigate: NavigateFunction,
  userOrganisationType?: UserType
): Promise<void> => {
  if (draftVersionUuid) {
    if (userOrganisationType === OrganisationTypeConstants.ASSET_MANAGER)
      navigate(getActivityLiteEditRoute(draftVersionUuid));
    else navigate(getActivityEditRoute(draftVersionUuid, isProjectDashboard ? "location=Project" : undefined));
  } else {
    await createActivityDraft(activityUuid, isProjectDashboard, navigate, userOrganisationType);
  }
};
