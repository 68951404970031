import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  getActivityDefinitionVersionDetails,
  getActivityHistoryDetails,
  GetActivityHistoryDetailsResponse,
} from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { ERROR_ACCESS_DENIED } from "../../../../../utils";
import { getDashboardRoute } from "../../../../../utils/routes";
import {
  ActivityData,
  ActivityDefinition,
  ActivityDefinitionInfo,
  populateParentLinks,
  ResponseDocumentData,
} from "../../../../developer/activities";

export const useFetchActivityHistory: (
  setActivityCompletionPercentage: React.Dispatch<React.SetStateAction<number>>,
  setActivityData: React.Dispatch<React.SetStateAction<GetActivityHistoryDetailsResponse | undefined>>,
  setActivityDefinition: React.Dispatch<React.SetStateAction<ActivityDefinition>>,
  setActivityDefinitionInfo: React.Dispatch<React.SetStateAction<ActivityDefinitionInfo | undefined>>,
  activityHistoryUuid?: string
) => () => Promise<{
  activityData: ActivityData;
  activityDefinition: ActivityDefinition;
  activityDocuments: ResponseDocumentData[];
  activityUuid: string;
  activityCompletionPercentage: number;
  rowVersion: number;
}> = (
  setActivityCompletionPercentage,
  setActivityData,
  setActivityDefinition,
  setActivityDefinitionInfo,
  activityHistoryUuid
) => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  return useCallback(async () => {
    if (!activityHistoryUuid) throw new Error("Missing 'activityHistoryUuid' parameter");

    const activityHistoryRes = await getActivityHistoryDetails({ activityHistoryUuid });

    if (activityHistoryRes.status !== Status.Success && activityHistoryRes.errors) {
      if (activityHistoryRes.errors?.find((e) => e.message === ERROR_ACCESS_DENIED)) {
        if (window?.history?.state?.idx === 0) {
          navigate(getDashboardRoute(currentUserType), { replace: true });
        } else {
          navigate(-1);
        }

        throw new Error("Not enough permissions to view this activity");
      }
    }

    if (activityHistoryRes.data == null) {
      throw new Error(`"getActivityHistoryDetails" returned no data for uuid ${activityHistoryUuid}`);
    }

    const activityDefinitionRes = await getActivityDefinitionVersionDetails({
      activityDefinitionVersionUuid: activityHistoryRes.data.activity.activityDefinitionVersion.uuid,
      activityHistoryUuid,
    });

    if (activityDefinitionRes.status !== Status.Success || activityDefinitionRes.data == null) {
      throw new Error(
        `"getActivityDefinitionVersionDetails" returned no data for uuid ${activityHistoryRes.data.activity.activityDefinitionVersion.uuid}`
      );
    }

    const { definition, activityDefinition, versionNumber } = activityDefinitionRes.data;

    populateParentLinks(definition);
    setActivityDefinition(definition);

    setActivityDefinitionInfo({
      displayName: activityDefinition.displayName,
      versionNumber,
    });

    setActivityCompletionPercentage(activityHistoryRes.data.completionPercentage || 0);
    setActivityData(activityHistoryRes.data);

    return {
      activityDefinition: definition,
      activityData: activityHistoryRes.data.data,
      rowVersion: activityHistoryRes.data.rowVersion,
      activityDocuments: activityHistoryRes.data.documents,
      activityCompletionPercentage: activityHistoryRes.data.completionPercentage || 0,
      activityUuid: activityHistoryRes.data.activity.uuid,
    };
  }, [activityHistoryUuid]);
};
